import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TaxProviderLists } from '@shared';
import { TaxConfigService } from '@shared/services/price-management/tax-config.service';
import { Pagination, PaginationService, Params, ToastService } from 'thkee-common';
const pageSize: number = 10;
@Component({
  selector: 'app-price-tax-config',
  templateUrl: './price-tax-config.component.html',
  styleUrls: ['./price-tax-config.component.scss'],
})
export class PriceTaxConfigComponent implements OnInit {
  taxProviderLists!: Pagination<TaxProviderLists>;

  queryParams: Params = { page: 1, page_size: pageSize };
  isLoading: boolean = false;
  isLoadingStatus: boolean[] = [];

  constructor(
    private taxConfigService: TaxConfigService,
    private router: Router,
    private toastService: ToastService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.getTaxProviderLists();
  }

  getTaxProviderLists() {
    this.isLoading = true;
    this.taxConfigService.getTaxProviderLists(this.queryParams).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.taxProviderLists = res;
        this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.error({
          message: error,
        });
      },
    });
  }

  paginate(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getTaxProviderLists();
  }

  goDetails() {
    this.isLoading = true;
    this.taxConfigService.getFixedRateTaxLists(this.queryParams).subscribe({
      next: (res) => {
        if (res.results.tax_type === 'by_country') {
          this.router.navigate(['/price-management/tax-config/by-country']);
        } else {
          this.router.navigate(['/price-management/tax-config/fixed-rate']);
        }
      },
      error: (error) => {
        this.toastService.error({
          message: error,
        });
      },
    });
  }

  toggle(i: number, provider: TaxProviderLists) {
    let body = {
      system_name: provider.system_name,
      name: provider.name,
      is_primary: provider.is_primary,
      is_active: provider.is_active,
    };

    this.isLoadingStatus[i] = true;
    this.taxConfigService.updateTaxProviderLists(body, provider.id.toString()).subscribe({
      next: (res) => {
        this.isLoadingStatus[i] = false;
        this.taxProviderLists.results.forEach((item, index) => {
          if (provider.is_active === true) {
            if (i !== index) {
              item.is_active = false;
            }
          }
        });
      },
      error: (error) => {
        this.isLoadingStatus[i] = false;
        this.toastService.error({
          message: error,
        });
      },
    });
  }
}
