<ng-container *ngIf="!isNewHistory">
  <ng-container *ngIf="{ course: course$ | async, courseHistory: courseHistory$ | async } as vm">
    <div class="changes-history custom-scrollbar overflow-y-auto p-4" [style.maxHeight]="maxHeight">
      <div class="history-heading mt-5 flex items-center">
        <div class="flex grow gap-3">
          <strong>Changes History</strong>
          <svg-icon
            src="@assets/images/icons/question-mark-solid.svg"
            class="block h-5 w-5"
            tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip'"
          ></svg-icon>
        </div>
        <div>
          <button type="button" class="btn btn-outline-red">Purge all</button>
        </div>
      </div>
      <div class="text-sm text-amber-600">Old versions will be deleted after 30 days automatically</div>
      <div class="history-items mt-5">
        <ng-container *ngFor="let course of vm.courseHistory; let idx = index">
          <ng-container *ngTemplateOutlet="historyItem; context: { course: course, idx: idx }"></ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #historyItem let-course="course" let-idx="idx">
      <div class="item flex h-[90px] items-center gap-3 border-b border-neutral-200 text-sm">
        <div class="line relative mx-4 h-full w-px border-l border-dashed border-neutral-900">
          <svg-icon
            src="@assets/images/icons/dot.svg"
            class="absolute -bottom-[3px] -left-[3px] block h-[6px] w-[6px]"
          ></svg-icon>
        </div>
        <div class="grow py-[10px]">
          <div class="value mb-3 py-[10px]">
            <a class="hover:underline" [routerLink]="'/course/' + course.id + '/details'">
              <span>v{{ course.version }} - {{ course.status }} - {{ course.title }}</span>
              <span *ngIf="course.id === vm.course?.id" class="tag-green">Current</span>
            </a>
          </div>
          <div class="meta flex items-center gap-3 text-neutral-500">
            <svg-icon src="@assets/images/icons/calendar2.svg" class="block h-4 w-4"></svg-icon>
            <div>{{ course.date_created | date : 'dd MMM yyyy hh:mm a' }}</div>
            <!-- <div
            class="text-xs italic text-neutral-700 underline"
            tooltip="This is a tooltip. This is a tooltip This is a tooltip. This is a tooltip"
            tooltip_position="top"
          >
            View feedback
          </div> -->
          </div>
        </div>
        <div class="">
          <button type="button" class="btn btn-outline-yellow">
            <svg-icon src="@assets/images/icons/refresh-solid.svg" class="btn-icon"></svg-icon>
            <span>Restore</span>
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<ng-container *ngIf="isNewHistory">
  <div *ngIf="{ versionHistory: versionHistory$ | async } as vm" class="flex w-[600px] flex-col p-5">
    <!-- Search -->
    <div class="input-group mb-8">
      <div class="group">
        <svg-icon src="@assets/images/icons/search2.svg" class="h-4 w-4 text-black"> </svg-icon>
        <input [(ngModel)]="filterVersion" placeholder="Search previous version" />
      </div>
    </div>
    <!-- Latest version -->
    <div class="mb-5 flex items-center justify-between">
      <div class="flex items-center gap-2">
        <h4 class="text-base font-bold text-slate-800">
          <b>{{ vm.versionHistory?.version }}</b> -
          <span class="font-normal">
            {{ vm.versionHistory?.date_created | date : 'MMM d' }}
            {{ vm.versionHistory?.date_created | date : 'h:mm a' }}</span
          >
        </h4>
        <span class="h-1 w-1 rounded-full bg-slate-400"></span>
        <div class="status-{{ getStatusTagColor(vm.versionHistory?.status || '') }}">
          <span></span>
          {{ formateStatusTag(vm.versionHistory?.status) }}
        </div>
      </div>
      <div class="flex flex-col items-center gap-1">
        <div class="rounded-md bg-blue-600 px-3 py-1 text-xs text-blue-100">Latest</div>
        <span class="text-xs font-normal text-slate-400">Prev: {{ vm.versionHistory?.previous_version }}</span>
      </div>
    </div>
    <!-- Version history -->
    <div *ngFor="let history of vm.versionHistory?.history || [] | thkLibFilter : { status: filterVersion, version: filterVersion }">
      <div class="relative flex flex-col gap-3 pb-5 pl-8">
        <!-- Title -->
        <div [ngClass]="history.is_active ? 'opacity-100' : 'opacity-30'" class="flex items-center gap-2">
          <h4 class="text-sm font-bold text-slate-800">
            <b>{{ history.version }}</b> -
            <span class="font-normal">
              {{ history.date_created | date : 'MMM d' }}
              {{ history.date_created | date : 'h:mm a' }}</span
            >
          </h4>
          <span class="h-1 w-1 rounded-full bg-slate-400"></span>
          <div class="status-{{ getStatusTagColor(history.status || '') }}">
            <span></span>
            {{ formateStatusTag(history.status) }}
          </div>
          <div *ngIf="history.is_current" class="rounded-md bg-slate-900 px-3 py-1 text-xs text-blue-100">Current</div>
        </div>
        <!-- Items -->
        <ul [ngClass]="history.is_active ? 'opacity-100' : 'opacity-30'">
          <!-- <li *ngFor="let review of history.change_log" class="list-inside list-disc text-sm font-light text-slate-400">
            {{ review }}
          </li> -->
        </ul>
        <span class="history_disk"></span>
        <span class="history_row"></span>
      </div>
    </div>
  </div>
</ng-container>
