import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';

export interface NotificationMessageAction {
  title: string;
  onClick: (message: NotificationMessage) => Promise<void>;
}
export interface NotificationMessage {
  id?: string;
  type: 'error' | 'success' | 'info' | 'warning';
  title?: string;
  message: string;
  expiredAt?: number;
  actions?: NotificationMessageAction[];
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgClass],
})
export class MessagesComponent implements OnInit {
  @Input() messages = <NotificationMessage[]>[];

  readonly classesType = {
    error: 'bg-red-50 text-red-800 dark:bg-gray-800 dark:text-red-400',
    success: 'bg-green-50 text-green-800 dark:bg-gray-800 dark:text-green-400',
    warning: 'text-warning bg-warning-50',
    info: 'bg-slate-800 text-slate-50',
  };

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {}

  getChild(selector: string) {
    return this.elementRef.nativeElement.querySelector(selector);
  }
}
