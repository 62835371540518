import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TabItem } from '../../models';

@Component({
  selector: 'thk-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [NgFor, RouterLink, RouterLinkActive],
})
export class TabsComponent {
  @Input() items: TabItem[] = [];
  @Input() disabled = false;
  @Output() activeTab = new EventEmitter<TabItem>();

  selectedTab?: TabItem;

  activeChange(item: TabItem, active: boolean) {
    if (active) {
      this.activeTab.emit(item);
      this.selectedTab = item;
    }
  }

  selectTab(tab: TabItem) {
    tab.action && tab.action();
    this.selectedTab = tab;
  }
}
