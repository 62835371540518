import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  CourseActionsV2,
  CourseDetailsModelV2,
  selectCoursesVersionHistory,
  selectCourseV2,
  UtilsService,
  VersionHistory,
} from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-course-heading',
  templateUrl: './course-heading.component.html',
  styleUrls: ['./course-heading.component.scss'],
})
export class CourseHeadingComponent implements OnInit {
  course!: CourseDetailsModelV2;
  isDropdown: boolean[] = [];
  @Output() changeHistory: EventEmitter<boolean> = new EventEmitter();

  versionHistory$ = this.store.select(selectCoursesVersionHistory);

  constructor(
    private store: Store,
    private utilsService: UtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCourseV2)
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        this.course = course;
      });
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  // Version activity
  showVersionAction() {
    this.changeHistory.emit(true);
  }
  // Dropdown
  dropdown(index: number) {
    this.isDropdown[index] = !this.isDropdown[index];
  }
  handleClickOutside(index: number) {
    this.isDropdown[index] = false;
  }

  // Switch version
  switchVersion(history: VersionHistory, index: number) {
    this.isDropdown[index] = !this.isDropdown[index];
    this.store.dispatch(CourseActionsV2.loadCourseV2({ courseId: history.id }));

    this.replaceCourseId(history.id);
  }

  replaceCourseId(newCourseId: string): void {
    const lastPath = this.activatedRoute.snapshot.url.slice(-1)[0]?.path;
    if (lastPath === 'revision') {
      this.router.navigate([`/courses/${newCourseId}/${lastPath}`]);
    } else {
      this.router.navigate([`/course/${newCourseId}/${lastPath}`]);
    }
  }
}
