<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Tax</h3>
    </div>
  </div>
  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="Tax" badge="2 Config">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">Name</div>
              </th>
              <th scope="col">
                <div class="col-name">System Name</div>
              </th>
              <th scope="col">
                <div class="col-name">Is Active Provider?</div>
              </th>
              <th scope="col" class="w-28">
                <div class="col-name">Option</div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (provider of taxProviderLists?.results; track provider; let i = $index) {
            <tr class="cursor-pointer text-left hover:!bg-neutral-100">
              <td (click)="goDetails()" class="!py-6">
                <span class="course-id py-3">{{ provider.name }}</span>
              </td>
              <td (click)="goDetails()">
                <span class="course-id">{{ provider.system_name }}</span>
              </td>
              <td>
                <div class="tier-status flex">
                  <label class="relative inline-flex cursor-pointer items-center">
                    <input (change)="toggle(i, provider)" type="checkbox" [(ngModel)]="provider.is_active" class="peer sr-only" />
                    <div
                      class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                    ></div>
                    <span class="ms-3 text-sm">{{ provider.is_active ? 'Active' : 'Disabled' }}</span>
                  </label>
                  @if(isLoadingStatus[i]){
                    <div class="absolute" [thkLibProcessing]="true" size="sm"></div>
                  }
                </div>
              </td>
              <td>
                <button class="btn-amber-soft btn-sm px-2">
                  <svg-icon class="btn-icon" src="@assets/images/icons/adjustments-vertical.svg"></svg-icon>
                  Configure
                </button>
              </td>
            </tr>
            } @if(isLoading){
            <tr class="cursor-pointer text-left hover:!bg-neutral-100">
              <td colspan="4">
                <div
                  class="flex h-14 w-full items-center justify-center bg-white bg-opacity-50"
                  [thkLibProcessing]="true"
                  size="sm"
                ></div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
    <!-- Pagination -->
    <div class="relative z-30">
      <app-common-pagination (paginatorData)="paginate($event)" [type]="'dynamic'"></app-common-pagination>
    </div>
  </div>
</div>
