import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DefaultValue, defaultValueCtx, Editor, editorViewCtx, editorViewOptionsCtx } from '@milkdown/core';
import { gfm } from '@milkdown/kit/preset/gfm';
import { block } from '@milkdown/plugin-block';
import { clipboard } from '@milkdown/plugin-clipboard';
import { cursor } from '@milkdown/plugin-cursor';
import { history } from '@milkdown/plugin-history';
import { indent, indentConfig } from '@milkdown/plugin-indent';
import { prism } from '@milkdown/plugin-prism';
import { nord } from '@milkdown/theme-nord';
import { $provide, NgMilkdown, NgMilkdownPlugin, NgMilkdownProvider } from 'ng-milkdown';

import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { schemaCtx } from '@milkdown/core';
import { Ctx } from '@milkdown/ctx';
import { html } from '@milkdown/kit/component';
import { imageBlockComponent, imageBlockConfig } from '@milkdown/kit/component/image-block';
import { listenerCtx } from '@milkdown/plugin-listener';
import { slashFactory } from '@milkdown/plugin-slash';
import { tooltipFactory } from '@milkdown/plugin-tooltip';
import {
  blockquoteAttr,
  codeBlockSchema,
  commonmark,
  inlineCodeAttr,
  listItemSchema,
} from '@milkdown/preset-commonmark';
import { $view } from '@milkdown/utils';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DOMSerializer } from 'prosemirror-model';
import { firstValueFrom } from 'rxjs';
import { CourseService, EditorImageInfo, LoaderService } from 'thkee-common';
import { BlockComponent } from '../block/block.component';
import { CodeBlockComponent } from '../code-block.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { SlashComponent } from '../slash/slash.component';
import { SpinnerComponent } from '../spinner.component';
import { tableTooltip, TableTooltipComponent, tableTooltipCtx } from '../table-selector/table-tooltip.component';
import { tableSelectorPlugin } from '../table-selector/tableSelectorPlugin';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { EditorTopBarItemsConfig, TopBarComponent } from '../top-bar/top-bar.component';

export const slash = slashFactory('slashMenu');
export const tooltip = tooltipFactory('tooltipMenu');

@Component({
  selector: 'app-main-editor',
  standalone: true,
  imports: [
    NgMilkdown,
    SpinnerComponent,
    NgMilkdownProvider,
    TooltipComponent,
    SlashComponent,
    FormsModule,
    AngularSvgIconModule,
    TopBarComponent,
    NgClass,
    CodeBlockComponent,
  ],
  templateUrl: './main-editor.component.html',
  styleUrl: './main-editor.component.scss',
})
export class MainEditorComponent implements OnInit {
  @Input() isTopBard: boolean = true;
  @Input() isActiveSlash: boolean = true;
  @Input() isActiveBlock: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isActiveTooltip: boolean = false;
  @Input() value = ''; // For dynamic data from BE
  @Output() editorValue: EventEmitter<any> = new EventEmitter();
  @ViewChild(NgMilkdownProvider, { static: true }) provider!: NgMilkdownProvider;
  @Input() topBarItemsConfig!: EditorTopBarItemsConfig;
  @Input() outputFormate: 'markdown' | 'html' | 'json' = 'markdown'; // For dynamic data from BE

  editor!: Editor;
  htmlOutput = '';
  jsonOutput: any;

  loading = true;
  isLoadingDetails$ = this.loaderService.response('file-upload-test');

  constructor(
    private loaderService: LoaderService,
    private coursesDetailsService: CourseService,
    private activatedRoute: ActivatedRoute
  ) {}

  initialized: boolean = false;

  ngOnInit(): void {}

  plugins: NgMilkdownPlugin[] = [
    gfm,
    history,
    prism,
    clipboard,
    cursor,
    commonmark,
    $view(
      listItemSchema.node,
      () => this.provider.createNodeView({ component: ListItemComponent }) // create node view for list item node
    ),
    {
      plugin: imageBlockComponent,
      config: (ctx) => {
        ctx.set(imageBlockConfig.key, {
          imageIcon: () => '🖼️',
          uploadButton: () => html`<span i18n>Upload Image</span>`,
          uploadPlaceholderText: $localize`or paste an image URL`,
          onUpload: async (file: File) => {
            try {
              const imageInfo = await this.uploadImage(file); // Call the uploadImage method
              return imageInfo.image;
            } catch (error) {
              return '';
            }
          },
          captionIcon: () => '📝',
          confirmButton: () => html`<span i18n>Confirm</span>`,
          captionPlaceholderText: $localize`Add a caption`,
        });
      },
    },
    $view(codeBlockSchema.node, () => this.provider.createNodeView({ component: CodeBlockComponent })),
    {
      plugin: block,
      config: (ctx) => {
        if (this.isActiveBlock) {
          ctx.set(block.key, {
            view: this.provider.createPluginView({
              component: BlockComponent,
              inputs: { ctx },
            }),
          });
        }
      },
    },
    {
      plugin: indent,
      config: (ctx) => {
        ctx.set(indentConfig.key as any, {
          type: 'space',
          size: 40,
        });
      },
    },
    {
      plugin: tooltip,
      config: (ctx) => {
        if (this.isActiveTooltip) {
          ctx.set(tooltip.key, {
            view: this.provider.createPluginView({ component: TooltipComponent }), // create plugin view for tooltip plugin
          });
        }
      },
    },
    tableTooltip,
    {
      plugin: tableTooltipCtx,
      config: (ctx) => {
        ctx.set(tableTooltip.key, {
          view: this.provider.createPluginView({
            component: TableTooltipComponent,
          }),
        });
      },
    },
    $provide(tableSelectorPlugin),
    {
      plugin: slash,
      config: (ctx) => {
        if (this.isActiveSlash) {
          ctx.set(slash.key, {
            view: this.provider.createPluginView({ component: SlashComponent }), // create plugin view for slash plugin
          });
        }
      },
    },
  ];

  config = (ctx: Ctx) => {
    const defaultValueFormate = this.determineContentType(this.value);
    if (this.outputFormate === 'html') {
      const defaultValue: DefaultValue = {
        type: 'html',
        dom: document.querySelector('#pre') as HTMLElement,
      };
      if (this.outputFormate === 'html' && defaultValueFormate === 'html') {
        ctx.set(defaultValueCtx, defaultValue);
      }

      ctx.get(listenerCtx).updated((ctx, doc, prevDoc) => {
        const schema = ctx.get(schemaCtx);
        const serializer = DOMSerializer.fromSchema(schema);
        const fragment = serializer.serializeFragment(doc.content);
        const tempDiv = document.createElement('div');
        tempDiv.appendChild(fragment);
        this.htmlOutput = tempDiv.innerHTML;
      });
    }

    if (this.outputFormate === 'json') {
      ctx.get(listenerCtx).updated((ctx, doc, prevDoc) => {
        this.jsonOutput = doc.toJSON();
      });

      const defaultValue: DefaultValue = {
        type: 'json',
        value: this.jsonOutput,
      };
      ctx.set(defaultValueCtx, defaultValue);
    }

    ctx.set(editorViewOptionsCtx, {
      attributes: {
        class: 'prose outline-none mx-auto px-2 py-4 box-border milkdown-theme-nord editor',
        spellcheck: 'false',
      },
    });

    ctx.set(blockquoteAttr.key, () => ({
      class: 'border-l-4 border-slate-600 pl-4',
    }));

    ctx.set(inlineCodeAttr.key, () => ({
      class: 'font-mono text-slate-600 tracking-tight',
    }));

    nord(ctx);
  };

  onEditorReady(editor: Editor): void {
    this.editor = editor; // Store the editor instance if needed
  }

  onChange(markdownText: any) {
    if (this.outputFormate === 'html') {
      this.editorValue.emit(this.htmlOutput);
    } else if (this.outputFormate === 'json') {
      this.editorValue.emit(this.jsonOutput);
    } else {
      this.editorValue.emit(markdownText);
    }
  }

  // Check default value
  determineContentType(value: string): 'markdown' | 'html' {
    if (!value.trim()) {
      return 'markdown';
    }

    // Regular expressions
    const markdownIndicators = [
      /^#{1,6}\s/m, // Markdown headings (e.g., #, ##, ###)
      /^(\*|-|\+)\s/m, // Unordered lists
      /^\d+\.\s/m, // Ordered lists
      /^>\s/m, // Blockquotes
      /`{3,}/m, // Code fences
      /\[.*?\]\(.*?\)/, // Links (e.g., [text](url))
    ];

    const htmlTagRegex = /<\/?[a-z][\s\S]*?>/i; // Generic HTML tags

    // Check for Markdown-specific syntax
    const isMarkdown = markdownIndicators.some((regex) => regex.test(value));
    if (isMarkdown) {
      return 'markdown';
    }

    // Check for the presence of HTML tags outside of code blocks
    const codeBlockRegex = /```[\s\S]*?```/g; // Matches fenced code blocks
    const contentWithoutCodeBlocks = value.replace(codeBlockRegex, ''); // Remove code blocks for HTML check

    if (htmlTagRegex.test(contentWithoutCodeBlocks)) {
      return 'html';
    }

    // Default to Markdown if no clear HTML is detected
    return 'markdown';
  }

  topBarAction(event: string) {
    if (event === 'imageBlock') {
      if (this.editor) {
        this.editor.action((ctx: Ctx) => {
          const view = ctx.get(editorViewCtx);
          const schema = ctx.get(schemaCtx);
          const { state, dispatch } = view;
          const { selection } = state;

          // Create a block image node with empty src and alt attributes
          const imageBlockNode = schema.nodes['image-block'].create({
            src: '', // Empty src for now (you can later update it with an actual URL)
            alt: '', // Empty alt text (can be updated too)
          });

          // Create a transaction to insert the image block at the cursor position
          const tr = state.tr.insert(selection.from, imageBlockNode);

          // Dispatch the transaction to apply the change in the editor
          dispatch(tr);
        });
      } else {
        console.error('Editor is not initialized.');
      }
    }
  }

  async uploadImage(file: File): Promise<EditorImageInfo> {
    try {
      const imageInfo = await firstValueFrom(this.coursesDetailsService.uploadImage(file));
      return imageInfo;
    } catch (error) {
      throw error;
    }
  }
  isExpand: boolean = false;
  topBarExpand(event: boolean) {
    this.isExpand = event;
  }
}
