import { Component } from '@angular/core';
import { NgMilkdownBlock } from 'ng-milkdown';

@Component({
  selector: 'app-block',
  standalone: true,
  imports: [],
  templateUrl: './block.component.html',
  styleUrl: './block.component.scss',
})
export class BlockComponent extends NgMilkdownBlock {}
