
<ng-container *ngIf="{versionHistory: versionHistory$ | async } as vm">
  <div class="course-heading mb-4 flex items-center justify-between gap-4">
    <div class="course-details" *ngIf="course">
      <h3 class="mb-3 text-neutral-800">{{ course.title }}</h3>
      <div class="course-meta inline-flex items-center gap-[10px] text-sm">
        <div class="category">{{ course.category_name }}</div>
        <svg-icon class="h-[10px] w-[10px] text-neutral-300" src="@assets/images/icons/dot.svg"></svg-icon>
        <div class="last-update text-neutral-600">
          Last edited <span class="font-semibold">{{ course.date_updated | date }}</span>
        </div>
        <svg-icon class="h-[10px] w-[10px] text-neutral-300" src="@assets/images/icons/dot.svg"></svg-icon>
        <div (ClickOutside)="handleClickOutside(1)" class="relative">
          <div class="dropdown-container">
            <span (click)="showVersionAction()"> Showing Version </span>
            <span> <b>v{{course.version}}</b> </span>
            <svg-icon (click)="dropdown(1)" class="h-6 w-6 text-neutral-300" src="@assets/images/icons/chevron-down-solid.svg"></svg-icon>
          </div>    
          <div [ngClass]="isDropdown[1] ? 'flex':'hidden'" class="dropdown-items">
            <span *ngFor="let history of vm.versionHistory?.history" (click)="switchVersion(history, 1)">v{{history.version}}</span>
          </div>      
        </div>
        <svg-icon class="h-[10px] w-[10px] text-neutral-300" src="@assets/images/icons/dot.svg"></svg-icon>
        <div *ngIf="course.is_latest" class="bg-blue-600 px-3 py-1 text-xs text-blue-100 rounded-md">Latest</div>
        <div *ngIf="course.is_current" class="bg-slate-900 px-3 py-1 text-xs text-blue-100 rounded-md">Current</div>
        <div class="status">
          <div *ngIf="course.status === 'published'" class="border border-green-300 bg-green-100 rounded-md text-green-700 px-3 py-1 text-xs flex items-center gap-1">
            <span class="w-2 h-2 bg-green-500 rounded-full"></span>
            Published
          </div>
          <div *ngIf="course.status === 'in_review'" class="border border-amber-300 bg-amber-100 rounded-md text-amber-700 px-3 py-1 text-xs flex items-center gap-1">
            <span class="w-2 h-2 bg-amber-500 rounded-full"></span>
            In Review
          </div>
          <div *ngIf="course.status === 'draft'" class="border border-slate-300 bg-slate-100 rounded-md text-slate-700 px-3 py-1 text-xs flex items-center gap-1">
            <span class="w-2 h-2 bg-slate-500 rounded-full"></span>
            Draft
          </div>
          <div *ngIf="course.status === 'rejected'" class="border border-red-300 bg-red-100 rounded-md text-red-700 px-3 py-1 text-xs flex items-center gap-1">
            <span class="w-2 h-2 bg-red-500 rounded-full"></span>
            Rejected
          </div>
          <!-- <div *ngIf="course.status === 'rejected'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm">
            Rejected
          </div> -->
        </div>
      </div>
    </div>
    <div class="actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
</ng-container>