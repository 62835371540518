<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Tax</h3>
    </div>
  </div>

  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex items-center gap-4">
      <button class="btn btn-transparent bg-primary-100 text-primary-600">
        <span>Fixed Rate</span>
      </button>
      <button (click)="switchToByCountry()" class="btn btn-transparent relative">
        <span>By Country</span>
        @if(taxFixedRateData?.results?.tax_type === 'by_country'){
        <span class="absolute -right-6 -top-3 rounded-md bg-primary-50 px-2 py-1 text-xs text-primary-400"> Active </span>
        }
      </button>
    </div>
  </div>
  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="Tax by Fixed rates" badge="{{ totalFixedRateTax }} Categories">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col" class="w-1/2">
                <div class="col-name">Tax Category</div>
              </th>
              <th scope="col">
                <div class="col-name">Rate</div>
              </th>
              <th scope="col" class="w-28">
                <div class="col-name">Option</div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (setting of taxFixedRateData?.results?.list; track setting) {
            <tr class="cursor-pointer text-left hover:!bg-neutral-100">
              <td>
                <span class="course-id">{{ setting.global_tax_category }}</span>
              </td>
              <td>
                @if(editId !== setting.id){
                <span class="course-id">{{ setting.fixed_percentage }}%</span>
                } @if(editId === setting.id){
                <input placeholder="0%" class="input-field" [(ngModel)]="editModel.fixed_percentage" />
                }
              </td>
              <td>
                @if(editId !== setting.id){
                <button (click)="editRate(setting)" class="btn-amber-soft btn-sm px-2">
                  <svg-icon class="btn-icon" src="@assets/images/icons/pencil.svg"></svg-icon>
                </button>
                } @if(editId === setting.id){
                <div class="flex flex-row gap-2">
                  <button (click)="saveRate()" class="btn-teal btn-sm px-2">Save</button>
                  <button (click)="cancelRate()" class="btn-amber btn-sm px-2">Cancel</button>
                </div>
                }
              </td>
            </tr>
            }@if(isLoading){
            <tr>
              <td colspan="7">
                <div
                  class="flex h-14 w-full items-center justify-center bg-white bg-opacity-50"
                  [thkLibProcessing]="true"
                  size="sm"
                ></div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
    <!-- Pagination -->
    <div class="relative z-30">
      <app-common-pagination (paginatorData)="paginate($event)" [type]="'dynamic'"></app-common-pagination>
    </div>
  </div>
</div>
