import { Component } from '@angular/core';
import { FormlyFieldProps as CoreFormlyFieldProps, FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

export interface FormlyFeedbackFieldProps extends CoreFormlyFieldProps {
  hideLabel?: boolean;
  hideRequiredMarker?: boolean;
  labelPosition?: 'floating';
  wrapClass?: string;
  wrapAppendClass?: string[];
  labelClass?: string;
  tips?: string;
  feedback?: number;
  updated?: {
    by: string;
    date: string;
  };
  openFeedback?: (field: FormlyFieldConfig) => void;
}

@Component({
  selector: 'formly-wrapper-feedback-field',
  template: `
    <ng-template #labelTemplate>
      <label
        *ngIf="props.label && props.hideLabel !== true"
        [attr.for]="id"
        [ngClass]="{
          'text-sm': !props.labelClass,
          'flex items-center gap-3': props.tips
        }"
        [class]="props.labelClass ? props.labelClass : ''"
      >
        {{ props.label }}
        <ng-container *ngIf="props.tips">
          <svg-icon
            src="@assets/images/icons/exclamation-circle.svg"
            class="block h-5 w-5 text-slate-400"
            tooltip="{{ props.tips }}"
          ></svg-icon>
        </ng-container>
        <div *ngIf="props.updated" class="text-blue-600 bg-blue-50 border border-blue-500 font-normal text-xs px-3 py-0.5 rounded-full">Recently Updated</div>
      </label>
    </ng-template>
    <div
      class=""
      [class.has-error]="showError"
      [ngClass]="
        props.wrapClass
          ? props.wrapClass
          : 'input-group feedback-input-group mb-3 ' + (props.wrapAppendClass ? props.wrapAppendClass.join(' ') : '')
      "
      [class.readonly]="props.disabled"
    >
      <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
      <div class="flex w-full items-center gap-[10px]">
        <div class="inline-grid grow"><ng-template #fieldComponent></ng-template></div>
        <div>
          <button (click)="openFeedback($event)" class="btn-transparent btn-sm px-[7px] hover:bg-blue-100 hover:text-blue-500" *ngIf="!props.feedback">
            <svg-icon src="@assets/images/icons/annotation.svg" class="btn-icon"></svg-icon>
          </button>
          <div (click)="openFeedback($event)" *ngIf="(props.feedback ?? 0) > 0">
            <button class="btn-teal-soft btn-sm gap-[6px] px-[7px] py-[4px]">
              <svg-icon src="@assets/images/icons/history.svg" class="btn-icon block h-4 w-4"></svg-icon>
              <span>{{ props.feedback }}</span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <small *ngIf="props.description" class="text-sm" [innerHTML]="props.description"></small>
      <div *ngIf="props.updated" class="text-sm text-neutral-500">
        Updated by <span class="font-semibold">{{ props.updated ? props.updated.by : '' }}</span> at
        <span class="font-semibold">{{ props.updated ? '' + props.updated.date : 'asd' }}</span>
      </div>
    </div>
  `,
  styles: ['.input-group { @apply gap-1; }'],
})
export class FormlyWrapperFeedbackField extends FieldWrapper<FormlyFieldConfig<FormlyFeedbackFieldProps>> {
  constructor() {
    super();
  }

  ngOnInit() {}

  openFeedback($event: any) {
    if (this.props.openFeedback) {
      // this.props.openFeedback(this.field, this, $event);
      this.props.openFeedback(this.field);
    }
  }
}
