import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationService } from 'thkee-common';

export interface PaginationConfig {
  pageCountSummary: boolean;
  pageSize?: boolean;
  firstAndLast?: boolean;
}
@Component({
  selector: 'app-common-pagination',
  templateUrl: './common-pagination.component.html',
  styleUrls: ['./common-pagination.component.scss'],
})
export class CommonPaginationComponent implements OnInit {
  @Input() type: string = 'static';
  @Input() config: PaginationConfig = {
    pageCountSummary: true,
    pageSize: false,
    firstAndLast: false,
  };
  paginationData: any;
  @Output() paginatorData: EventEmitter<any> = new EventEmitter();
  @Output() pageSize: EventEmitter<number> = new EventEmitter();

  selectedSize: number = 10;
  currentPage: number = 1;

  constructor(private paginationService: PaginationService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.currentPage = params['page'] ? +params['page'] : 1;
      const pageSize = params['page_size'] ? +params['page_size'] : 10;

      this.selectedSize = pageSize;

      this.paginationService.getPageItems().subscribe((res) => {
        this.paginationData = res;
        this.paginationData.currentPage = this.currentPage;
        this.paginationData.pageSize = pageSize;
      });
    });
  }

  paginateAction(type: string) {
    let newPage = this.paginationData.currentPage;
    if (type === 'next' && newPage < this.paginationData.totalPages) {
      newPage++;
    } else if (type === 'prev' && newPage > 1) {
      newPage--;
    }

    if (type === 'last' && newPage < this.paginationData.totalPages) {
      newPage = this.paginationData.totalPages;
    } else if (type === 'first' && newPage > 1) {
      newPage = 1;
    }

    this.updateQueryParams(newPage, this.selectedSize);
    this.paginatorData.emit(newPage);
  }

  switchPage(page: number) {
    this.updateQueryParams(page, this.selectedSize);
    this.paginatorData.emit(page);
  }

  onChange(value: number) {
    this.selectedSize = value;
    this.updateQueryParams(this.currentPage, value); // Reset to page 1 when changing page_size
    this.pageSize.emit(value);
  }

  private updateQueryParams(page: number, page_size: number) {
    this.router.navigate([], {
      queryParams: { page, page_size },
      queryParamsHandling: 'merge', // Keeps other query parameters
    });
  }
}
