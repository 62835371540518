<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Tax Settings</h3>
    </div>
  </div>

  <div class="card mt-4 rounded-lg border border-neutral-100 p-4">
    @if(isLoading){
    <div
      class="absolute z-20 flex h-full w-full items-center justify-center bg-white bg-opacity-50"
      [thkLibProcessing]="true"
      size="sm"
    ></div>
    }
    <div class="m-auto block max-w-3xl">
      <form [formGroup]="settingsForm">
        <formly-form [form]="settingsForm" [fields]="settingsFormFields" [model]="settingsFormModel"></formly-form>
      </form>
    </div>
  </div>
</div>
