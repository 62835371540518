<ul class="m-0 w-40 list-none rounded-md bg-slate-900 shadow-lg overflow-hidden">
   @for (item of list;track item) {
     <li class="cursor-pointer px-6 py-3 hover:bg-slate-700 hover:text-white"
         [class]="selected === $index ? ['bg-slate-900'] : []"
         (mousemove)="selected = $index"
         (mousedown)="action(onPick)"
         (touchstart)="selected = $index;action(onPick)"
     >
       <div class="flex items-center gap-2 text-slate-300">
         <svg-icon class="btn-icon" [src]="item.icon"></svg-icon>
         <span>{{ item.label }}</span>
       </div>
     </li>
   }
</ul>