import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { COURSE_TAB, ModalComponent, TabItem, UntilDestroy, selectUserId, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter, firstValueFrom, map } from 'rxjs';
import {
  ActionStatus,
  AnnotationDetail,
  AnnotationService,
  CategoryV2,
  ContentTypeApps,
  ContentTypeModels,
  CourseActions,
  CourseActionsV2,
  CourseDetailsModelV2,
  CourseService,
  FeedbackState,
  FileProcessingState,
  FileProps,
  LANGUAGES,
  LoaderService,
  Logger,
  ModalService,
  ProjectService,
  RouterStoreService,
  SelectVideoLibraryItem,
  ToastService,
  generateUuid,
  selectCategories,
  selectContentType,
  selectCourseV2,
  selectFeedback,
  selectTopics,
} from 'thkee-common';
import { HeaderActions } from '../components/course-header/course-header.component';

const log = new Logger('CourseDetailsComponent');

@UntilDestroy()
@Component({
  selector: 'app-general',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class CourseDetailsComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  @ViewChild('feedbackModal') feedbackModal!: ModalComponent;
  @ViewChild('changeHistory') changeHistory!: ModalComponent;

  COURSE_MODEL = ContentTypeModels.COURSE;
  courseState: string = '0';
  courseId: string = '';

  readOnly: boolean = true;
  options: FormlyFormOptions = {};
  formDetails = new FormGroup({ subcategory: new FormControl() });
  modelDetails!: CourseDetailsModelV2;
  fieldsDetails: FormlyFieldConfig[] = [];
  categoryData: CategoryV2[] = [];

  topicOptions$!: Observable<{ label: string; value: string | number }[]>;
  course$ = this.store.select(selectCourseV2);
  categories$!: Observable<CategoryV2[]>;
  categoryOptions$!: Observable<{ label: string; value: string | number }[]>;
  userId$!: Observable<number>;
  courseId$!: Observable<string>;
  feedback$!: Observable<FeedbackState>;
  isLoading$ = this.loaderService.response('loading-admin-courses-detailsV2');
  isDropdown: boolean[] = [];
  tabItems: TabItem[] = COURSE_TAB;
  libraryId: string = '';
  fileStatus$ = new BehaviorSubject<FileProcessingState>({});
  fileSources$ = new BehaviorSubject<SelectVideoLibraryItem>({});

  topBarItemsConfig = {
    undo: false,
    redo: false,
    h1: false,
    h2: false,
    h3: true,
    h4: true,
    h5: true,
    bold: true,
    italic: true,
    strikethrough: true,
    table: false,
    divider: false,
    bulletList: true,
    orderedList: true,
    codeBlock: false,
    quoteBlock: true,
    imageUpload: false,
  };

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private projectService: ProjectService,
    private annotationService: AnnotationService,
    private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService,
    private courseService: CourseService,
    private toastService: ToastService,
    private modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.libraryId = generateUuid();
    this.userId$ = this.store.select(selectUserId);
    this.courseId$ = this.routerStore.getParam('courseId');

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    this.topicOptions$ = this.store
      .select(selectTopics)
      .pipe(map((topics) => topics.map((topic) => ({ label: _.startCase(topic.name), value: topic.id }))));

    this.categories$ = this.store.select(selectCategories);
    this.categoryOptions$ = this.categories$.pipe(
      map((categories) => categories.map((category) => ({ label: _.startCase(category.name), value: category.id })))
    );

    this.categories$.pipe(untilDestroyed(this)).subscribe((categories) => {
      // need this since we can't convert props.options for subcategory field to be an observable
      // we still used function based formly expression
      this.categoryData = categories;
    });

    this.feedback$ = this.store.select(selectFeedback);

    combineLatest([this.course$, this.categories$])
      .pipe(
        filter(([course, categories]) => !!course && categories.length > 0),
        untilDestroyed(this)
      )
      .subscribe(([course]) => {
        this.breadcrumbGenerator(course.title);
        this.modelDetails = {
          ...this.modelDetails,
          ...course,
        };
        this.initFields();

        setTimeout(() => {
          if (course.video_info?.status) {
            const courseInfo: FileProcessingState = {
              status: course.video_info.status,
              event: course.video_info.event,
              error: course.video_info.error,
            };
            return this.fileStatus$.next(courseInfo);
          }
        });
        let newStatus = course?.video_info?.status;
        this.loaderService.loaderAction('file-processing', newStatus as ActionStatus);
        if (course.id && newStatus === 'completed') {
          this.getTranscodedVideoInfo(course.id);
        }
      });

    // handle feedback modal open/close
    this.store
      .select(selectFeedback)
      .pipe(
        map((feedback) => feedback.isFeedbackModalOpen),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((isFeedbackModalOpen) => {
        if (this.feedbackModal) {
          if (isFeedbackModalOpen) {
            this.feedbackModal.open();
          } else {
            this.feedbackModal.close();
          }
        }
      });
  }

  breadcrumb: { label: string; url: string }[] = [];
  breadcrumbGenerator(courseTitle: string) {
    this.breadcrumb = [
      {
        label: 'Courses',
        url: '/courses',
      },
      {
        label: 'Overview',
        url: '/courses/overview',
      },
      {
        label: `${courseTitle}`,
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(this.breadcrumb);
  }

  // GET transcoded info, after file processing
  getTranscodedVideoInfo(courseId: string) {
    this.courseService
      .getCourseDetailsTranscodedInfo(courseId)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe({
        next: (res) => {
          if (res.video_url.length > 0) {
            this.loaderService.loaderAction('file-processing', 'completed');
            this.fileSources$.next(res);
            this.initFields();
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  initFields(disabled: boolean = this.readOnly) {
    if (!disabled) {
      this.courseState = '1';
    }

    this.fieldsDetails = [
      {
        fieldGroupClassName: 'card block border border-neutral-100 py-9 px-6 mb-4 rounded-[10px]',
        fieldGroup: [
          {
            className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
            template: '<h5>Details</h5>',
          },
          {
            key: 'title',
            type: 'input',
            wrappers: ['feedback-field'],
            defaultValue: '#SAP MM Material Management',
            className: 'relative',
            props: {
              label: 'Title',
              labelClass: '!text-neutral-400',
              minLength: 6,
              maxLength: 60,
              placeholder: 'e.g. Learn Blender in 60 minutes',
              required: true,
              disabled: disabled,
              tips: 'Course title',
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            validation: {
              messages: {
                minLength: "Title can't be lower than 6 characters.",
              },
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('title'),
              'props.updated': this.getFeedbackDate('title'),
            },
          },
          {
            key: 'subtitle',
            type: 'input',
            wrappers: ['feedback-field'],
            defaultValue: 'Learn how to create anything from shapes in Blender!',
            className: 'relative',
            props: {
              label: 'Subtitle',
              labelClass: '!text-neutral-400',
              minLength: 6,
              maxLength: 250,
              placeholder: 'e.g. Learn how to create anything from shapes in Blender!',
              disabled: disabled,
              tips: 'Course subtitle',
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            validation: {
              messages: {
                minLength: "This field can't be lower than 6 characters.",
              },
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('subtitle'),
              'props.updated': this.getFeedbackDate('subtitle'),
            },
          },
          {
            key: 'topics',
            type: 'select',
            defaultValue: ['559409e1-6873-4afd-b93d-b29088bad599', '430a8092-90a7-436e-9553-595ecde6e0be'],
            wrappers: ['feedback-field'],
            className: 'relative',
            props: {
              label: 'Topics',
              labelClass: '!text-neutral-400',
              placeholder: 'Choose topic',
              multiple: true,
              stylish: true,
              stayPlaceholder: true,
              disabled: disabled,
              tips: 'Course topics',
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            expressions: {
              'props.options': this.topicOptions$,
              'props.feedback': this.getFeedbackCount('topics'),
              'props.updated': this.getFeedbackDate('topics'),
            },
          },
          {
            key: 'desc',
            type: 'plain-editor',
            defaultValue: '',
            wrappers: ['feedback-field'],
            className: 'relative overflow-y-hidden',
            props: {
              label: 'Description',
              labelClass: '!text-neutral-400',
              minWords: 60,
              maxLength: 100000,
              placeholder: 'Course description...',
              minHeight: '170px',
              maxHeight: '300px',
              disabled: disabled,
              tips: 'Course description',
              topBard: true,
              activeSlash: true,
              activeBlock: true,
              isActiveTooltip: false,
              topBarItemsConfig: this.topBarItemsConfig,
              outputFormate: 'html',
              // updated: {
              //   by: 'Instructor',
              //   date: 'Aug 03, 2022 10:24:00 AM',
              // },
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('desc'),
              'props.updated': this.getFeedbackDate('desc'),
            },
          },
          {
            fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
            fieldGroup: [
              {
                key: 'skill_level',
                type: 'select',
                defaultValue: 'Beginner',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Level',
                  labelClass: '!text-neutral-400',
                  placeholder: 'Choose a level',
                  stylish: true,
                  options: [
                    { label: 'All', value: 'all' },
                    { label: 'Beginner', value: 'beginner' },
                    { label: 'Intermediate', value: 'intermediate' },
                    { label: 'Expert', value: 'expert' },
                  ],
                  disabled: disabled,
                  tips: 'Course level',
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.feedback': this.getFeedbackCount('skill_level'),
                  'props.updated': this.getFeedbackDate('skill_level'),
                },
              },
              {
                key: 'language',
                type: 'select',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Language',
                  labelClass: '!text-neutral-400',
                  placeholder: 'Select language',
                  stylish: true,
                  options: _.sortBy(
                    _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                    (lang) => lang.label
                  ),
                  disabled: disabled,
                  tips: 'Course language',
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.feedback': this.getFeedbackCount('language'),
                  'props.updated': this.getFeedbackDate('language'),
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
            fieldGroup: [
              {
                key: 'category',
                type: 'select',
                defaultValue: 'e6d8a0ba-7b5c-4b81-b5ae-5698cba860a1',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Category',
                  labelClass: '!text-neutral-400',
                  placeholder: 'Choose a category',
                  required: true,
                  disabled: disabled,
                  tips: 'Course category',
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.options': this.categoryOptions$,
                  'props.feedback': this.getFeedbackCount('category'),
                  'props.updated': this.getFeedbackDate('category'),
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field.formControl?.valueChanges.subscribe((value) => {
                      this.formDetails.controls['subcategory'].setValue('');
                      // this.modelDetails.subcategory = '';
                    });
                  },
                },
              },
              {
                key: 'subcategory',
                type: 'select',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Sub category',
                  labelClass: '!text-neutral-400',
                  placeholder: 'Choose sub-category',
                  stylish: true,
                  options: [],
                  disabled: disabled,
                  tips: 'Course sub category',
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.disabled': () => {
                    if (this.modelDetails['category'] && !disabled) {
                      return false;
                    }
                    return true;
                  },
                  // 'props.options': async (field) =>
                  //   await combineLatest([this.categories$])
                  //     .pipe(
                  //       // distinctUntilChanged(([prevCategories, prevValue], [currCategories, currValue]) => prev),
                  //       tap(([categories]) => log.debug('>>> categories formValue', categories, this.modelDetails)),
                  //       filter(([categories]) => categories.length > 0),
                  //       map(([categories]) =>
                  //         categories
                  //           .filter((category) => category.id === this.modelDetails['category'])
                  //           .flatMap((category) =>
                  //             category.subcategories?.map((subcategory) => ({
                  //               label: _.startCase(subcategory.name),
                  //               value: subcategory.id,
                  //             }))
                  //           )
                  //       ),
                  //       tap((options) => log.debug('>>> subcategory options', options)),
                  //       distinctUntilChanged()
                  //     )
                  //     .toPromise(),
                  'props.options': (field) => {
                    const selectedCtgValue = this.modelDetails['category'];
                    let selectedCtg = _.find(this.categoryData, { id: selectedCtgValue });
                    if (selectedCtg && selectedCtg.subcategories?.length) {
                      const sub = selectedCtg.subcategories.map((category: any) => ({
                        label: _.startCase(category.name),
                        value: category.id,
                      }));
                      return sub;
                    }
                    return [];
                  },
                  'props.feedback': this.getFeedbackCount('subcategory'),
                  'props.updated': this.getFeedbackDate('subcategory'),
                },
              },
            ],
          },
        ],
      },
      {
        className: 'block border border-neutral-100 card mb-4 pt-6 px-6 rounded-[10px] relative',
        key: 'image',
        type: 'file',
        wrappers: ['feedback-field'],
        props: {
          wrapAppendClass: ['border-none'],
          label: 'Cover Image',
          subheading: '(7:4 ratio) or (580px × 330px)',
          labelClass: '!text-neutral-400',
          placeholder: 'Upload your course image here. Supported files are .webp, .jpg, .jpeg, or .png.',
          preview: true,
          previewType: 'image',
          allowedTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
          uploadType: 'dashboard',
          disabled: disabled,
          tips: 'Course cover image',
          openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
        } as FileProps,
        expressions: {
          'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
            map(([course, userId]) => ({
              user: userId,
              public: true,
              content_type: 'images',
              library: generateUuid(),
              course: course.id,
            }))
          ),
          'props.previewUrl': this.course$.pipe(map((course) => course.image_url)),
          'props.feedback': this.getFeedbackCount('image'),
          'props.updated': this.getFeedbackDate('image'),
        },
      },
      {
        className: 'block border border-neutral-100 card mb-4 pt-6 px-6 rounded-[10px] relative',
        key: 'promo_video',
        type: 'file',
        wrappers: ['feedback-field'],
        props: {
          wrapAppendClass: ['border-none !gap-3'],
          label: 'Promotional Video',
          labelClass: '!text-neutral-400',
          placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
          preview: true,
          previewType: 'video',
          hideLabel: false,
          allowedTypes: [
            'video/mp4', // MP4 format
            'video/x-matroska', // MKV format
            'video/webm', // WebM format
            'video/ogg', // Ogg format
            'video/x-msvideo', // AVI format
            'video/mpeg', // MPEG format
            'video/quicktime', // MOV format
            'video/x-ms-wmv', // WMV format
            'video/x-flv', // FLV format
            'application/vnd.rn-realmedia', // RealMedia (RM) format
            'application/mxf', // MXF format
            'video/x-ms-asf', // ASF format
            'video/x-prores', // ProRes format
            'video/x-xdcam', // XDCAM format
            'video/x-dnxhd', // DNx format
            'audio/x-m4a', // M4A format
            'video/x-m4v', // M4V format
            'video/x-f4v', // F4V format
            'audio/x-f4a', // F4A format
            'audio/x-m4b', // M4B format
            'audio/x-m4r', // M4R format
            'video/x-vob', // VOB format
            'video/3gpp', // 3GP format
            'video/3gpp2', // 3GP2 format
            'video/x-h264', // H.264 format
            'video/x-hevc', // H.265/HEVC format
            'video/x-dv', // DV format
          ],
          uploadType: 'dashboard',
          disabled: disabled,
          tips: 'Course promotional video',
          previewUrl: '',
          processingStatus: {},
          sources: {},
          metadata: {},
          enableHotkey: false,
          openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
          onUpload: (upload, field) => {
            log.debug('upload: ', upload);
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadStart({
                  referenceId: `${courseId}-${field.key}`,
                  fileName: upload.name,
                  fileType: upload.type,
                })
              );
            });
          },
          onProgress: (progress, field) => {
            log.debug('progress: ', progress);
            this.loaderService.loaderAction('file-processing', 'upload-progress');
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadProgress({
                  referenceId: `${courseId}-${field.key}`,
                  bytesTotal: progress.bytesTotal,
                  bytesUploaded: progress.bytesUploaded,
                  progressPercent: progress.progressPercent,
                })
              );
            });
          },
          onComplete: (field) => {
            log.debug('complete');
            this.loaderService.loaderAction('file-processing', 'complete');
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadComplete({
                  referenceId: `${courseId}-${field.key}`,
                })
              );
            });
          },
        } as FileProps,
        expressions: {
          'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
            map(([course, userId]) => ({
              user: userId,
              public: true,
              content_type: 'promo_video',
              library: this.libraryId,
              course: course.id,
            })),
            distinctUntilChanged()
          ),
          'props.previewUrl': this.fileSources$.pipe(
            map((course) => {
              return course.video_url ? course.video_url[0]?.url : '';
            }),
            distinctUntilChanged()
          ),
          'props.sources': this.fileSources$.pipe(
            map((sources) => {
              return sources;
            }),
            distinctUntilChanged()
          ),
          'props.processingStatus': this.fileStatus$.pipe(
            map((status) => {
              return status;
            })
          ),
          'props.feedback': this.getFeedbackCount('promo_video'),
          'props.updated': this.getFeedbackDate('promo_video'),
        },
      },
    ];
  }

  formatDate(dateString: string = '') {
    return moment(dateString).format('MMMM DD, YYYY');
  }

  actionEvent(event: HeaderActions) {
    log.debug('actionEvent: ', event);
    this.readOnly = true;
    this.initFields(true);
    this.courseState = '0';

    if (event === 'update') {
      this.store.dispatch(CourseActionsV2.upsertCourse({ courseDetails: this.modelDetails }));
    } else if (event === 'accept') {
      this.projectService.toStatePublished(this.modelDetails.project);
    } else if (event === 'reject') {
      this.projectService.toStateRejected(this.modelDetails.project);
    }
  }

  async onFeedbackOpen(field: FormlyFieldConfig) {
    log.debug('onFeedbackOpen', field.key);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldId: this.courseId,
        fieldKey: String(field.key),
        fieldModel: ContentTypeModels.COURSE,
        fieldValue: field.formControl?.value,
      })
    );
  }

  async onFeedbackSubmit(annotation: Partial<AnnotationDetail>) {
    log.debug('onFeedbackSubmit');
    const contentType = await firstValueFrom(
      this.store.select(selectContentType(ContentTypeApps.COURSES, ContentTypeModels.COURSE))
    );
    const fieldKey = await firstValueFrom(this.feedback$.pipe(map((feedback) => feedback.fieldKey)));
    this.store.dispatch(
      CourseActions.upsertAnnotation({
        annotation: {
          ...annotation,
          id: annotation.id ?? generateUuid(),
          content_type: contentType?.id ?? 0,
          course: this.courseId,
          feedback: annotation.feedback ?? '',
          field: fieldKey,
          object_id: this.courseId,
          required_action: annotation.required_action ?? false,
          model: ContentTypeModels.COURSE,
        },
      })
    );
  }

  onFeedbackCancel() {
    log.debug('onFeedbackCancel');

    this.store.dispatch(CourseActions.closeFeedbackModal());
  }

  private getFeedbackCount(fieldKey: string): Observable<number | undefined> {
    return this.annotationService.getAnnotationCountV2(fieldKey, ContentTypeModels.COURSE, this.courseId);
  }

  private getFeedbackDate(fieldKey: string): Observable<{ by: string; date: string } | null> {
    return this.annotationService.getAnnotationDate(fieldKey, ContentTypeModels.COURSE, this.courseId).pipe(
      map((date) => {
        if (!date) {
          return null;
        }
        const feedbackDate = new Date(date);
        const currentDate = new Date();
        const feedbackDateWithBuffer = new Date(feedbackDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        if (feedbackDateWithBuffer < currentDate) {
          return null;
        }
        return {
          by: 'Instructor',
          date: this.formatDate(feedbackDate.toISOString()),
        };
      })
    );
  }

  // private getFeedbackCount(fieldKey: string): Observable<number> {
  //   return this.annotationService.getAnnotationCount(fieldKey, ContentTypeModels.COURSE, this.courseId);
  // }

  // Dropdown
  dropdown(index: number) {
    this.isDropdown[index] = !this.isDropdown[index];
  }
  handleClickOutside(index: number) {
    this.isDropdown[index] = false;
  }

  courseAction(action: string, index: number) {
    this.isDropdown[index] = !this.isDropdown[index];
    let body: { unlisted?: boolean; restricted?: boolean; archived?: boolean } = {};
    let confirmTitle: string = '';
    let confirmMessage: string = '';
    if (action === 'unlisted') {
      confirmTitle = 'Unlisted Confirmation';
      confirmMessage = 'Are you sure, You want to unlisted this course?';
      body.unlisted = true;
    } else if (action === 'restricted') {
      confirmTitle = 'Restricted Confirmation';
      confirmMessage = 'Are you sure, You want to restricted this course?';
      body.restricted = true;
    } else {
      confirmTitle = 'Delete Confirmation';
      confirmMessage = 'Are you sure, You want to delete/archived this course?';
      body.archived = true;
    }

    this.modalService.confirm({
      title: confirmTitle,
      message: confirmMessage,
      onConfirm: () => {
        this.courseActionConfirm(action, body);
      },
    });
  }

  courseActionConfirm(action: string, body = {}) {
    this.courseService.postAdminCourseAction(body, this.courseId).subscribe({
      next: (res) => {
        this.toastService.message({
          message: `Course successfully ${action}!`,
        });
        this.router.navigate(['/courses/overview']);
      },
      error: (error) => {
        this.toastService.error({
          message: `Course ${action} action failed!`,
        });
      },
    });
  }

  // Instructor info
  get fullName(): string {
    return this.modelDetails?.user.fullname ?? '';
  }
  get totalInstructorCourses(): string {
    return this.modelDetails?.user.total_courses ?? '';
  }
  get totalInstructorStudents(): string {
    return this.modelDetails?.user.total_student ?? '';
  }
  get instructorBio(): string {
    return this.modelDetails?.user.bio ?? '';
  }
  get instructorHeadline(): string {
    return this.modelDetails?.user.headline ?? '';
  }
}
