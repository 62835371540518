import { Component } from '@angular/core';
import { commandsCtx, editorViewCtx } from '@milkdown/core';
import { Ctx } from '@milkdown/ctx';
import {
  createCodeBlockCommand,
  insertHrCommand,
  wrapInBlockquoteCommand,
  wrapInHeadingCommand,
} from '@milkdown/preset-commonmark';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgMilkdownSlash } from 'ng-milkdown';

@Component({
  selector: 'app-slash',
  standalone: true,
  imports: [NgMilkdownSlash, AngularSvgIconModule],
  templateUrl: './slash.component.html',
  styleUrl: './slash.component.scss',
})
export class SlashComponent extends NgMilkdownSlash {
  override list = [
    {
      label: 'Heading 1',
      icon: '@assets/images/icons/editors/heading_one.svg',
      slice: wrapInHeadingCommand.key,
      payload: 1,
    },
    {
      label: 'Heading 2',
      icon: '@assets/images/icons/editors/heading_two.svg',
      slice: wrapInHeadingCommand.key,
      payload: 2,
    },
    {
      label: 'Heading 3',
      icon: '@assets/images/icons/editors/heading_three.svg',
      slice: wrapInHeadingCommand.key,
      payload: 3,
    },
    {
      label: 'Quote Block',
      icon: '@assets/images/icons/editors/format_quote.svg',
      slice: wrapInBlockquoteCommand.key,
    },
    {
      label: 'Code Block',
      icon: '@assets/images/icons/editors/code_bracket.svg',
      slice: createCodeBlockCommand.key,
    },
    {
      label: 'Divider',
      icon: '@assets/images/icons/editors/horizontal_line.svg',
      slice: insertHrCommand.key,
      payload: { mode: 'horizontal' },
    },
  ];

  override get onPick(): (ctx: Ctx) => void {
    const { slice, payload } = this.list[this.selected];
    setTimeout(() => {
      this.action((ctx) => {
        ctx.get(editorViewCtx).focus();
      });
    });
    return (ctx: Ctx) => {
      this.removeSlash(ctx);
      ctx.get(commandsCtx).call(slice, payload);
    };
  }
}
