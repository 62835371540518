import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thkLibFilter',
})
export class FilterPipe implements PipeTransform {
  defaultFilter: boolean | undefined;

  transform(items: any[], filter: any): any[] {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (this.defaultFilter) {
        return items.filter((item) =>
          filterKeys.reduce(
            (x, keyName) => (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == '',
            true
          )
        );
      } else {
        return items.filter((item) =>
          filterKeys.some((keyName) => new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == '')
        );
      }
    }

    // Fallback for other cases
    return [];
  }
}
