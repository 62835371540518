import { Component } from '@angular/core';
import { NgMilkdownNodeComp } from 'ng-milkdown';

@Component({
  selector: 'app-list-item',
  standalone: true,
  imports: [],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
})
export class ListItemComponent extends NgMilkdownNodeComp {
  get checked() {
    return this.node.attrs?.['checked'];
  }

  get isBullet() {
    return this.node.attrs?.['listType'] === 'bullet';
  }

  get label() {
    return this.node.attrs?.['label'];
  }
}
