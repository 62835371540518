<div class="flex flex-col w-full">
  <div class="relative cursor-pointer" (click)="toggle()">
    <ng-content></ng-content>
    <div class="absolute top-[50%] translate-y-[-50%] ltr:right-0 rtl:left-0 p-4">
      <svg-icon [class]="showing ? 'rotate-0' : 'rotate-180'" src="@assets/images/icons/chevron-down.svg" class="arrow inline-grid h-5 w-5"></svg-icon>
    </div>
  </div>
  <div *ngIf="showing" class="w-full">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
