import { COURSE_TAB, COURSES_OVERVIEW, PRICESHARE_TAB, TabItem, UPCOMING_PAYOUT_TAB } from './tabs-constant';

export interface ShellCom {
  sidebar?: boolean;
  header?: boolean;
  tab?: boolean;
  breadcrumbs?: boolean;
}

export interface NavItem {
  displayName: string;
  iconName?: string;
  route?: string;
  children?: NavItem[];
  id: number | string;
  countItem?: number | null;
  hidden?: boolean;
  tabMenu?: TabItem[];
  shell?: ShellCom;
}

export const SIDEBAR_CONSTANTS: NavItem[] = [
  {
    id: '2be11c0d',
    displayName: 'Overview',
    iconName: 'category',
    route: '/overview',
  },
  {
    id: '3ffcb75e3a14',
    displayName: 'E-Commerce',
    iconName: 'shop-add',
    // route: '/ecommerce',
    children: [
      {
        id: '5e392de5',
        displayName: 'Dashboard',
        iconName: '',
        route: '/ecommerce/dashboard',
      },
      {
        id: 'aaab00b0',
        displayName: 'Sales',
        iconName: '',
        children: [
          {
            id: 'dks912d',
            displayName: 'Transactions',
            iconName: '',
            route: '/ecommerce/sales/transactions',
          },
          {
            id: 'asdik8f3',
            displayName: 'Statements',
            iconName: '',
            route: '/ecommerce/sales/statements',
          },
          {
            id: 'dkf938d',
            displayName: 'Instructor Statements',
            iconName: '',
            route: '/ecommerce/sales/instructors',
          },
        ],
      },
      {
        id: '312313',
        displayName: 'Orders',
        iconName: '',
        route: '/ecommerce/orders',
      },
      {
        id: '213294',
        displayName: 'Refund Request',
        iconName: '',
        route: '/ecommerce/refund',
      },
      {
        id: 'kjf84u1',
        displayName: 'Payouts',
        iconName: '',
        children: [
          // {
          //   id: 'clzk9d',
          //   displayName: 'Dashboard',
          //   iconName: '',
          //   route: 'ecommerce/payout',
          // },
          {
            id: 'co0a912',
            displayName: 'Paid Payout',
            iconName: '',
            route: '/ecommerce/payout/paid-payout/all',
            countItem: 0,
            // tabMenu: PAID_PAYOUT_TAB,
          },
          {
            id: 'co0a912',
            displayName: 'Paid Payout',
            iconName: '',
            route: '/ecommerce/payout/paid-payout/ondemand',
            countItem: 0,
            // tabMenu: PAID_PAYOUT_TAB,
            hidden: true,
          },
          {
            id: 'co0a912',
            displayName: 'Paid Payout',
            iconName: '',
            route: '/ecommerce/payout/paid-payout/monthly',
            countItem: 0,
            // tabMenu: PAID_PAYOUT_TAB,
            hidden: true,
          },
          {
            id: 'dkzmxck093',
            displayName: 'Instructor balances',
            iconName: '',
            route: '/ecommerce/payout/instructor-balances',
          },
          {
            id: '980mma',
            displayName: 'Payouts Requests',
            iconName: '',
            route: '/ecommerce/payout/payouts-requests/all',
            // tabMenu: PAYOUT_REQUEST_TAB,
          },
          {
            id: '980mmb',
            displayName: 'Payouts Requests',
            iconName: '',
            route: '/ecommerce/payout/payouts-requests/ondemand',
            // tabMenu: PAYOUT_REQUEST_TAB,
            hidden: true,
          },
          {
            id: '980mmc',
            displayName: 'Payouts Requests',
            iconName: '',
            route: '/ecommerce/payout/payouts-requests/monthly',
            // tabMenu: PAYOUT_REQUEST_TAB,
            hidden: true,
          },
          {
            id: 'kcjkcn3',
            displayName: 'Upcoming Payout',
            iconName: '',
            route: '/ecommerce/payout/upcoming-payouts/monthly',
            tabMenu: UPCOMING_PAYOUT_TAB,
          },
          {
            id: 'lcmod93',
            displayName: 'Hold Transactions',
            iconName: '',
            route: '/ecommerce/payout/hold-transactions',
          },
          {
            id: 'dlksd939',
            displayName: 'Inactive Payouts',
            iconName: '',
            route: '/ecommerce/payout/inactive-payouts',
          },
          {
            id: 'dlksd934',
            displayName: 'Failed Payouts',
            iconName: '',
            route: '/ecommerce/payout/failed-payouts',
          },
        ],
      },
      {
        id: '2132943333',
        displayName: 'Ready Requests',
        iconName: '',
        route: '/ecommerce/ready',
      },
    ],
  },
  {
    id: 'mcod933',
    displayName: 'Marketing',
    route: '/marketing',
    iconName: 'chart',
  },
  {
    id: 'dkajkdm1',
    displayName: 'Price Management',
    iconName: 'coin',
    // route: '/price-management',
    children: [
      {
        id: 'dc904923',
        displayName: 'Shares',
        iconName: '',
        route: '/price-management/shares/organics',
        tabMenu: PRICESHARE_TAB,
        children: [
          {
            id: 'pmshares01',
            displayName: 'Shares',
            iconName: '',
            route: '/price-management/shares/organics',
            tabMenu: PRICESHARE_TAB,
            hidden: true,
          },
          {
            id: 'pmshares02',
            displayName: 'Shares',
            iconName: '',
            route: '/price-management/shares/ads',
            tabMenu: PRICESHARE_TAB,
            hidden: true,
          },
          {
            id: 'pmshares03',
            displayName: 'Shares',
            iconName: '',
            route: '/price-management/shares/instructor-referral',
            tabMenu: PRICESHARE_TAB,
            hidden: true,
          },
          {
            id: 'pmshares04',
            displayName: 'Shares',
            iconName: '',
            route: '/price-management/shares/affiliate',
            tabMenu: PRICESHARE_TAB,
            hidden: true,
          },
          {
            id: 'pmshares05',
            displayName: 'Shares',
            iconName: '',
            route: '/price-management/shares/student-referral',
            tabMenu: PRICESHARE_TAB,
            hidden: true,
          },
        ],
      },
      {
        id: 'lkcmcd31',
        displayName: 'Price Tiers',
        iconName: '',
        route: '/price-management/tiers',
        children: [
          {
            id: '09e9811',
            displayName: 'Add Pricing Tiers Menu',
            iconName: '',
            route: '/price-management/tiers/new',
            hidden: true,
          },
          {
            id: 'dods913',
            displayName: 'View Pricing Tiers Menu',
            iconName: '',
            route: '/price-management/tiers/:id',
            hidden: true,
          },
          {
            id: 'dksd913',
            displayName: 'Edit Pricing Tiers Menu',
            iconName: '',
            route: '/price-management/tiers/:id/edit',
            hidden: true,
          },
        ],
      },
      {
        id: 'geo-pricing-page',
        displayName: 'Geo Pricing',
        iconName: '',
        route: '/price-management/geolocation',
        children: [
          {
            id: 'geo-pricing-new-page',
            displayName: 'Add Geolocation',
            iconName: '',
            route: '/price-management/geolocation/new',
            hidden: true,
          },
          {
            id: 'geo-pricing-view-page',
            displayName: 'View Geolocation',
            iconName: '',
            route: '/price-management/geolocation/:id',
            hidden: true,
          },
          {
            id: 'geo-pricing-edit-page',
            displayName: 'Edit Geolocation',
            iconName: '',
            route: '/price-management/geolocation/:id/edit',
            hidden: true,
          },
        ],
      },
      {
        id: '123zc8',
        displayName: 'Countries',
        iconName: '',
        route: '/price-management/countries',
      },
      {
        id: 'currencies-pages',
        displayName: 'Currencies',
        iconName: '',
        route: '/price-management/currencies',
        children: [
          {
            id: 'add-currencies',
            displayName: 'Add Currencies',
            iconName: '',
            route: '/price-management/currencies/new',
            hidden: true,
          },
          {
            id: 'view-currencies',
            displayName: 'View Currencies',
            iconName: '',
            route: '/price-management/currencies/:id',
            hidden: true,
          },
          {
            id: 'edit-currencies',
            displayName: 'Edit Currencies',
            iconName: '',
            route: '/price-management/currencies/:id/edit',
            hidden: true,
          },
        ],
      },
      {
        id: 'zkdoie834',
        displayName: 'Tax Configure',
        iconName: '',
        route: '/price-management/tax-config',
        children: [
          {
            id: 'zkdoie83443',
            displayName: 'Fixed Rate',
            iconName: '',
            route: '/price-management/tax-config/fixed-rate',
            hidden: true,
          },
          {
            id: 'zkdoie834qw2',
            displayName: 'By Country',
            iconName: '',
            route: '/price-management/tax-config/by-country',
            hidden: true,
          },
        ],
      },
      {
        id: 'zkdoie832',
        displayName: 'Tax Category',
        iconName: '',
        route: '/price-management/tax-category',
      },
    ],
  },
  {
    id: 'o90e94i',
    displayName: 'Courses',
    iconName: 'archive-book',
    route: '/courses',
    children: [
      {
        id: 'mc09r941',
        displayName: 'Overview',
        iconName: '',
        route: '/course/:courseId',
        countItem: 0,
        tabMenu: COURSE_TAB,
        hidden: true,
        shell: {
          sidebar: true,
          header: true,
          tab: false,
          breadcrumbs: false,
        },
      },
      {
        id: '948471',
        displayName: 'Overview',
        iconName: '',
        route: '/courses/overview',
        countItem: 0,
        tabMenu: COURSES_OVERVIEW,
      },
      {
        id: '948423',
        displayName: 'Overview',
        iconName: '',
        route: '/courses/statistics',
        countItem: 0,
        tabMenu: COURSES_OVERVIEW,
        hidden: true,
      },
      {
        id: '19238eud',
        displayName: 'To Review',
        iconName: '',
        countItem: 0,
        route: '/courses/review',
      },
      {
        id: '9384841',
        displayName: 'Published',
        iconName: '',
        route: '/courses/published',
      },
      {
        id: '93889djdcjq',
        displayName: 'Recently Updated',
        iconName: '',
        countItem: 0,
        route: '/courses/updated',
      },
      {
        id: 'bkfif9r',
        displayName: 'Rejected',
        iconName: '',
        route: '/courses/rejected',
      },
      {
        id: '0f9c9lqoppz',
        displayName: 'Course Settings',
        iconName: '',
        route: '/courses/settings',
      },
      {
        id: '0f9c9lqop23',
        displayName: 'Course Revision',
        iconName: '',
        route: '/courses/revision',
        hidden: true,
      },
    ],
  },
  {
    id: '928191',
    displayName: 'Reports',
    route: '/report',
    iconName: 'diagram',
  },
  {
    id: '0939191',
    displayName: 'Users',
    iconName: 'user-octagon',
    route: '/users',
    children: [
      {
        id: '0292919',
        displayName: 'Users',
        iconName: '',
        children: [
          {
            id: 'ksisisiq',
            displayName: 'Instructors',
            iconName: '',
            route: '/users/instructors',
          },
          {
            id: 'oiiiiw99w',
            displayName: 'Students',
            iconName: '',
            route: '/users/students',
          },
          {
            id: 'oiiiiw88w',
            displayName: 'Login As',
            iconName: '',
            route: '/users/login-as',
          },
        ],
      },
      {
        id: 'users-instructor-group',
        displayName: 'Instructor Group',
        iconName: '',
        route: '/users/instructor-group',
        children: [
          {
            id: 'add-instructor-group',
            displayName: 'Add Instructor Group',
            iconName: '',
            route: '/users/insturctor-group/new',
            hidden: true,
          },
          {
            id: 'view-instructor-group',
            displayName: 'View Instructor Group',
            iconName: '',
            route: '/users/insturctor-group/:id',
            hidden: true,
          },
          {
            id: 'edit-instructor-group',
            displayName: 'Edit Instructor Group',
            iconName: '',
            route: '/users/insturctor-group/:id/edit',
            hidden: true,
          },
          {
            id: 'member-instructor-group',
            displayName: 'Member Instructor Group',
            iconName: '',
            route: '/users/insturctor-group/:id/member',
            hidden: true,
          },
        ],
      },
      {
        id: 'users-payout-activation',
        displayName: 'Payout Activation',
        iconName: '',
        route: '/users/payout-activation',
        children: [
          {
            id: 'payout-activation-details',
            displayName: 'Payout Activation',
            iconName: '',
            route: '/users/payout-activation/details',
            hidden: true,
          },
        ],
      },
      {
        id: 'czxckd81',
        displayName: 'Staff Management',
        iconName: '',
        children: [
          {
            id: '956opoaz',
            displayName: 'All Users',
            iconName: '',
            route: '/all-users',
          },
          {
            id: 'mvkoqo782',
            displayName: 'Roles',
            iconName: '',
            route: '/user-role',
          },
        ],
      },
      {
        id: '8v840n1nc',
        displayName: 'Reviews',
        iconName: '',
        route: '/user-reveiw',
      },
    ],
  },
  {
    id: 'dasd844',
    displayName: 'Settings',
    iconName: 'settings',
    route: '/settings',
    children: [
      {
        id: 'cmobiw83',
        displayName: 'Course Categories',
        iconName: '',
        route: 'course-categories',
      },
      {
        id: 'dkc84k1',
        displayName: 'Course Topics',
        iconName: '',
        route: 'course-topic',
      },
      {
        id: 'mvmdid81',
        displayName: 'Promote Courses',
        iconName: '',
        route: '/settings',
      },
      {
        id: 'mvmdiw72',
        displayName: 'Tax Settings',
        iconName: '',
        route: 'tax-settings',
      },
      {
        id: 'mcvidiud81',
        displayName: 'Notification',
        iconName: '',
        children: [
          {
            id: 'lciodi91',
            displayName: 'On Website',
            iconName: '',
            route: 'website-notification',
          },
          {
            id: 'kdkjdid82',
            displayName: 'Email',
            iconName: '',
            route: 'email-notification',
          },
        ],
      },
    ],
  },
];
