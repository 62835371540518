export * from './price-countries/price-countries.component';
export * from './price-currencies/price-currencies.component';
export * from './price-geolocation/price-geolocation.component';
export * from './price-management.component';
export * from './price-shares/price-shares.component';
export * from './price-tax-config/price-tax-config.component';
export * from './price-tiers/price-tiers.component';

import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as PriceCountries from './price-countries';
import * as PriceCurrencies from './price-currencies';
import { PriceCurrenciesComponent } from './price-currencies/price-currencies.component';
import * as PriceGeo from './price-geolocation';
import { PriceManagementComponent } from './price-management.component';
import * as PriceShares from './price-shares';
import { PriceTaxCategoryComponent } from './price-tax-category/price-tax-category.component';
import { PriceTaxByCountryComponent } from './price-tax-config/price-tax-by-country/price-tax-by-country.component';
import { PriceTaxConfigComponent } from './price-tax-config/price-tax-config.component';
import { PriceTaxFixedRatesComponent } from './price-tax-config/price-tax-fixed-rates/price-tax-fixed-rates.component';
import * as PriceTiers from './price-tiers';

export const Components = [
  PriceManagementComponent, //TODO: To remove
  ...PriceTiers.Components,
  ...PriceShares.Components,
  ...PriceGeo.Components,
  ...PriceCountries.Components,
  ...PriceCurrencies.Components,
  PriceTaxConfigComponent, //TODO: To work
];

export const Routing = Shell.childPriceRoutes([
  // {
  //   path: '', // Default url/route for Pricing Management
  //   redirectTo: 'shares/organics',
  //   pathMatch: 'full',
  // },
  ...PriceShares.Routing,
  ...PriceTiers.Routing,
  ...PriceGeo.Routing,
  ...PriceCountries.Routing,
  {
    path: 'currencies',
    component: PriceCurrenciesComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Currencies' },
  },
  ...PriceCurrencies.Routing,
  {
    path: 'tax-config',
    component: PriceTaxConfigComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Tax Configuration' },
  },
  {
    path: 'tax-config/fixed-rate',
    component: PriceTaxFixedRatesComponent,
    data: { title: marker('Price Management'), breadcrumb: 'Fix Rate' },
  },
  {
    path: 'tax-config/by-country',
    component: PriceTaxByCountryComponent,
    data: { title: marker('Price Management'), breadcrumb: 'By Country' },
  },
  {
    path: 'tax-category',
    component: PriceTaxCategoryComponent,
    data: { title: marker('Price Tax Category'), breadcrumb: 'Tax Category' },
  },
]);
