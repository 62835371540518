<ng-container *ngIf="isDefaultView">
  <app-course-header [courseState]="courseState" (clickEvent)="actionEvent($event)"></app-course-header>
</ng-container>

<div class="px-4" *ngIf="{course: course$ | async } as vm">
  <app-course-heading (changeHistory)="changeHistoryDrawer.open()">
    <ng-container actions>
      <ng-container *ngIf="isDefaultView">
        <div class="flex items-center gap-3">
          <button class="btn btn-sm btn-transparent rounded-lg">
            <svg-icon src="@assets/images/icons/cog.svg" class="btn-icon"></svg-icon>
            <span>Project Settings</span>
          </button>
          <div (ClickOutside)="handleClickOutside(1)" class="relative">
            <button (click)="dropdown(1)" class="btn btn-sm text-blue-700 border-blue-500 rounded-lg">
              <span>Unlisted</span>
              <svg-icon class="btn-icon" src="@assets/images/icons/chevron-down-solid.svg"></svg-icon>
            </button>   
            <div [ngClass]="isDropdown[1] ? 'flex' : 'hidden'" class="dropdown-items">
              <span (click)="courseAction('unlisted', 1)">Unlisted</span>
              <span (click)="courseAction('restricted', 1)">Restrict</span>
              <span (click)="courseAction('delete', 1)">Delete/Archive </span>
            </div>      
          </div>
          <ng-container *ngIf="vm.course && vm.course.is_latest">
            <button *ngIf="courseState === '0'" class="btn bg-green-500 text-green-100 btn-sm rounded-lg"
              (click)="readOnly = !readOnly; initFields(readOnly)">
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
              <span>Edit</span>
            </button>
            <div class="flex items-center gap-3" *ngIf="courseState === '1'">
              <button (click)="actionEvent('cancel')" class="btn btn-outline btn-sm rounded-lg">
                <span>Cancel</span>
              </button>
              <button (click)="actionEvent('update')" class="btn bg-green-500 text-green-100 btn-sm rounded-lg">
                <svg-icon src="@assets/images/icons/check.svg" class="btn-icon"></svg-icon>
                <span>Update</span>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </app-course-heading>
</div>
<div *ngIf="{loadingStatus: isLoading$ | async } as vm" class="course-details p-4 relative">
  <ng-container *ngIf="vm.loadingStatus === 'loading'; else loadedIntent" >
    <div class="w-full h-full top-0 left-0 absolute z-50 flex items-center justify-center bg-white bg-opacity-25" [thkLibProcessing]="true" size="md"></div>
  </ng-container>
  <ng-template #loadedIntent>
    <ng-container *ngIf="isDefaultView">
      <app-common-tab-menu [items]="tabItems" title="Overview"></app-common-tab-menu>
    </ng-container>
    <div>
      <form [formGroup]="formDetails">
        <formly-form [form]="formDetails" [fields]="fieldsDetails" [model]="modelDetails" [options]="options">
        </formly-form>
      </form>
      <common-modal #feedbackModal title="Feedback for Instructor" customClass="sm:w-full sm:max-w-3xl"
        (closeEvent)="onFeedbackCancel()">
        <ng-container content *ngIf="feedbackModal.isOpened">
          <app-common-feedback (submitEvent)="onFeedbackSubmit($event)" (cancel)="onFeedbackCancel()">
          </app-common-feedback>
        </ng-container>
      </common-modal>
    </div>
    <div class="flex flex-col gap-2 border border-neutral-100 card mb-4 pt-6 px-6 rounded-[10px] relative">
      <div class="text-neutral-400 font-normal text-xl flex items-center gap-2">
        Instructor
        <svg-icon src="@assets/images/icons/exclamation-circle.svg" class="block h-5 w-5 text-slate-400" tooltip="Instructor"></svg-icon>
      </div>
      <div class="flex flex-row gap-4 mb-4">
        <img src="../../../../assets/images/profile-img.png" class="w-16 h-16 rounded-full" alt="Instructor image">
        <div class="flex flex-col gap-1">
          <h3 class="text-base font-semibold text-slate-700">{{fullName}}</h3>
          <h3 class="text-sm font-normal text-slate-600">{{instructorHeadline}}</h3>
          <h3 class="text-sm font-normal text-slate-600"> 
            <span class="font-semibold">{{totalInstructorStudents}}</span> Students
            <span class="font-semibold">{{totalInstructorCourses}}</span> Courses
          </h3>
        </div>
      </div>
      <p class="text-slate-500 text-sm">{{instructorBio}}</p>
    </div>
  </ng-template>
</div>

<!-- Buyer drawer -->
<app-drawer #changeHistoryDrawer customClass="max-w-[600px] !sm:w-full">
  <ng-container header>
    <div class="px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
      <h3 class="text-xl text-black font-semibold">Version History</h3>
      <div class="flex justify-between items-center gap-3">
        <button (click)="changeHistoryDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
          <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
          </svg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container content>
    <ng-container content *ngIf="changeHistoryDrawer.isOpened">
      <app-common-history-course maxHeight="90vh"></app-common-history-course>
    </ng-container>
  </ng-container>
</app-drawer>