<ng-container *ngIf="{ course: course$ | async } as vm">
  <div *ngIf="vm.course?.is_latest === false && vm.course?.is_current === false" class="h-auto w-full bg-amber-100 p-2 text-center">
    <p class="m-0 text-sm text-amber-700">
      You're viewing a previous version of this course.
      <b (click)="switchVersion('exit_history')" class="cursor-pointer underline">Exit History</b>
    </p>
  </div>

  <div *ngIf="vm.course?.is_latest === true && vm.course?.is_current === false && vm.course?.status !== 'published'" class="h-auto w-full bg-black p-2 text-center">
    <p class="m-0 text-sm text-white">
      You're viewing latest unpublished version.
      <b (click)="switchVersion('view_current_published_version')" class="cursor-pointer underline">View current published version</b>
    </p>
  </div>

  <div *ngIf="vm.course?.is_current === true && vm.course?.is_latest === false" class="h-auto w-full bg-green-500 p-2 text-center">
    <p class="m-0 text-sm text-white">
      You are viewing current published version.
      <b (click)="switchVersion('view_latest_unpublished_version')" class="cursor-pointer underline">View latest unpublished version</b>
    </p>
  </div>

  <div *ngIf="vm.course?.is_current === true && vm.course?.is_latest === true" class="h-auto w-full bg-green-500 p-2 text-center">
    <p class="m-0 text-sm text-white">
      You are viewing current published version and it's the most latest.
    </p>
  </div>
</ng-container>
<!-- <app-header>
  <ng-container action>
    <div class="flex gap-[10px] border-l border-neutral-200 pl-4" *ngIf="courseState">
      <ng-container *ngIf="courseState === '1'">
        <ng-container *ngTemplateOutlet="buttonState"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</app-header> -->
<!-- <app-common-tab-menu [items]="tabItems" title="Overview"></app-common-tab-menu> -->
<app-common-breadcrumbs></app-common-breadcrumbs>

<!-- <ng-template #buttonState>
  <button class="btn-outline" (click)="emitButton('reject')">
    <span>Reject</span>
  </button>
  <button class="btn-teal" (click)="emitButton('accept')">
    <span>Accept</span>
  </button>
</ng-template> -->
<!-- <ng-template #buttonState>
  <button class="btn-outline" (click)="emitButton('cancel')">
    <span>Cancel</span>
  </button>
  <button class="btn-amber" (click)="emitButton('update')">
    <span>Update</span>
  </button>
</ng-template> -->
<!-- <ng-template #buttonState>Button State 3</ng-template> -->
