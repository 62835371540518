import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  CountryTaxLists,
  CreateTaxModel,
  DrawerComponent,
  ModalComponent,
  PricingCountryService,
  SharedModule,
  TaxCategory,
  TaxCategoryLists,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import { TaxConfigService } from '@shared/services/price-management/tax-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import _ from 'lodash';
import { BehaviorSubject, map } from 'rxjs';
import { ConstantCountryOption, PaginationService, Params, ToastService } from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-price-tax-by-country',
  standalone: true,
  imports: [
    AngularSvgIconModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgTemplateOutlet,
    NgIf,
    NgFor,
  ],
  templateUrl: './price-tax-by-country.component.html',
  styleUrl: './price-tax-by-country.component.scss',
})
export class PriceTaxByCountryComponent implements OnInit {
  taxByCountry = new FormGroup({});
  taxByCountryModel: CreateTaxModel = {
    country_code: '',
    state_province: '',
    zip_code: '',
    default_percentage: '0',
    enabled: false,
    categories: [],
  };

  categories: TaxCategory[] = [];

  taxByCountryFields: FormlyFieldConfig[] = [];
  showSubmit: boolean = true;
  percentageLabel$ = new BehaviorSubject<string>('Yes');
  activeLabel$ = new BehaviorSubject<string>('Yes');

  taxByCountryLists: CountryTaxLists[] = [];
  totalTaxByCountry: number = 0;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  @ViewChild('drawerSidebar') private drawerSidebar!: DrawerComponent;
  action: string = '';
  queryParams: Params = { page: 1, page_size: pageSize };

  countryOption: { label: string; value: string | number }[] = [];
  taxCategoryLists: TaxCategoryLists[] = [];
  @ViewChild('categoryModal') categoryModal!: ModalComponent;

  constructor(
    private taxConfigService: TaxConfigService,
    private router: Router,
    private toastService: ToastService,
    private paginationService: PaginationService,
    private pricingCountryService: PricingCountryService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initFields();
    this.getCountryList();
    this.getCountryTaxLists();
    this.getTaxCategoryLists();

    this.initFields();
  }

  getCountryTaxLists() {
    this.isLoading = true;
    this.taxConfigService
      .getCountryTaxLists(this.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.taxByCountryLists = res.results;
          this.totalTaxByCountry = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
        },
        error: (error) => {
          this.isLoading = false;
          this.toastService.error({
            message: error,
          });
        },
      });
  }

  getCountryList() {
    this.pricingCountryService
      .getConstantCountries()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (countries) => {
          this.countryOption = countries.map((country: ConstantCountryOption) => ({
            label: _.startCase(country.name),
            value: country.code ?? '',
          }));
          this.initFields();
        },
        error: ({ error }) => {
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });
  }

  getTaxCategoryLists() {
    this.taxConfigService.getTaxCategoryLists(this.queryParams).subscribe({
      next: (res) => {
        this.taxCategoryLists = res.results;
        this.taxCategoryLists.forEach((cat) => {
          cat['checked'] = false;
        });
      },
      error: (error) => {
        this.toastService.error({
          message: error,
        });
      },
    });
  }

  switchToFixedRate() {
    this.router.navigate(['/price-management/tax-config/fixed-rate']);
  }

  initFields() {
    this.taxByCountryFields = [
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Name</label>',
          },
          {
            key: 'country_code',
            type: 'select',
            className: 'w-full',
            props: {
              hideLabel: true,
              placeholder: 'Choose Country',
              required: true,
              options: this.countryOption,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">State / Province</label>',
          },
          {
            key: 'state_province',
            type: 'input',
            className: 'w-full',
            props: {
              hideLabel: true,
              placeholder: 'Choose value',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">ZIP Code</label>',
          },
          {
            key: 'zip_code',
            type: 'input',
            className: 'w-full',
            props: {
              hideLabel: true,
              placeholder: 'ZIP Code',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Default percentage</label>',
          },
          {
            key: 'default_percentage',
            type: 'input',
            className: 'w-full',
            props: {
              hideLabel: true,
              placeholder: 'Default percentage',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold w-52 text-right">Categories level percentage</label>',
          },
          {
            key: 'categories_level_percentage',
            type: 'toggle',
            defaultValue: true,
            className: 'w-full',
            props: {
              wrapClass: '!mb-0',
              label: 'Yes',
              tips: 'This is a tip message for course title',
            },
            expressions: {
              'props.label': this.percentageLabel$.pipe(
                map((percentageLabel) => {
                  return percentageLabel;
                })
              ),
            },
            hooks: {
              onInit: (field) => {
                // On change event
                if (field.formControl) {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.percentageLabel$.next(value ? 'Yes' : 'No');
                  });
                }
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right"></label>',
          },
          {
            fieldGroupClassName: 'flex flex-col',
            fieldGroup: [
              {
                key: 'button-popup',
                type: 'button',
                props: {
                  inputAppendClass: ['w-auto'],
                  template: `<div class="bg-primary-100 text-primary-600 flex items-center gap-2 text-nowrap px-3 py-2.5 mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none" class="w-5">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.0001 2.60156C7.3867 2.60156 7.7001 2.91496 7.7001 3.30156V6.80156H11.2001C11.5867 6.80156 11.9001 7.11496 11.9001 7.50156C11.9001 7.88816 11.5867 8.20156 11.2001 8.20156H7.7001V11.7016C7.7001 12.0882 7.3867 12.4016 7.0001 12.4016C6.6135 12.4016 6.3001 12.0882 6.3001 11.7016V8.20156H2.8001C2.4135 8.20156 2.1001 7.88816 2.1001 7.50156C2.1001 7.11496 2.4135 6.80156 2.8001 6.80156L6.3001 6.80156V3.30156C6.3001 2.91496 6.6135 2.60156 7.0001 2.60156Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span class="text-sm font-medium max-sm:text-xs">Add Category</span>
                  </div>`,
                  click: this.onButtonClick.bind(this),
                },
              },
              {
                key: 'categories',
                type: 'repeat',
                className: 'w-full',
                props: {
                  isButton: 'false',
                  addText: $localize`Add Target Learners`,
                  deleteConfirmation: true,
                  onDelete: (field: any, deletedData: any) => {
                    this.removeItem(deletedData);
                  },
                  min: 1,
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'id',
                      defaultValue: '',
                      props: {
                        type: 'hidden',
                        wrapClass: 'hidden',
                      },
                    },
                    {
                      type: 'input',
                      key: 'percentage',
                      defaultValue: '',
                      props: {
                        required: true,
                        placeholder: '',
                        hideLabel: true,
                        wrapClass: 'mb-2 custom-class',
                        wrapGroupClass: '!static',
                        inputAppendClass: ['border-0 p-0 disabled-input-outline'],
                        addonRight: '<span class="text-slate-300">%</span>',
                        maxLength: 160,
                        feedback: 0,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (field.formControl) {
                            field.formControl.valueChanges.subscribe((value) => {
                              // let remain = 160;
                              // if (value) remain = 160 - value.length;
                              // if (field.props)
                              //   field.props['addonRight'] = `<span class="text-slate-300">${
                              //     remain ? remain : 0
                              //   }</span>`;
                            });
                          }
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Display order</label>',
          },
          {
            key: 'display_order',
            type: 'input',
            className: 'w-full',
            props: {
              hideLabel: true,
              placeholder: 'Default percentage',
              required: false,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold w-52 text-right">Active</label>',
          },
          {
            key: 'enabled',
            type: 'toggle',
            defaultValue: true,
            className: 'w-full',
            props: {
              wrapClass: '!mb-0',
              label: 'Yes',
              tips: 'This is a tip message for course title',
            },
            expressions: {
              'props.label': this.activeLabel$.pipe(
                map((activeLabel) => {
                  return activeLabel;
                })
              ),
            },
            hooks: {
              onInit: (field) => {
                // On change event
                if (field.formControl) {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.activeLabel$.next(value ? 'Yes' : 'No');
                  });
                }
              },
            },
          },
        ],
      },
    ];
  }

  onButtonClick() {
    this.selectedCategory.forEach((selectedCat) => {
      const index = this.taxCategoryLists.findIndex((cat) => cat.id === selectedCat.id);
      this.taxCategoryLists.splice(index, 1);
    });

    this.categoryModal.open();
  }

  selectedCategory: { id: number; percentage: string }[] = [];
  selectCategory(category: TaxCategoryLists, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.checked) {
      this.selectedCategory.push({ id: category.id || 0, percentage: '' });
    } else {
      const index = this.selectedCategory.findIndex((cat) => cat.id === category.id);
      this.selectedCategory.splice(index, 1);
    }
  }

  applySelectedCategory() {
    this.categoryModal.close();
    this.taxByCountryModel = {
      ...this.taxByCountryModel,
      categories: [...this.selectedCategory, ...this.categories],
    };
  }

  taxId!: number;
  createUpdate() {
    console.log(this.taxByCountryModel);
    this.isSubmitted = true;
    if (this.action === 'create') {
      this.taxConfigService.createCountryTax(this.taxByCountryModel).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.taxByCountryLists.push(res);
          this.drawerSidebar.close();
        },
        error: ({ error }) => {
          this.isSubmitted = false;
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });
    } else if (this.action === 'update') {
      this.taxConfigService.updateCountryTax(this.taxByCountryModel, this.taxId.toString()).subscribe({
        next: (res) => {
          this.taxByCountryLists;
          const index = this.taxByCountryLists.findIndex((cat) => cat.id === res.id);
          this.taxByCountryLists.splice(index, 1, res);
          this.isSubmitted = false;
          this.drawerSidebar.close();
        },
        error: ({ error }) => {
          this.isSubmitted = false;
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });
    }
  }

  paginate(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getCountryTaxLists();
  }

  add() {
    this.categories = [];
    this.taxByCountryModel = {
      country_code: '',
      state_province: '',
      zip_code: '',
      default_percentage: '0',
      enabled: false,
      categories: [],
    };
    this.taxByCountry.reset();
    this.taxByCountry.markAsUntouched();
    this.drawerSidebar.open();
    this.action = 'create';
  }

  update(category: CountryTaxLists) {
    this.categories = category.categories;

    category.categories.forEach((existsCat) => {
      const index = this.taxCategoryLists.findIndex((cat) => cat.id === existsCat.id);
      this.taxCategoryLists.splice(index, 1);
    });

    this.taxByCountryModel = {
      ...this.taxByCountryModel,
      ...category,
    };
    this.cdr.detectChanges();
    this.taxId = category.id || 0;
    this.drawerSidebar.open();
    this.action = 'update';
  }

  reset() {
    this.taxByCountry.reset();
  }

  removeItem(deletedData: { id: number | undefined }) {
    const selectIndex = this.selectedCategory.findIndex((cat) => cat.id === deletedData.id);
    this.selectedCategory.splice(selectIndex, 1);
  }

  previewCategoryDetails!: CountryTaxLists;
  previewCategory(category: CountryTaxLists) {
    this.previewCategoryDetails = category;
    this.action = 'preview';
    this.drawerSidebar.open();
  }
}
