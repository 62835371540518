import { Pipe, PipeTransform } from '@angular/core';
import '@angular/localize/init';

@Pipe({
  name: 'formatDuration',
  standalone: true,
})
export class formatDurationPipe implements PipeTransform {
  // transform(second: number = 0, ...args: unknown[]): string {
  //   // { minute: number; second: number; output: string } - Old output
  //   if (second < 0) {
  //     throw new Error('Second cannot be negative.');
  //   }
  //   const minute = Math.floor(second / 60);
  //   const remainingSeconds = Math.floor(second % 60);
  //   let output = '';
  //   if (minute) output += minute + 'm ';
  //   if (remainingSeconds) output += remainingSeconds + 's';
  //   output = output ? output : 'Not Available';

  //   return output;
  //   //return { minute, second: remainingSeconds, output }; Old return
  // }

  transform(second: number = 0, ...args: unknown[]): string {
    if (second < 0) {
      return $localize`Not Available`; // Handling invalid input
    }

    const totalSeconds = Math.floor(second);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    // Checking for zero duration
    if (totalSeconds === 0) {
      return $localize`Not Available`;
    }

    // Formatting based on the duration
    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`;
    } else if (totalSeconds < 60) {
      return `${'00'}:${String(seconds).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  }
}

@Pipe({
  name: 'formatDurationHour',
  standalone: true,
})
export class formatDurationHourPipe implements PipeTransform {
  transform(seconds: number = 0, ...args: unknown[]): string {
    // Handle invalid or zero input in one place
    if (seconds <= 0) {
      return $localize`Not Available`;
    }

    const totalMinutes = Math.floor(seconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const hoursText = hours > 1 ? $localize`hours` : $localize`hour`;
    const minutesText = minutes > 1 ? $localize`minutes` : $localize`minute`;
    const andText = $localize`and`;

    // Format based on the duration in hours and minutes
    if (hours > 0) {
      return `${hours} ${hoursText} ${andText} ${minutes} ${minutesText}`; // Display "xh:ym" if hours are present
    } else {
      return `${minutes} ${minutesText}`; // Only display minutes if there are no hours
    }
  }
}
