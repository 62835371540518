import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  AdminReviews,
  Column,
  COURSE_TAB,
  COURSES_REVIEWS_COLUMN,
  ModalComponent,
  ModalConfirmComponent,
  RECENT_UPDATE_TABLE_SEARCH,
  ReviewsFilterOptions,
  ReviewsResult,
  TabItem,
  TableSearch,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import { CourseService } from '@shared/services';
import { combineLatest } from 'rxjs';
import { Logger, PaginationService, Params, RouterStoreService, UtilsService } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');
const pageSize: number = 5;

@UntilDestroy()
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class CourseReviewsComponent implements OnInit {
  courseState: string = '3';
  columns: Column[] = COURSES_REVIEWS_COLUMN;
  queryParams: Params = { page: 1, page_size: pageSize };
  @ViewChild('deleteReviewModal')
  private deleteReviewModal!: ModalConfirmComponent;
  tabItems: TabItem[] = COURSE_TAB;

  // Filter form
  filterValues: ReviewsFilterOptions = {
    is_reported: [],
    course_rating: [],
  };
  filterOptions: ReviewsFilterOptions = {
    is_reported: [],
    course_rating: [],
  };

  // Reviews
  @ViewChild('newReview') newReview!: ModalComponent;
  formReview = new FormGroup({});
  modelReview = { content: '' };
  fieldsReview: FormlyFieldConfig[] = [
    {
      key: 'content',
      type: 'textarea',
      props: {
        minLength: 6,
        maxLength: 1000,
        placeholder: 'Share your experience with this course!',
        minHeight: '150px',
        maxHeight: '300px',
        hideLabel: true,
        required: true,
      },
      validation: {
        messages: {
          minLength: "Review can't be lower than 6 characters.",
        },
      },
    },
  ];

  reviewId: string = '';
  selectedRate: number = 0;
  courseId: string = '';
  reviews_lists!: Partial<AdminReviews>;
  constructor(
    private store: Store,
    private courseService: CourseService,
    private routerStore: RouterStoreService,
    private paginationService: PaginationService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    // this.getData();
    // this.courses$ = this.store.select(selectCourses);

    combineLatest([this.routerStore.getParam('courseId')])
      .pipe(untilDestroyed(this))
      .subscribe(([courseId]) => {
        this.courseId = courseId;
        if (courseId) {
          this.getData({}, courseId);
        }
      });

    this.setFilterOptions();
  }

  // Checkbox filter
  setFilterOptions() {
    this.filterOptions.course_rating = [
      { value: '5', label: '5 Star' },
      { value: '4', label: '4 Star' },
      { value: '3', label: '3 Star' },
      { value: '2', label: '2 Star' },
      { value: '1', label: '1 Star' },
    ];
    this.filterOptions.is_reported = [{ value: 'true', label: 'Is Reported' }];
  }

  // Filter values
  filterHander(event: any) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.queryParams = {};
      this.clearFilter([]);
    }
  }
  // Reset filter
  clearFilter(filters: any) {
    if (filters) {
      filters.forEach((filter: any) => {
        filter.reset();
      });
    }
  }

  // Get data
  getData(params: Params = {}, courseId: string, reload: boolean = false) {
    this.courseService
      .getAdminCoursesReviews(this.queryParams, courseId, reload)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.reviews_lists = data;
        this.paginationService.getPager(data.count, params.page, params.page_size);
      });
  }

  // Pageination
  paginat(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = event;
    this.getData(this.queryParams, this.courseId, true);
  }

  // Common dropdown
  isDropdown: { [key: string]: boolean } = {};
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Filter
  submitFilter() {
    // TODO: API Intigration
    this.getData(this.queryParams, this.courseId, true);
  }

  // Search
  searchType: TableSearch[] = RECENT_UPDATE_TABLE_SEARCH;
  selectedItems: TableSearch = RECENT_UPDATE_TABLE_SEARCH[0];
  selectItems(item: TableSearch) {
    this.selectedItems = item;
  }
  search(event: any) {
    this.queryParams.search = `${this.selectedItems.value + ':' + event.target.value}`;
    this.getData(this.queryParams, this.courseId, true);
  }

  // Table
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // Update Revviews
  updateReview(review: ReviewsResult) {
    this.modelReview.content = review.content;
    this.selectedRate = review.rate;
    this.reviewId = review.id;
    this.newReview.open();
  }
  submitReview() {
    const payload = {
      ...this.modelReview,
      rate: this.selectedRate,
    };
    this.courseService
      .updateAdminCoursesReviews(this.reviewId, payload)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.newReview.close();
        this.formReview.reset();
        this.getData(this.queryParams, this.courseId, true);
      });
  }

  // Delete Revview
  deleteReview(review: ReviewsResult) {
    this.reviewId = review.id;
    this.deleteReviewModal.open();
  }
  deleteReviewConfirmed() {
    this.courseService
      .deleteAdminCoursesReviews(this.reviewId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.getData(this.queryParams, this.courseId, true);
      });
  }
}
