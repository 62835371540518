<ng-container *ngIf="{ isEdit: isEdit$ | async } as vm">
  <!-- <ng-container header>
    <div class="modal-header flex items-center border-b border-neutral-50 bg-neutral-50 px-6 py-4">
      <div class="text-lg font-bold">{{ onSelected ? lectureType : 'Lecture Type' }}</div>
    </div>
  </ng-container> -->
  <div class="left-0 top-0 flex w-full flex-row justify-between gap-4 border-b border-slate-200 bg-white px-6 py-4">
    <div *ngIf="onSelected" class="modal-header flex items-center border-b border-neutral-50 bg-neutral-50">
      <div *ngIf="vm.isEdit" class="font-semibold">
        <span *ngIf="lectureType === 'video'">Update Video</span>
        <span *ngIf="lectureType === 'article'">Update Article</span>
      </div>
      <div *ngIf="!vm.isEdit" class="font-semibold">
        <span *ngIf="lectureType === 'video'">Add Video</span>
        <span *ngIf="lectureType === 'article'">Add Article</span>
      </div>
    </div>
    <div *ngIf="!onSelected" class="modal-header flex items-center border-b border-neutral-50 bg-neutral-50">
      <div class="font-semibold">Lecture Type</div>
    </div>
    <button (click)="cancel()" class="w-6 text-slate-400 hover:text-slate-600">
      <svg-icon src="@assets/images/icons/x-mark.svg" class="h-6 w-6"></svg-icon>
    </button>
  </div>
  <div class="px-6 pb-6">
    <ng-container *ngIf="!onSelected">
      <div class="activity-type grid grid-cols-2 gap-6">
        <div
          class="relative flex cursor-pointer flex-col rounded-lg border border-neutral-200 p-7 text-center"
          (click)="select('video')"
          [class.active]="lectureType === 'video'"
        >
          <svg-icon
            *ngIf="lectureType === 'video'"
            src="@assets/images/icons/check-circle-solid.svg"
            class="text-primary-400 absolute right-3 top-3 w-6"
          ></svg-icon>
          <div class="mx-auto mb-6"><svg-icon src="@assets/images/video_type.svg" class="w-20"></svg-icon></div>
          <h4 class="mb-3 font-bold">Video</h4>
          <div class="text-sm text-neutral-500">Build trust and credibility with a video-based Lecture.</div>
        </div>
        <div
          class="relative flex cursor-pointer flex-col rounded-lg border border-neutral-200 p-7 text-center"
          (click)="select('article')"
          [class.active]="lectureType === 'article'"
        >
          <svg-icon
            *ngIf="lectureType === 'article'"
            src="@assets/images/icons/check-circle-solid.svg"
            class="text-primary-400 absolute right-3 top-3 w-6"
          ></svg-icon>
          <div class="mx-auto mb-6"><svg-icon src="@assets/images/article_type.svg" class="w-16"></svg-icon></div>
          <h4 class="mb-3 font-bold">Article</h4>
          <div class="text-sm text-neutral-500">
            Provide variation, self-guided learning, easy way to combine text & images.
          </div>
        </div>
      </div>
      <div class="mt-5 text-center">
        <button class="btn btn-teal w-52" [disabled]="!lectureType" (click)="next()">
          <span>Next</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="onSelected">
      <form [formGroup]="form" (submit)="submit()" #ngForm="ngForm">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
      </form>
    </ng-container>
  </div>
  <div class="flex justify-end gap-2 border-t border-neutral-50 px-6 py-4" *ngIf="onSelected">
    <div class="flex items-center justify-between gap-2" *ngIf="!vm.isEdit">
      <button class="btn btn-neutral" (click)="cancel()">
        <span>Cancel</span>
      </button>
      <button class="btn btn-teal" (click)="submit()">
        <span *ngIf="lectureType === 'video'">Add video</span>
        <span *ngIf="lectureType === 'article'">Add article</span>
      </button>
    </div>
    <div class="flex w-full items-center justify-between gap-2" *ngIf="vm.isEdit">
      <div class="max-w-xs text-sm text-neutral-500 max-sm:text-center">
        You can close this window any time. it will not interrupt ongoing uploads.
      </div>
      <div class="flex gap-2">
        <button class="btn btn-neutral" (click)="cancel()">
          <span>Cancel</span>
        </button>
        <button class="btn btn-teal" (click)="submit()">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
