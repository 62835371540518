import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _ from 'lodash';
import { map, Observable, switchMap } from 'rxjs';
import {
  AssignmentDetailV2,
  AssignmentQuestionV2,
  CourseActions,
  Logger,
  Mutable,
  selectAssignmentDetailByAssignmentIdV2,
  selectSelectedActivityIdV2,
} from 'thkee-common';

const log = new Logger('AssignmentComponent');

@UntilDestroy()
@Component({
  selector: 'app-activity-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class AssignmentComponent implements OnInit {
  @ViewChild('formAssignment') formAssignment: Mutable<NgForm> | undefined;

  @Output() createEvent = new EventEmitter<Partial<any>>();
  @Output() saveEvent = new EventEmitter<Partial<any>>();
  @Output() cancelEvent = new EventEmitter<Partial<any>>();

  assignmentId$!: Observable<string>;
  assignment$!: Observable<AssignmentDetailV2>;

  readonly form: FormGroup = new FormGroup({});
  readonly options: FormlyFormOptions = {};
  model: Partial<AssignmentDetailV2> = {
    title: '',
    questions: [
      {
        id: '',
        question: '',
        assignment: '',
      },
    ],
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Title',
        placeholder: '',
        required: true,
        minLength: 6,
        maxLength: 60,
        hideRequiredMarker: true,
      },
      validation: {
        messages: {
          minLength: "Title can't be lower than 6 characters.",
        },
      },
    },
    {
      key: 'questions',
      type: 'repeat',
      props: {
        addText: 'Add Question',
        min: 1,
        max: 5,
        deleteConfirmation: true,
        onDelete: (field: FormlyFieldConfig, model: Partial<AssignmentQuestionV2>) => {
          if (model?.id) {
            this.store.dispatch(CourseActions.deleteAssignmentQuestion({ assignmentQuestionId: model.id }));
          }
        },
      },
      expressions: {
        hide: () => !this.model.id,
      },
      fieldArray: {
        fieldGroup: [
          {
            type: 'input',
            key: 'question',
            props: {
              label: 'Question',
              required: true,
              placeholder: 'Your question',
              wrapClass: 'input-group mb-2',
              hideRequiredMarker: true,
            },
          },
          {
            type: 'input',
            key: 'expected',
            defaultValue: '',
            props: {
              label: 'Your answer (Optional)',
              hideLabel: true,
              placeholder: 'Your answer (Optional)',
              wrapClass: 'mb-4',
            },
          },
        ],
      },
    },
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.assignmentId$ = this.store.select(selectSelectedActivityIdV2);
    this.assignment$ = this.assignmentId$.pipe(
      switchMap((assignmentId) => {
        return this.store.select(selectAssignmentDetailByAssignmentIdV2(assignmentId));
      }),
      map((assignment) => _.cloneDeep(assignment))
    );
    // init model
    this.assignment$.pipe(untilDestroyed(this)).subscribe((assignment) => {
      this.model = {
        ...assignment,
        questions: assignment.questions.length > 0 ? assignment.questions : [],
      };
      log.debug('assignment model', this.model, JSON.stringify(this.model));
    });
  }

  cancel(): void {
    this.cancelEvent.emit(this.model);
  }

  submitAssignment(action: string = 'new'): void {
    this.form.markAsTouched();
    (this.formAssignment as any).submitted = true;
    if (this.form.valid) {
      this.form.markAsUntouched();
      if (action == 'new') {
        console.log('Assign Form Valid and Created - ', this.model);
        this.createEvent.emit(this.model);
      }
      if (action == 'save') {
        console.log('Assign Form Valid and Save - ', this.model);
        this.saveEvent.emit(this.model);
      }
      console.log('Assign Fields - ', this.fields);

      this.form.markAsUntouched();
      if (this.formAssignment) {
        this.formAssignment.submitted = false;
      }
      // if (typeof this.options.resetModel === 'function' && this.options) {
      //   this.options.resetModel();
      // }
    }
  }
}
