import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  AUTO_SAVE_DEBOUNCE_TIME_MS,
  PricingCountryService,
  SharedModule,
  TaxSettings,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import { TaxConfigService } from '@shared/services/price-management/tax-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime, map, skip } from 'rxjs';
import { ConstantCountryOption, ModalService, PaginationService, ToastService } from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-tax-settings',
  standalone: true,
  imports: [AngularSvgIconModule, RouterLink, ReactiveFormsModule, FormsModule, SharedModule],
  templateUrl: './tax-settings.component.html',
  styleUrl: './tax-settings.component.scss',
})
export class TaxSettingsComponent implements OnInit {
  showSubmit: boolean = true;
  pricesIncludeTax$ = new BehaviorSubject<string>('Yes');
  hideTaxInOrderSummary$ = new BehaviorSubject<string>('Yes');
  detectCountryByIP$ = new BehaviorSubject<string>('Yes');

  countryOption: { label: string; value: string | number }[] = [];
  isLoading: boolean = true;
  action: string = 'create';

  settingsForm = new FormGroup({});
  settingsFormModel: TaxSettings = {
    id: 0,
    date_created: '',
    date_updated: '',
    default_global_percentage: '',
    tax_based_on: '',
    detect_country_by_ip: false,
    prices_include_tax: false,
    tax_display_type: '',
    hide_tax_in_order_summary: false,
    tax_type: '',
    default_country: '',
    default_state_province: '',
    city: '',
    address: '',
    mobile: '',
    default_zip_code: '',
    active: false,
  }; //TODO: Update model here
  settingsFormFields: FormlyFieldConfig[] = [];

  constructor(
    private taxConfigService: TaxConfigService,
    private router: Router,
    private toastService: ToastService,
    private paginationService: PaginationService,
    private pricingCountryService: PricingCountryService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.initFields();

    combineLatest([this.pricingCountryService.getConstantCountries(), this.taxConfigService.getTaxSettings()])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([countries, setting]) => {
          this.settingsForm.patchValue(setting[0]);
          this.countryOption = countries.map((country: ConstantCountryOption) => ({
            label: _.startCase(country.name),
            value: country.code ?? '',
          }));
          this.isLoading = false;
          this.initFields();
        },
        error: ([{ countriesError }, { settingError }]) => {
          this.isLoading = false;
          countriesError.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
          settingError.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });

    // if (this.isLoading) {
    this.settingsForm.valueChanges
      .pipe(debounceTime(AUTO_SAVE_DEBOUNCE_TIME_MS), skip(2), untilDestroyed(this))
      .subscribe((value) => {
        this.modalService.confirm({
          title: 'Are you sure?',
          message: `You want to change your settings?`,
          onConfirm: () => {
            this.updateSettings();
          },
        });
      });
    // }
  }

  initFields() {
    this.settingsFormFields = [
      {
        template: '<label class="block font-bold w-full text-left text-lg my-6">Common</label>',
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Tax Based on</label>',
          },
          {
            key: 'tax_based_on',
            type: 'select',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Choose Tax Based',
              required: true,
              options: [
                {
                  label: 'Billing Address',
                  value: 'billing_address',
                },
                {
                  label: 'User Country',
                  value: 'user_country',
                },
                {
                  label: 'IP Address Country',
                  value: 'ip_address_country',
                },
                {
                  label: 'Shipping Address',
                  value: 'shipping_address',
                },
                {
                  label: 'Default Address',
                  value: 'default_address',
                },
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold w-52 text-right">Detect Country by IP</label>',
          },
          {
            key: 'detect_country_by_ip',
            type: 'toggle',
            defaultValue: true,
            className: 'w-full max-w-[400px]',
            props: {
              wrapClass: '!mb-0',
              label: 'Yes',
              tips: 'This is a tip message for course title',
            },
            expressions: {
              'props.label': this.detectCountryByIP$.pipe(
                map((detectCountryByIP) => {
                  return detectCountryByIP;
                })
              ),
            },
            hooks: {
              onInit: (field) => {
                // On change event
                if (field.formControl) {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.detectCountryByIP$.next(value ? 'Yes' : 'No');
                  });
                }
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Default Global Percentage</label>',
          },
          {
            key: 'default_global_percentage',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Default Global Percentage',
              required: true,
            },
          },
        ],
      },
      {
        template: '<label class="block font-bold w-full text-left text-lg my-6">Default Tax address</label>',
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Default Country</label>',
          },
          {
            key: 'default_country',
            type: 'select',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Choose Default Country',
              required: true,
              options: this.countryOption,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">State/Province</label>',
          },
          {
            key: 'default_state_province',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'State/Province',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Region/City</label>',
          },
          {
            key: 'city',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Region/City',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Address</label>',
          },
          {
            key: 'address',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Address',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">ZIP Code</label>',
          },
          {
            key: 'default_zip_code',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'ZIP Code',
              required: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Phone Number</label>',
          },
          {
            key: 'mobile',
            type: 'input',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'ZIP Code',
              required: true,
            },
          },
        ],
      },
      {
        template: '<label class="block font-bold w-full text-left text-lg my-6">Tax Displaying</label>',
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold w-52 text-right">Price including Tax</label>',
          },
          {
            key: 'prices_include_tax',
            type: 'toggle',
            defaultValue: true,
            className: 'w-full max-w-[400px]',
            props: {
              wrapClass: '!mb-0',
              label: 'Yes',
              tips: 'This is a tip message for course title',
            },
            expressions: {
              'props.label': this.pricesIncludeTax$.pipe(
                map((pricesIncludeTax) => {
                  return pricesIncludeTax;
                })
              ),
            },
            hooks: {
              onInit: (field) => {
                // On change event
                if (field.formControl) {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.pricesIncludeTax$.next(value ? 'Yes' : 'No');
                  });
                }
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Tax Type</label>',
          },
          {
            key: 'tax_type',
            type: 'select',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Choose Tax Type',
              required: true,
              options: [
                {
                  label: 'By Country',
                  value: 'by_country',
                },
                {
                  label: 'Fixed Rate',
                  value: 'fixed_rate',
                },
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block font-bold w-52 text-right">Tax Display Type</label>',
          },
          {
            key: 'tax_display_type',
            type: 'select',
            className: 'w-full max-w-[400px]',
            props: {
              hideLabel: true,
              placeholder: 'Choose Tax Display Type',
              required: true,
              options: [
                {
                  label: 'Include',
                  value: 'incl',
                },
                {
                  label: 'Exclude',
                  value: 'excl',
                },
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'flex gap-4 items-center justify-center',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold w-52 text-right">Hide tax in order summary</label>',
          },
          {
            key: 'hide_tax_in_order_summary',
            type: 'toggle',
            defaultValue: true,
            className: 'w-full max-w-[400px]',
            props: {
              wrapClass: '!mb-0',
              label: 'Yes',
              tips: 'This is a tip message for course title',
            },
            expressions: {
              'props.label': this.hideTaxInOrderSummary$.pipe(
                map((hideTaxInOrderSummary) => {
                  return hideTaxInOrderSummary;
                })
              ),
            },
            hooks: {
              onInit: (field) => {
                // On change event
                if (field.formControl) {
                  field.formControl.valueChanges.subscribe((value) => {
                    this.hideTaxInOrderSummary$.next(value ? 'Yes' : 'No');
                  });
                }
              },
            },
          },
        ],
      },
    ];
  }

  updateSettings() {
    this.taxConfigService.updateTaxSettings(this.settingsFormModel).subscribe({
      next: (res) => {},
      error: ({ error }) => {
        error.errors.map((error: { detail: string }) =>
          this.toastService.error({
            message: error.detail,
          })
        );
      },
    });
  }

  reset() {
    this.settingsForm.reset();
  }
}
