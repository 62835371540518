import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CmdKey } from '@milkdown/core';
import {
  createCodeBlockCommand,
  insertHrCommand,
  toggleEmphasisCommand,
  toggleStrongCommand,
  wrapInBlockquoteCommand,
  wrapInBulletListCommand,
  wrapInHeadingCommand,
  wrapInOrderedListCommand,
} from '@milkdown/preset-commonmark';
import { actionFactory, NgMilkdownProvider } from 'ng-milkdown';

import { RouterLink } from '@angular/router';
import { insertTableCommand, toggleStrikethroughCommand } from '@milkdown/kit/preset/gfm';
import { redoCommand, undoCommand } from '@milkdown/plugin-history';
import { callCommand } from '@milkdown/utils';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderService } from 'thkee-common';

export interface EditorTopBarItemsConfig {
  undo?: boolean;
  redo?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  table?: boolean;
  divider?: boolean;
  bulletList?: boolean;
  orderedList?: boolean;
  codeBlock?: boolean;
  quoteBlock?: boolean;
  imageUpload?: boolean;
}

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [RouterLink, AngularSvgIconModule],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss',
})
export class TopBarComponent implements AfterViewInit, AfterViewChecked {
  @Input() provider!: NgMilkdownProvider;
  @Input() topBarItemsConfig!: EditorTopBarItemsConfig;

  @Output() topBarAction: EventEmitter<string> = new EventEmitter();
  @Output() topBarExpand: EventEmitter<boolean> = new EventEmitter();

  navBarItems: any[] = [];

  constructor(private loaderService: LoaderService) {}

  ngAfterViewInit(): void {}
  ngAfterViewChecked(): void {
    this.initTabItems();
  }

  isExpand: boolean = false;
  expandAction() {
    this.isExpand = !this.isExpand;
    this.topBarExpand.emit(this.isExpand);
  }

  initTabItems() {
    this.navBarItems = [
      {
        title: 'Undo',
        icon: '@assets/images/icons/editors/undo.svg',
        slice: undoCommand.key,
        enabled: this.topBarItemsConfig.undo,
      },
      {
        title: 'Redo',
        icon: '@assets/images/icons/editors/redo.svg',
        slice: redoCommand.key,
        enabled: this.topBarItemsConfig.redo,
      },
      {
        title: 'Heading 1',
        icon: '@assets/images/icons/editors/heading-01.svg',
        slice: wrapInHeadingCommand.key,
        payload: 1,
        className: ['hidden-xs'],
        enabled: this.topBarItemsConfig.h1,
      },
      {
        title: 'Heading 2',
        icon: '@assets/images/icons/editors/heading-02.svg',
        slice: wrapInHeadingCommand.key,
        payload: 2,
        className: ['hidden-sm'],
        enabled: this.topBarItemsConfig.h2,
      },
      {
        title: 'Heading 3',
        icon: '@assets/images/icons/editors/heading-03.svg',
        slice: wrapInHeadingCommand.key,
        payload: 3,
        className: ['hidden-sm'],
        enabled: this.topBarItemsConfig.h3,
      },
      {
        title: 'Heading 4',
        icon: '@assets/images/icons/editors/heading-04.svg',
        slice: wrapInHeadingCommand.key,
        payload: 4,
        className: ['hidden-sm'],
        enabled: this.topBarItemsConfig.h4,
      },
      {
        title: 'Heading 5',
        icon: '@assets/images/icons/editors/heading-05.svg',
        slice: wrapInHeadingCommand.key,
        payload: 5,
        className: ['hidden-sm'],
        enabled: this.topBarItemsConfig.h5,
      },
      {
        title: 'Bold',
        icon: '@assets/images/icons/editors/format_bold.svg',
        slice: toggleStrongCommand.key,
        enabled: this.topBarItemsConfig.bold,
      },
      {
        title: 'Italic',
        icon: '@assets/images/icons/editors/format_italic.svg',
        slice: toggleEmphasisCommand.key,
        enabled: this.topBarItemsConfig.italic,
      },
      {
        title: 'Strikethrough',
        icon: '@assets/images/icons/editors/format_strikethrough.svg',
        slice: toggleStrikethroughCommand.key,
        enabled: this.topBarItemsConfig.strikethrough,
      },
      {
        title: 'Table',
        icon: '@assets/images/icons/editors/table.svg',
        slice: insertTableCommand.key,
        enabled: this.topBarItemsConfig.table,
      },
      {
        title: 'Divider',
        icon: '@assets/images/icons/editors/horizontal_line.svg',
        slice: insertHrCommand.key,
        payload: { mode: 'horizontal' },
        enabled: this.topBarItemsConfig.divider,
      },
      {
        title: 'Bullet List',
        icon: '@assets/images/icons/editors/list_bullet.svg',
        slice: wrapInBulletListCommand.key,
        enabled: this.topBarItemsConfig.bulletList,
      },
      {
        title: 'Ordered List',
        icon: '@assets/images/icons/editors/numbered_list.svg',
        slice: wrapInOrderedListCommand.key,
        enabled: this.topBarItemsConfig.orderedList,
      },
      {
        title: 'Code Block',
        icon: '@assets/images/icons/editors/code_bracket.svg',
        slice: createCodeBlockCommand.key,
        enabled: this.topBarItemsConfig.codeBlock,
      },
      {
        title: 'Quote Block',
        icon: '@assets/images/icons/editors/format_quote.svg',
        slice: wrapInBlockquoteCommand.key,
        enabled: this.topBarItemsConfig.quoteBlock,
      },
      {
        title: 'Image Upload',
        icon: '@assets/images/icons/editors/photo.svg',
        click: () => {
          this.insertImageBlock();
        },
        enabled: this.topBarItemsConfig.imageUpload,
      },
    ];
  }

  get action() {
    return actionFactory(this.provider.editor);
  }

  onMouseDown(e: MouseEvent | TouchEvent, slice?: CmdKey<any>, payload?: any, click?: () => void) {
    e.preventDefault();
    if (slice) this.action(callCommand(slice, payload));
    if (click) click();
  }

  insertImageBlock() {
    this.topBarAction.emit('imageBlock');
  }
}

// createNodeView
// createPluginView
// createWidgetView
// el
// provider

// TODO: This feature will be implement latter
// {
//   title: 'Diagram',
//   icon: 'rebase',
//   slice: insertDiagramCommand.key,
//   className: ['hidden-sm']
// },
// {
//   title: 'Task List',
//   icon: 'checklist',
//   slice: insertTaskListCommand.key,
// },
// {
//   title: 'Math Block',
//   icon: 'functions',
//   slice: createMathBlockCommand.key,
// },
// {
//   title: 'Test collaborative Editing',
//   icon: 'partner_exchange',
//   className: ['hidden-sm'],
//   routerLink: ['/collaborative-editing']
// },
// {
//   title: 'Open copilot',
//   icon: 'smart_toy',
//   className: ['hidden-sm'],
//   click: () => this.copilotService.enabled = true,
//   hidden: () => this.copilotService.enabled
// },
// {
//   title: 'Config copilot',
//   icon: 'settings',
//   className: ['hidden-sm'],
//   click: () => {
//     const dialogRef = this.dialog.open(CopilotDialog, {
//       data: {
//         config: localStorage.getItem('openai-api-config') ?? "{}",
//         url: localStorage.getItem('openai-api-url'),
//         token: localStorage.getItem('openai-api-token')
//       },
//     });

//     dialogRef.afterClosed().subscribe(result => {
//       if (result) {
//         localStorage.setItem('openai-api-config', result.config || "{}");
//         localStorage.setItem('openai-api-url', result.url);
//         localStorage.setItem('openai-api-token', result.token);
//       }
//     });
//   },
//   hidden: () => this.copilotService.enabled
// },
// {
//   title: 'Copilot is running, click to close copilot',
//   icon: 'smart_toy',
//   className: ['hidden-sm', 'text-red-500'],
//   click: () => this.copilotService.enabled = false,
//   hidden: () => !this.copilotService.enabled
// }
