<article class="prose lg:prose-xl"></article>
<div class="relative h-full rounded-md border border-slate-300 pt-10">
  <div class="hidden" [innerHtml]="value" id="pre"></div>
  <app-top-bar
    class="absolute top-0 w-full opacity-80"
    [topBarItemsConfig]="topBarItemsConfig"
    (topBarAction)="topBarAction($event)"
    (topBarExpand)="topBarExpand($event)"
    [provider]="provider"
  />
  <div [ngClass]="isExpand ? 'max-h-screen' : 'max-h-96 max-sm:max-h-64'" class="ctn min-h-60 overflow-auto overscroll-none px-0">
    <ng-milkdown-provider #provider>
      <ng-milkdown
        [config]="config"
        [plugins]="plugins"
        [(ngModel)]="value"
        [(loading)]="loading"
        (ngModelChange)="onChange($event)"
        (onReady)="onEditorReady($event)"
        [spinner]="spinner"
      />
      <ng-template #spinner>
        <app-spinner />
      </ng-template>
    </ng-milkdown-provider>
  </div>
</div>
