import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { COURSE_TAB, ModalComponent, TabItem, UntilDestroy, untilDestroyed } from '@shared';
import * as _ from 'lodash';
import { Observable, distinctUntilChanged, firstValueFrom, map, of, switchMap } from 'rxjs';
import {
  AnnotationDetail,
  AnnotationService,
  ContentTypeApps,
  ContentTypeModels,
  CourseActions,
  CourseLernerContent,
  FeedbackState,
  InstructorService,
  IntendedLearnerTypes,
  LoaderService,
  Logger,
  ProjectService,
  RouterStoreService,
  generateUuid,
  selectContentType,
  selectCourseLearnerV2,
  selectCourseV2,
  selectFeedback,
} from 'thkee-common';
import { HeaderActions } from '../components/course-header/course-header.component';

const log = new Logger('CourseIntendedComponent');

@UntilDestroy()
@Component({
  selector: 'app-intended',
  templateUrl: './intended.component.html',
  styleUrls: ['./intended.component.scss'],
})
export class CourseIntendedComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  @ViewChild('feedbackModal') feedbackModal!: ModalComponent;
  courseState: string = '0';
  readOnly: boolean = true;
  form = new FormGroup({});
  // model!: { objectives: ObjectiveV2[]; requirements: RequirementV2[]; learners: LearnerV2[] };
  model: {
    objectives: CourseLernerContent[];
    requirements: CourseLernerContent[];
    learners: CourseLernerContent[];
  } = {
    objectives: [],
    requirements: [],
    learners: [],
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  haveFeedbackHistory: boolean = false;

  courseId: string = '';
  courseId$!: Observable<string>;
  course$ = this.store.select(selectCourseV2);
  feedback$!: Observable<FeedbackState>;
  isLoading: boolean = false;
  isLoading$ = this.loaderService.response('loading-admin-courses-learnerV2');
  tabItems: TabItem[] = COURSE_TAB;
  courseIntendedV2$ = this.store.select(selectCourseLearnerV2);

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private instructorService: InstructorService,
    private projectService: ProjectService,
    private annotationService: AnnotationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.courseId$ = this.routerStore.getParam('courseId');
    this.feedback$ = this.store.select(selectFeedback);

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    // firstValueFrom(this.courseId$).then((courseId) => {
    //   this.isLoading = true;
    //   combineLatest([
    //     this.instructorService.getCourseIntended(courseId, 'objectives'),
    //     this.instructorService.getCourseIntended(courseId, 'requirements'),
    //     this.instructorService.getCourseIntended(courseId, 'learners'),
    //   ])
    //     .pipe(untilDestroyed(this))
    //     .subscribe(([objectives, requirements, learners]) => {
    //       this.isLoading = false;
    //       this.model = {
    //         ...this.model,
    //         objectives: _.isEmpty(objectives) ? [{}, {}, {}, {}] : objectives,
    //         requirements: _.isEmpty(requirements) ? [{}] : requirements,
    //         learners: _.isEmpty(learners) ? [{}] : learners,
    //       };
    //       this.initFields();
    //     });
    // });

    this.courseIntendedV2$.pipe(untilDestroyed(this)).subscribe((data) => {
      data = _.cloneDeep(data);
      this.model = {
        ...this.model,
        objectives: _.isEmpty(data.objectives) ? this.createEmptyPlaceholders(4) : data.objectives,
        requirements: _.isEmpty(data.requirements) ? this.createEmptyPlaceholders(1) : data.requirements,
        learners: _.isEmpty(data.learners) ? this.createEmptyPlaceholders(1) : data.learners,
      };
      this.initFields();
    });

    this.feedback$.pipe(untilDestroyed(this)).subscribe((feedback) => {
      log.debug('feedback', feedback);
    });

    this.initFields();

    // handle feedback modal open/close
    this.feedback$
      .pipe(
        map((feedback) => feedback.isFeedbackModalOpen),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((isFeedbackModalOpen) => {
        if (this.feedbackModal) {
          if (isFeedbackModalOpen) {
            this.feedbackModal.open();
          } else {
            this.feedbackModal.close();
          }
        }
      });
  }

  private createEmptyPlaceholders(count: number): CourseLernerContent[] {
    return Array.from({ length: count }, () => ({
      id: '',
      date_updated: '',
      content: '',
      source: '',
      course: '',
    }));
  }

  initFields(disabled: boolean = this.readOnly) {
    if (!disabled) {
      this.courseState = '1';
    }

    let className = 'pb-1 ';
    if (!disabled) {
      className = className + 'mb-4 border-b border-neutral-200';
    }

    this.fields = [
      {
        className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
        template: '<h5>Intended Learners</h5>',
      },
      {
        key: 'objectives',
        type: 'repeat',
        className: className,
        props: {
          labelClass: '!text-xl',
          label: 'Course Objectives',
          addText: 'Add Objectives',
          deleteConfirmation: true,
          min: 0,
          disabled: disabled,
          tips: 'Course Objectives',
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'input',
              key: 'content',
              defaultValue: '',
              wrappers: ['feedback-field'],
              props: {
                labelClass: '!text-base',
                wrapAppendClass: ['!mb-0'],
                required: true,
                placeholder: 'e.g. Learn about Data Structures',
                tips: 'This is a tip message for course title',
                hideLabel: true,
                maxLength: 160,
                disabled: disabled,
                // need to set initial feedback count here, so that change reflects when updated in hools.onInit
                feedback: 0,
                openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field, ContentTypeModels.OBJECTIVE),
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  this.annotationService
                    .getAnnotationCountV2(String(field.key), ContentTypeModels.OBJECTIVE, field.model.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((feedbackCount) => {
                      if (field.props) {
                        field.props['feedback'] = feedbackCount;
                      }
                    });
                },
              },
            },
          ],
        },
      },
      {
        key: 'requirements',
        type: 'repeat',
        className: className,
        props: {
          label: 'Requirements',
          addText: 'Add Requirement',
          deleteConfirmation: true,
          min: 0,
          tips: 'Add Requirement',
          disabled: disabled,
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'input',
              key: 'content',
              defaultValue: '',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['!mb-0'],
                required: true,
                placeholder: 'e.g. Learn about Data Structures',
                hideLabel: true,
                maxLength: 160,
                disabled: disabled,
                feedback: 0,
                openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field, ContentTypeModels.REQUIREMENT),
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  this.annotationService
                    .getAnnotationCountV2(String(field.key), ContentTypeModels.REQUIREMENT, field.model.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((feedbackCount) => {
                      if (field.props) {
                        field.props['feedback'] = feedbackCount;
                      }
                    });
                },
              },
            },
          ],
        },
      },
      {
        key: 'learners',
        type: 'repeat',
        props: {
          label: 'Who this course is for',
          addText: 'Add Target Learners',
          deleteConfirmation: true,
          min: 0,
          tips: 'Add Target Learners',
          disabled: disabled,
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'input',
              key: 'content',
              defaultValue: '',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['!mb-0'],
                required: true,
                placeholder: 'e.g. Learn about Data Structures',
                hideLabel: true,
                maxLength: 160,
                disabled: disabled,
                feedback: 0,
                openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field, ContentTypeModels.LEARNER),
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  this.annotationService
                    .getAnnotationCountV2(String(field.key), ContentTypeModels.LEARNER, field.model.id)
                    .pipe(untilDestroyed(this))
                    .subscribe((feedbackCount) => {
                      if (field.props) {
                        field.props['feedback'] = feedbackCount;
                      }
                    });
                },
              },
            },
          ],
        },
      },
    ];
  }

  actionEvent(event: HeaderActions) {
    this.readOnly = true;
    this.initFields(true);
    this.courseState = '0';

    if (event === 'update') {
      log.debug('update', this.model);

      this.model.objectives.forEach((objective) => {
        this.updateIntended(objective.id, 'objectives', objective.content);
      });
      this.model.requirements.forEach((requirement) => {
        this.updateIntended(requirement.id, 'requirements', requirement.content);
      });
      this.model.learners.forEach((learner) => {
        this.updateIntended(learner.id, 'learners', learner.content);
      });
    } else if (event === 'accept') {
      firstValueFrom(this.course$).then((course) => {
        this.projectService.toStatePublished(course.project);
      });
    } else if (event === 'reject') {
      firstValueFrom(this.course$).then((course) => {
        this.projectService.toStateRejected(course.project);
      });
    }
  }

  private updateIntended(id: string, type: IntendedLearnerTypes, value: string) {
    firstValueFrom(this.courseId$).then((courseId) => {
      if (courseId) {
        this.instructorService
          .createCourseIntended(courseId, type, {
            id,
            content: value,
            course: courseId,
          })
          .pipe(
            switchMap((value) => {
              this.updateModel(id, type, value);
              return of(value);
            }),
            untilDestroyed(this)
          )
          .subscribe();
      }
    });
  }

  private updateModel(id: string, type: IntendedLearnerTypes, value: any) {
    const findNew = _.findIndex(this.model[type], { id: id, content: value.content });
    if (this.model[type][findNew]) {
      this.model[type][findNew] = value;
    }
  }

  onFeedbackOpen(field: FormlyFieldConfig, modelType: ContentTypeModels) {
    log.debug('onFeedbackOpen', field.key);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: String(field.key),
        fieldValue: field.formControl?.value,
        fieldModel: modelType,
        fieldId: field.model?.id,
      })
    );
  }

  async onFeedbackSubmit(annotation: Partial<AnnotationDetail>) {
    log.debug('onFeedbackSubmit');

    const feedback = await firstValueFrom(this.feedback$);
    const contentType = await firstValueFrom(
      this.store.select(selectContentType(ContentTypeApps.COURSES, feedback.fieldModel))
    );
    this.store.dispatch(
      CourseActions.upsertAnnotation({
        annotation: {
          ...annotation,
          id: annotation.id ?? generateUuid(),
          content_type: contentType?.id ?? 0,
          course: this.courseId,
          feedback: annotation.feedback ?? '',
          field: feedback.fieldKey,
          object_id: feedback.fieldId,
          required_action: annotation.required_action ?? false,
          model: feedback.fieldModel,
        },
      })
    );
  }

  onFeedbackCancel() {
    log.debug('onFeedbackCancel');

    this.store.dispatch(CourseActions.closeFeedbackModal());
  }
}
