import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'thk-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  standalone: true,
  imports: [NgIf, AngularSvgIconModule],
})
export class AccordionComponent {
  showing = false;

  toggle() {
    this.showing = !this.showing;
  }

  open() {
    if (!this.showing) {
      this.showing = true;
    }
  }

  close() {
    if (this.showing) {
      this.showing = false;
    }
  }
}
