import { Component } from '@angular/core';
import { CmdKey } from '@milkdown/core';
import { redoCommand, undoCommand } from '@milkdown/plugin-history';
import { TooltipProvider } from '@milkdown/plugin-tooltip';
import { toggleEmphasisCommand, toggleStrongCommand, wrapInBlockquoteCommand } from '@milkdown/preset-commonmark';
import { toggleStrikethroughCommand } from '@milkdown/preset-gfm';
import { callCommand } from '@milkdown/utils';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgMilkdownTooltip } from 'ng-milkdown';

import { CommonModule } from '@angular/common';
import { TooltipProviderOptions } from '@milkdown/plugin-tooltip';

interface ExtendedTooltipProviderOptions extends TooltipProviderOptions {
  tippyOptions?: {
    appendTo: HTMLElement;
  };
}

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [NgMilkdownTooltip, AngularSvgIconModule, CommonModule],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
  providers: [],
})
export class TooltipComponent extends NgMilkdownTooltip {
  buttons = [
    [
      {
        // icon: 'undo',
        icon: '@assets/images/icons/editors/undo.svg',
        title: 'Undo',
        command: undoCommand.key,
      },
      {
        icon: '@assets/images/icons/editors/redo.svg',
        title: 'Redo',
        command: redoCommand.key,
      },
    ],
    [
      {
        icon: '@assets/images/icons/editors/format_bold.svg',
        title: 'Bold',
        command: toggleStrongCommand.key,
      },
      {
        icon: '@assets/images/icons/editors/format_italic.svg',
        title: 'Italic',
        command: toggleEmphasisCommand.key,
      },
      {
        icon: '@assets/images/icons/editors/format_strikethrough.svg',
        title: 'Strikethrough',
        command: toggleStrikethroughCommand.key,
      },
      {
        icon: '@assets/images/icons/editors/format_quote.svg',
        title: 'Quote',
        command: wrapInBlockquoteCommand.key,
      },
    ],
  ];

  mousedown(e: MouseEvent | TouchEvent, cmd: CmdKey<any>) {
    e.preventDefault();
    this.action(callCommand(cmd));
  }

  override get pluginView() {
    return new TooltipProvider({
      debounce: 50,
      content: this.container,
      shouldShow: (view) => {
        const { from, to } = view.state.selection;
        return from !== to && view.state.doc.nodeAt(from)?.type.name === 'text';
      },
      tippyOptions: { appendTo: document.body },
    } as ExtendedTooltipProviderOptions) as any;
  }
}
