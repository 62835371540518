import { Injectable } from '@angular/core';
import {
  CountryTaxLists,
  CreateTaxModel,
  FixedRateTax,
  TaxCategoryLists,
  TaxProviderLists,
  TaxProviderStatusUpdatePayload,
  TaxSettings,
} from '@shared/models';
import { Observable } from 'rxjs';
import {
  API_GET_COUNTRY_TAX_DETAILS,
  API_GET_COUNTRY_TAX_LISTS,
  API_GET_FIXED_TAX_LISTS,
  API_GET_POST_TAX_CATEGORY_LISTS,
  API_GET_TAX_PROVIDER_LISTS,
  API_GET_TAX_SETTINGS,
  API_POST_COUNTRY_TAX_CREATE,
  API_PUT_COUNTRY_TAX_UPDATE,
  API_PUT_FIXED_TAX_UPDATE,
  API_PUT_POST_TAX_CATEGORY_UPDATE,
  API_PUT_TAX_PROVIDER_STATUS_UPDATE,
  encodeURL,
  HttpService,
  Pagination,
  Params,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class TaxConfigService {
  constructor(private readonly http: HttpService) {}

  getTaxProviderLists(params: Params): Observable<Pagination<TaxProviderLists>> {
    return this.http.get<Pagination<TaxProviderLists>>(encodeURL(API_GET_TAX_PROVIDER_LISTS, params));
  }

  updateTaxProviderLists(payload: TaxProviderStatusUpdatePayload, id: string): Observable<TaxProviderLists> {
    return this.http.put<TaxProviderLists>(API_PUT_TAX_PROVIDER_STATUS_UPDATE.replace('<ID>', id), payload);
  }

  getTaxCategoryLists(params: Params): Observable<Pagination<TaxCategoryLists>> {
    return this.http.get<Pagination<TaxCategoryLists>>(encodeURL(API_GET_POST_TAX_CATEGORY_LISTS, params));
  }

  createTaxCategory(payload: TaxCategoryLists): Observable<TaxCategoryLists> {
    return this.http.post<TaxCategoryLists>(API_GET_POST_TAX_CATEGORY_LISTS, payload);
  }

  updateTaxCategory(payload: TaxCategoryLists, taxCategoryId: string) {
    return this.http.put(API_PUT_POST_TAX_CATEGORY_UPDATE.replace('<ID>', taxCategoryId), payload);
  }

  getTaxSettings(): Observable<TaxSettings[]> {
    return this.http.get<TaxSettings[]>(API_GET_TAX_SETTINGS);
  }

  updateTaxSettings(payload: TaxSettings): Observable<TaxSettings> {
    return this.http.post<TaxSettings>(API_GET_TAX_SETTINGS, payload);
  }

  createCountryTax(payload: CreateTaxModel): Observable<CountryTaxLists> {
    return this.http.post<CountryTaxLists>(API_POST_COUNTRY_TAX_CREATE, payload);
  }

  getCountryTaxLists(params: Params): Observable<Pagination<CountryTaxLists>> {
    return this.http.get<Pagination<CountryTaxLists>>(encodeURL(API_GET_COUNTRY_TAX_LISTS, params));
  }

  getCountryTaxByCountryCode(countryCode: string): Observable<CountryTaxLists> {
    return this.http.get<CountryTaxLists>(API_GET_COUNTRY_TAX_DETAILS.replace('<COUNTRY_CODE>', countryCode));
  }

  updateCountryTax(payload: CreateTaxModel, countryTaxId: string): Observable<CountryTaxLists> {
    let category: any = [];
    payload.categories.forEach((cat) => {
      category.push({ id: cat.category_id ?? cat.id, percentage: cat.percentage });
    });
    payload.categories = category;
    return this.http.put(API_PUT_COUNTRY_TAX_UPDATE.replace('<TAX_DEFAULT_ID>', countryTaxId), payload);
  }

  getFixedRateTaxLists(params: Params): Observable<FixedRateTax> {
    return this.http.get<FixedRateTax>(encodeURL(API_GET_FIXED_TAX_LISTS, params));
  }

  updateFixedRateTaxLists(
    payload: { global_tax_category_id: number; fixed_percentage: string },
    globalFixedTaxId: string
  ) {
    return this.http.put(API_PUT_FIXED_TAX_UPDATE.replace('<GLOBAL_FIXED_TAX_ID>', globalFixedTaxId), payload);
  }
}
