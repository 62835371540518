<div class="flex h-10 w-full justify-between border-b border-slate-300 bg-slate-100 max-sm:overflow-x-auto">
  <div class="prose flex">
    @for (item of navBarItems;track $index) { @if (item.enabled) {
    <div
      class="flex h-10 w-10 cursor-pointer items-center justify-center rounded hover:bg-slate-200"
      [title]="item.title"
      (mousedown)="onMouseDown($event, item.slice, item.payload, item.click)"
      (touchstart)="onMouseDown($event, item.slice, item.payload, item.click)"
      [class]="item.className"
    >
      <svg-icon class="btn-icon" [src]="item.icon"></svg-icon>
    </div>
    } }
  </div>
  <button type="button" class="ltr:mr-3 rtl:ml-3" (click)="expandAction()">
    @if (isExpand) {
    <svg-icon class="btn-icon" src="@assets/images/icons/arrows-pointing-in.svg"></svg-icon>
    } @if (!isExpand) {
    <svg-icon class="btn-icon" src="@assets/images/icons/arrows-pointing-out.svg"></svg-icon>
    }
  </button>
</div>
