<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4">
      <h3 i18n>Course Topics</h3>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <button (click)="topicAction('Add')" class="btn px-6 py-3 bg-blue-600 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/plus.svg" class="text-white h-4 w-4"></svg-icon>
         <span class="text-white font-semibold text-sm" i18n>Create New Topics</span>
      </button>
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div (ClickOutside)="handleClickOutside(1)" class="relative">
            <button (click)="dropdown(1)" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span i18n>Filter</span>
            </button>
            <div [ngClass]="isDropdown[1] ? '':'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
               <div class="flex flex-col">
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5" i18n>
                       Course category
                     </div>
                     <div [ngClass]="seeMore?'h-auto':'max-h-40 overflow-y-hidden transition-all'">
                       <app-filter-option #filterCategory key="subcategory" [options]="filterOptions.subcategory"
                         [defaultValue]="filterValues.subcategory" (filterEvent)="filterHandler($event)">
                       </app-filter-option>
                     </div>
                     <button class="btn border-0 pt-0 justify-start" type="button" (click)="seeMore = !seeMore">
                       <svg-icon class="btn-icon h-6 w-6 transition-all" src="@assets/images/icons/chevron-down-solid.svg"
                         [class.rotate-180]="seeMore"></svg-icon>
                     </button>
                   </div>

                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5" i18n>
                       Status
                     </div>
                     <app-filter-option #filterApproved key="approved" [options]="filterOptions.approved"
                       [defaultValue]="filterValues.approved" (filterEvent)="filterHandler($event)">
                     </app-filter-option>
                   </div>
                  <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                     <button
                        (click)="clearFilter([filterCategory, filterApproved])"
                        type="button" class="btn btn-outline-dark border-neutral-600">
                        <span i18n>Reset</span>
                     </button>
                     <button
                        (click)="submitFilter([filterCategory, filterApproved])"
                        class="btn btn-dark bg-neutral-600 text-white">
                        <span i18n>Filter</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="input-combine flex">
            <div class="input-group">
               <div class="group">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Search" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center px-6 py-5">
               <h6>Course Topics</h6>
               <div class="tag tag-sm">{{totalTopics}} Course Topics</div>
         </div>

         <div class="flex flex-col min-h-[300px] relative">
            <ng-container *ngIf="isLoading; else loadedCategory">
               <ng-container *ngTemplateOutlet="loader"></ng-container>
            </ng-container>
            <ng-template #loadedCategory>
               <div *ngIf="topicsLists" class="card-body">
               <div class="courses-list">
                  <div class="datagrid-table relative overflow-x-auto">
                     <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Topics ID</span>
                                 <svg-icon tooltip="Topics ID" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Topics(EN)</span>
                                 <svg-icon tooltip="Topics english" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Topics(AR)</span>
                                 <svg-icon tooltip="Topics arabic" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Synonym</span>
                                 <svg-icon tooltip="Synonym" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Related Courses</span>
                                    <svg-icon tooltip="Related Courses" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="flex gap-2 items-center">
                                 <span>Related Subcategories</span>
                                 <svg-icon tooltip="Related Subcategories" src="@assets/images/icons/question-mark-solid.svg"
                                    class="h-5 w-5 text-primary-500">
                                 </svg-icon>
                              </div>
                           </th>
                           <th scope="col" class="text-left">
                              <span>Status</span>
                           </th>
                           <th scope="col" class="text-right min-w-44">
                              <span>Option</span>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="" *ngFor="let item of topicsLists.results; let i = index">
                           <td class="">
                              <span class="course-id flex items-center gap-2">
                                 <thk-ui-snippets [type]="'clipboardButton'" [clipboardInput]="item.id"
                                    customClass="w-11 h-11 !rounded-full">
                                 </thk-ui-snippets>
                                 <a *ngIf="item.id" class="hover:text-blue-600 hover:underline">
                                    #{{ item.id.substring(0, 8) }}
                                 </a>
                              </span>
                           </td>
                           <td>
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <a class=""> {{ item?.name }} </a>
                              </div>
                           </td>
                           <td>
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <a class=""> {{ item?.name_ar }} </a>
                              </div>
                           </td>
                           <td class="max-w-[170px]">
                              <span class="whitespace-nowrap text-blue-600 underline"> {{item.synonyms?.length}} </span>
                           </td>
                           <td>
                              <span class="capitalize"> {{item.courses_used}} </span>
                           </td>
                           <td>
                              <span class="course-price"> {{item.subcategories?.length}} </span>
                           </td>
                           <td>
                              <span *ngIf="item.approved" class="course-price bg-green-100 rounded-lg px-2 py-1 text-green-600">Active </span>
                              <span *ngIf="!item.approved" class="course-price bg-slate-100 rounded-lg px-2 py-1 text-slate-600"> Draft </span>
                           </td>
                           <td class="text-right">
                              <span class="course-sold">
                                 <button (click)="topicAction('Block', item)" *ngIf="!item.approved" class="btn p-3 bg-teal-600 rounded-[4px] border border-teal-500">
                                    <svg-icon src="@assets/images/icons/check.svg" class="text-white btn-icon">
                                    </svg-icon>
                                 </button>
                                 <button (click)="topicAction('Active', item)" *ngIf="item.approved" class="btn p-3 bg-transparent border border-rose-600 rounded-[4px]">
                                    <svg-icon src="@assets/images/icons/no-symbol.svg" class="text-rose-600 btn-icon">
                                    </svg-icon>
                                 </button>
                                 <button (click)="topicAction('Update', item)" class="btn p-3 border border-amber-50 bg-amber-50 rounded-[4px]">
                                    <svg-icon src="@assets/images/icons/pencil.svg" class="text-amber-600 btn-icon">
                                    </svg-icon>
                                 </button>
                                 <button (click)="topicAction('Delete', item)" class="btn p-3 border border-rose-600 bg-rose-600 rounded-[4px]">
                                    <svg-icon src="@assets/images/icons/trash.svg" class="text-white btn-icon">
                                    </svg-icon>
                                 </button>
                              </span>
                           </td>
                        </tr>
                     </tbody>
                     </table>
                  </div>
                  <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
               </div>
               </div>
            </ng-template>
         </div>
      </div>
   </div>
</div>

<!-- Sub Category modal -->
<common-modal #topicsFormModal customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl" i18n>{{topicActionType}} Topics</h1>
         </div>
         <button (click)="topicsFormModal.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <div class="form">
            <form [formGroup]="topicForm">
               <formly-form [form]="topicForm" [fields]="topicFormFields" [model]="topicFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="topicsFormModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="topicCreate()" [disabled]="topicForm.invalid || isSubmittedTopic" class="btn bg-teal-600 border-none relative">
               <span class="text-white text-sm font-semibold" i18n>Save</span>
               <ng-container *ngIf="isSubmittedTopic">
                  <ng-container *ngTemplateOutlet="loaderWhit"></ng-container>
               </ng-container>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>

<ng-template #loaderWhit>
   <div class="loader">
      <svg class="text-white m-auto h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
         <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
         <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
         </path>
      </svg>
   </div>
</ng-template>