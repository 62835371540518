import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  CourseActionsV2,
  dragdropState,
  FileProcessingState,
  LoaderService,
  selectSelectedActivityIdV2,
  selectUploadsV2,
} from 'thkee-common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-DragDropInfo',
  template: `
    <!-- Upload in progress -->
    <ng-container *ngIf="{ uploads: uploads$ | async, lectureId: lectureId$ | async } as vm">
      <ng-container *ngIf="vm.uploads && vm.lectureId">
        <!-- Upload in progress -->
        <div
          *ngIf="
            vm.uploads![vm.lectureId!]?.status === 'upload-progress' ||
            vm.uploads![vm.lectureId!]?.status === 'uploading'
          "
          class="flex flex-col gap-3"
        >
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/file-up.svg"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>Uploading...</div>
            <!-- <div class="estimate" i18n>
              Estimated time remaining: <span>{minutes, plural, =1 {1 minute} other { {{ minutes }} minutes} }</span>
            </div> -->
          </div>
          <div class="progress-status flex items-center gap-3">
            <div>
              <svg-icon src="@assets/images/icons/file.svg" class="block"></svg-icon>
            </div>
            <div class="grow">
              <div class="flex text-xs font-medium">
                <div class="grow truncate">{{ vm.uploads![vm.lectureId!].fileName | truncate : 50 : '...' }}</div>
                <div class="text-slate-400">{{ vm.uploads![vm.lectureId!].progressPercent }}%</div>
              </div>
              <div class="progress-container">
                <div class="progress" [style.width.%]="vm.uploads![vm.lectureId!].progressPercent"></div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-sm btn-outline-red" (click)="emitClickEvent('abort')">
              <svg-icon src="@assets/images/icons/x-circle.svg" class="btn-icon"></svg-icon>
              <span i18n>Abort</span>
            </button>
          </div>
        </div>
        <!-- Upload Success -->
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'upload-complete'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/check-circle-solid.svg" class="text-green"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Uploaded.</div>
            <div class="estimate" i18n>File uploaded successfully done</div>
          </div>
        </div>
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'processing'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/hourglass.svg" class="text-green"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Processing.</div>
            <div class="estimate" i18n>Video is processing now</div>
          </div>
        </div>
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'failed'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/exclamation-triangle-solid.svg" class="text-red-500"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Processing Failed.</div>
            <!-- <div class="estimate" i18n>Your uploaded file processing is failed</div> -->
            <ng-container *ngIf="vm.uploads![vm.lectureId!]?.error === 'video_processing_failed'">
              <span class="mt-2 block rounded-md bg-red-100 p-3 text-xs font-normal capitalize text-red-500">
                {{ VIDEO_TRANSCODE_ERROR }}
              </span>
            </ng-container>
            <ng-container *ngIf="vm.uploads![vm.lectureId!]?.error === 'video_has_no_audio'">
              <span class="mt-2 block rounded-md bg-red-100 p-3 text-xs font-normal capitalize text-red-500">
                {{ SPEECH_TO_TEXT_ERROR }}
              </span>
            </ng-container>
            <ng-container *ngIf="vm.uploads![vm.lectureId!]?.error === 'video_has_no_proper_image'">
              <span class="mt-2 block rounded-md bg-red-100 p-3 text-xs font-normal capitalize text-red-500">
                {{ THUMBNAIL_ERROR }}
              </span>
            </ng-container>
            <ng-container *ngIf="vm.uploads![vm.lectureId!]?.error === 'video_lecture_is_not_ready'">
              <span class="mt-2 block rounded-md bg-red-100 p-3 text-xs font-normal capitalize text-red-500">
                {{ VIDEO_LECTURE_NOT_COMPLETED }}
              </span>
            </ng-container>
          </div>
          <div class="text-center">
            <button class="btn btn-sm btn-outline-red" (click)="emitClickEvent('retry')">
              <svg-icon src="@assets/images/icons/x-circle.svg" class="btn-icon"></svg-icon>
              <span i18n>Retry</span>
            </button>
          </div>
        </div>
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'resume'" class="flex flex-col gap-3">
          <div class="file-icon">
            <svg-icon src="@assets/images/icons/hourglass.svg" class="text-primary-500"></svg-icon>
          </div>
          <div class="status">
            <div class="uploading" i18n>File Processing Pending.</div>
            <span class="bg-primary-100 text-primary-500 mt-2 block rounded-md p-3 text-xs font-normal capitalize" i18n>
              Processing is pending and will begin shortly. Click 'Resume' to speed it up.
            </span>
          </div>
          <div class="text-center">
            <button class="btn btn-sm btn-outline-primary" (click)="emitClickEvent('retry')">
              <svg-icon src="@assets/images/icons/backward.svg" class="btn-icon ltr:hidden rtl:block"></svg-icon>
              <svg-icon
                src="@assets/images/icons/backward.svg"
                class="btn-icon rotate-180 ltr:block rtl:hidden"
              ></svg-icon>
              <span i18n>Resume</span>
            </button>
          </div>
        </div>
        <!-- Uploaded Preview -->
        <div *ngIf="vm.uploads![vm.lectureId!]?.status === 'completed'" class="-m-6">
          <div class="preview relative min-h-[264px] overflow-hidden rounded-lg max-sm:min-h-28">
            <!-- <app-lib-video-player [URL]="uploadState.fileURL"></app-lib-video-player> -->
            <ng-container *ngIf="sources$">
              <app-thk-video-player
                [sources$]="sources$"
                [settingsButton]="false"
                [relatedMenu]="false"
                [qualityMenu]="false"
                [buttonRewind]="false"
                [autoplay]="true"
                [singlePlayer]="false"
                [theaterButton]="false"
                [enableHotkey]="enableHotkey"
                [poster]="''"
              >
              </app-thk-video-player>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="vm.uploads![vm.lectureId!]?.status === 'loading'"
          [thkLibProcessing]="vm.uploads![vm.lectureId!]?.status === 'loading'"
          class="flex h-[264px] w-full items-center justify-center max-sm:min-h-28"
        ></div>
      </ng-container>
    </ng-container>
  `,
})
export class FormlyFileDragDropInfoComponent implements OnInit, OnDestroy {
  @Input() field!: FormlyFieldConfig;
  @Input() fileStatus: FileProcessingState | undefined;
  @Input() sources$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input() uploadState: dragdropState = {
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  };
  @Output() clickEvent = new EventEmitter<string>();
  @Input() enableHotkey: boolean = false;
  minutes = 20;

  private subscriptions = new Subscription();
  processingStatus: string = '';
  lectureId: string = '';
  isLoading$ = this.loaderService.response('file-processing');
  processingInfo: { [key: string]: { status: string; error?: string } } = {};

  uploads$ = this.store.select(selectUploadsV2);
  lectureId$ = this.store.select(selectSelectedActivityIdV2);

  VIDEO_TRANSCODE_ERROR = $localize`Video processing failed. Please try again or contact support.`;
  SPEECH_TO_TEXT_ERROR = $localize`The video has no audio. Please upload a video with proper audio.`;
  THUMBNAIL_ERROR = $localize`Unable to generate a thumbnail. Ensure the video contains valid images.`;
  VIDEO_LECTURE_NOT_COMPLETED = $localize`Video lecture is not ready yet. Please check back later.`;

  constructor(private loaderService: LoaderService, private store: Store) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.lectureId$.subscribe((lectureId) => {
        this.lectureId = lectureId;
      })
    );
  }

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
    if (action === 'abort' || action === 'reset') {
      this.store.dispatch(CourseActionsV2.uploadStatusUpdateV2({ referenceId: this.lectureId, status: 'init' }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
