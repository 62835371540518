@for(line of buttons;track line){
   <div class="flex-1 flex w-64">
     @for(button of line;track button){
       <button
         [title]="button.title"
         class="flex-1 flex justify-center text-slate-300 text-xs bg-slate-900 px-2 py-1 hover:bg-slate-700 border border-slate-700 border-b-slate-700 hover:text-white"
         (mousedown)="mousedown($event, button.command)"
         (touchstart)="mousedown($event, button.command)"
       >
         <!-- <span class="material-symbols-outlined">{{ button.icon }}</span> -->
         <svg-icon class="btn-icon" [src]="button.icon"></svg-icon>
       </button>
     }
   </div>
 }
 