import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { selectUserId } from '@shared';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  switchMap,
  take,
} from 'rxjs';
import {
  CourseActions,
  CourseActionsV2,
  FileProcessingState,
  FileProps,
  generateUuid,
  InstructorService,
  isDefined,
  LectureDetailV2,
  LectureResourceV2,
  LectureType,
  LectureV2,
  Logger,
  PartialEntity,
  selectCourseV2,
  selectLectureResourceV2,
  selectLectureV2,
  selectSelectedActivityIdV2,
  SelectVideoLibraryItem,
  ToastService,
} from 'thkee-common';

const log = new Logger('LectureComponent');

@UntilDestroy()
@Component({
  selector: 'app-activity-lecture',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss'],
})
export class LectureComponent implements OnInit {
  @ViewChild('ngForm') ngForm!: NgForm;
  @Output() nextEvent = new EventEmitter<Partial<string>>();
  @Output() createEvent = new EventEmitter<Partial<any>>();
  @Output() saveEvent = new EventEmitter<Partial<any>>();
  @Output() cancelEvent = new EventEmitter<Partial<any>>();

  lectureType?: LectureType;
  onSelected: boolean = false;
  lectureId: string = '';

  lectureId$!: Observable<string>;
  lecture$!: Observable<PartialEntity<LectureV2> | undefined>;
  lectureResources$!: Observable<PartialEntity<LectureResourceV2>[] | undefined>;
  course$ = this.store.select(selectCourseV2);
  userId$!: Observable<number>;
  isEdit$!: Observable<boolean>;

  readonly form: FormGroup = new FormGroup({});
  readonly options: FormlyFormOptions = {};
  model: Partial<LectureDetailV2> = {
    title: '',
  };
  fields: FormlyFieldConfig[] = [];
  topBarItemsConfig = {
    undo: true,
    redo: true,
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    h5: true,
    bold: true,
    italic: true,
    strikethrough: true,
    table: true,
    divider: true,
    bulletList: true,
    orderedList: true,
    codeBlock: true,
    quoteBlock: true,
    imageUpload: true,
  };

  currentStatus$ = new BehaviorSubject<string>('');
  libraryId: string = '';
  fileStatus$ = new BehaviorSubject<FileProcessingState>({});
  fileSources$ = new BehaviorSubject<SelectVideoLibraryItem>({});
  isUploadFile = true;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
    private instructorService: InstructorService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isUploadFile = true;
    this.libraryId = generateUuid();
    // this.course$ = this.store.select(selectCourseV2);
    this.lectureId$ = this.store.select(selectSelectedActivityIdV2);
    this.lectureId$.pipe(filter(isDefined), take(1)).subscribe((lectureId) => {
      this.lectureId = lectureId;
    });
    this.lecture$ = this.lectureId$.pipe(switchMap((lectureId) => this.store.select(selectLectureV2(lectureId))));
    this.lectureResources$ = this.lectureId$.pipe(
      switchMap((lectureId) => this.store.select(selectLectureResourceV2(lectureId)))
    );
    this.userId$ = this.store.select(selectUserId);
    this.isEdit$ = this.lectureId$.pipe(map((lectureId) => !!lectureId));

    firstValueFrom(this.lecture$).then((lecture) => {
      if (!lecture?.video_info?.status) {
        this.store.dispatch(
          CourseActionsV2.uploadStatusUpdateV2({ referenceId: this.lectureId || '', status: 'init' })
        );
      }
      if (lecture?.video_info?.status === 'completed' && this.isUploadFile) {
        this.getTranscodedVideo(lecture.id);
      }
    });

    combineLatest([this.lecture$, this.lectureResources$])
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([lecture, lectureResources]) => {
        this.lectureType = lecture?.type;
        this.onSelected = !!lecture?.id;
        this.model = {
          ...this.model,
          ..._.cloneDeep(lecture),
          resources: _.cloneDeep(lectureResources),
        };
        this.initFields();
        this.cdr.detectChanges();
      });
  }

  private async initFields() {
    this.fields = [
      {
        key: 'title',
        type: 'input',
        props: {
          label: 'Title',
          placeholder: 'e.g. The basics of the Course',
          required: true,
          minLength: 6,
          maxLength: 60,
        },
        validation: {
          messages: {
            minLength: "Title can't be lower than 6 characters.",
          },
        },
      },
      {
        key: 'article',
        type: 'plain-editor',
        props: {
          label: 'Article',
          minLength: 100,
          maxLength: 1000,
          placeholder: 'Article content here...',
          minHeight: '170px',
          maxHeight: '300px',
          required: true,
          topBard: true,
          activeSlash: true,
          activeBlock: true,
          isActiveTooltip: false,
          topBarItemsConfig: this.topBarItemsConfig,
          outputFormate: 'html',
        },
        validation: {
          messages: {
            minLength: "Article can't be lower than 100 characters.",
          },
        },
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'article')),
        },
      },
      {
        key: 'video',
        type: 'file',
        props: {
          label: 'Video',
          placeholder: '.mkv, .mp4, .mpeg, .webm and others up to 2GB',
          preview: true,
          previewType: 'video',
          previewUrl: '',
          allowedTypes: [
            'video/mp4', // MP4 format
            'video/x-matroska', // MKV format
            'video/webm', // WebM format
            'video/ogg', // Ogg format
            'video/x-msvideo', // AVI format
            'video/mpeg', // MPEG format
            'video/quicktime', // MOV format
            'video/x-ms-wmv', // WMV format
            'video/x-flv', // FLV format
            'application/vnd.rn-realmedia', // RealMedia (RM) format
            'application/mxf', // MXF format
            'video/x-ms-asf', // ASF format
            'video/x-prores', // ProRes format
            'video/x-xdcam', // XDCAM format
            'video/x-dnxhd', // DNx format
            'audio/x-m4a', // M4A format
            'video/x-m4v', // M4V format
            'video/x-f4v', // F4V format
            'audio/x-f4a', // F4A format
            'audio/x-m4b', // M4B format
            'audio/x-m4r', // M4R format
            'video/x-vob', // VOB format
            'video/3gpp', // 3GP format
            'video/3gpp2', // 3GP2 format
            'video/x-h264', // H.264 format
            'video/x-hevc', // H.265/HEVC format
            'video/x-dv', // DV format
          ],
          uploadType: 'drag-drop',
          processingStatus: {},
          sources: {},
          metadata: {},
          enableHotkey: false,
          onUpload: (upload, field) => {
            log.debug('upload: ', upload);
            this.store.dispatch(
              CourseActionsV2.uploadStartV2({
                referenceId: `${this.lectureId}`,
                fileName: upload.name,
                fileType: upload.type,
                status: 'uploading',
              })
            );
          },
          onProgress: (progress, field) => {
            log.debug('progress: ', progress);
            this.currentStatus$.next('upload-progress');
            this.store.dispatch(
              CourseActionsV2.uploadProgressV2({
                referenceId: `${this.lectureId}`,
                bytesTotal: progress.bytesTotal,
                bytesUploaded: progress.bytesUploaded,
                progressPercent: progress.progressPercent,
                status: 'upload-progress',
              })
            );
          },
          onComplete: (field) => {
            log.debug('complete');
            this.currentStatus$.next('complete');
            this.store.dispatch(
              CourseActionsV2.uploadStatusUpdateV2({
                referenceId: `${this.lectureId}`,
                status: 'upload-complete',
              })
            );
          },
          onAbort: (field) => {
            log.debug('abort');
            this.store.dispatch(
              CourseActionsV2.uploadStatusUpdateV2({
                referenceId: this.lectureId,
                status: 'loading',
              })
            );
          },
        } as FileProps,
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'video')),
          'props.metadata': combineLatest([this.course$, this.lecture$, this.userId$]).pipe(
            map(([course, lecture, userId]) => {
              return {
                user: userId,
                public: false,
                content_type: 'lecture_video',
                library: this.libraryId,
                course: course.id,
                lecture: lecture?.id,
              };
            })
          ),
          'props.previewUrl': this.fileSources$.pipe(
            map((lecture) => {
              return lecture.video_url ? lecture.video_url[0]?.url : '';
            }),
            distinctUntilChanged()
          ),
          'props.sources': this.fileSources$.pipe(
            map((sources) => {
              return sources;
            }),
            distinctUntilChanged()
          ),
          'props.processingStatus': this.fileStatus$.pipe(
            map((status) => {
              return status;
            }),
            distinctUntilChanged()
          ),
        },
        hooks: {
          afterViewInit: (field: any) => {
            setTimeout(() => {
              const element = this.el.nativeElement.querySelector('.replace_video');
              if (element) {
                this.renderer.listen(element, 'click', () => {
                  field.props.componentRef.handleFileInfo('reset');
                  setTimeout(() => {
                    this.fileSources$.next({});
                    this.initFields();
                  });

                  this.store.dispatch(
                    CourseActionsV2.uploadStatusUpdateV2({ referenceId: this.lectureId, status: 'init' })
                  );
                });
              }
            }, 1000);
          },
        },
      },
      {
        fieldGroupClassName: 'grid grid-cols-2 gap-6',
        fieldGroup: [
          {
            type: 'checkbox',
            key: 'preview',
            props: {
              label: 'Available for Preview',
            },
          },
          {
            template: `<div target="video" class="replace_video text-right text-sm cursor-pointer">Replace</div>`,
          },
        ],
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit || this.lectureType !== 'video')),
        },
      },
      {
        template: `<div class="text-lg font-medium mt-3 mb-2">Downloadable Materials</div>`,
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit)),
        },
      },
      {
        key: 'resources',
        type: 'repeat',
        props: {
          addText: 'Add Resources',
          deleteConfirmation: true,
          onDelete: (field: FormlyFieldConfig, model: Partial<LectureResourceV2>) => {
            if (model?.id) {
              this.store.dispatch(CourseActions.deleteLectureResource({ lectureResourceId: model.id }));
            }
          },
        },
        expressions: {
          hide: this.isEdit$.pipe(map((isEdit) => !isEdit)),
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'file',
              key: 'file',
              defaultValue: '',
              props: {
                allowedTypes: [
                  'video/mp4',
                  'video/mkv',
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'application/pdf',
                  'application/x-pdf',
                  'application/vnd.ms-excel',
                ],
                preview: false,
                uploadType: 'default',
                metadata: {},
              } as FileProps,
              expressions: {
                'props.metadata': combineLatest([this.course$, this.lecture$, this.userId$]).pipe(
                  map(([course, lecture, userId]) => ({
                    user: userId,
                    course: course?.id,
                    lecture: lecture?.id,
                    resource: '',
                  }))
                ),
                'props.previewUrl': 'model.file',
              },
            },
          ],
        },
      },
    ];
  }

  // GET transcoded info, after file processing
  private getTranscodedVideo(lectureId: string) {
    if (lectureId) {
      this.store.dispatch(CourseActionsV2.uploadStatusUpdateV2({ referenceId: this.lectureId, status: 'loading' }));
      this.instructorService
        .getLectureTranscodedInfo(lectureId)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (res) => {
            if (res.video_url.length > 0) {
              this.store.dispatch(
                CourseActionsV2.uploadStatusUpdateV2({ referenceId: lectureId || '', status: 'completed' })
              );
              const status = { status: 'completed' };
              this.fileStatus$.next(status);
              this.fileSources$.next(res);
              this.initFields();
            }
            if (res.video_url.length === 0) {
              this.store.dispatch(
                CourseActionsV2.uploadStatusUpdateV2({ referenceId: this.lectureId, status: 'init' })
              );
            }
            this.cdr.detectChanges();
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }

  private getRollbackVideoInfo(lectureId: string) {
    this.instructorService
      .getLectureRollbackInfo(lectureId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.toastService.message({ message: $localize`Video roll back success` });
          if (res.video_url.length > 0) {
            this.store.dispatch(
              CourseActionsV2.uploadStatusUpdateV2({ referenceId: lectureId || '', status: 'completed' })
            );
            const status = { status: 'completed' };
            this.store.dispatch(
              CourseActions.uploadStatusUpdate({ referenceId: lectureId || '', status: 'completed' })
            );
            this.fileStatus$.next(status);
            this.fileSources$.next(res);
            this.initFields();
          }
        },
        error: (error) => {
          error?.error?.errors.forEach((err: any) => {
            this.toastService.error({ message: err?.detail });
          });
        },
      });
  }

  select(select: LectureType) {
    this.lectureType = select;
  }

  next() {
    this.nextEvent.emit(this.lectureType?.toLocaleLowerCase());
    this.onSelected = true;
  }

  submit(): void {
    this.form.markAsTouched();
    (this.ngForm as any).submitted = true;
    if (this.form.valid) {
      this.lectureId$.pipe(take(1)).subscribe((lectureId) => {
        const model: Partial<LectureV2> = { ...this.model, type: this.lectureType };
        if (lectureId) {
          this.saveEvent.emit(model);
        } else {
          this.createEvent.emit(model);
        }
        this.form.markAsUntouched();
      });
    }
  }

  cancel(): void {
    this.cancelEvent.emit(this.model);
  }
}
