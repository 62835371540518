import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
@Component({
  selector: 'app-table-tooltip-button',
  template: `
    <button
      class="inline-flex items-center justify-center rounded bg-slate-900 px-4 py-2 text-base font-medium leading-6 text-slate-300 shadow-sm hover:bg-slate-700 hover:text-white"
      (click)="clickButton.emit($event)"
    >
      <svg-icon class="btn-icon" [style]="iconStyle" [src]="icon"></svg-icon>
    </button>
  `,
  styles: [],
  imports: [AngularSvgIconModule],
  standalone: true,
})
export class TableButtonComponent {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();
  @Input() iconStyle: { [key: string]: any | undefined } = {};
}
