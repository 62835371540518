import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import {
  CourseV2,
  PartialEntity,
  selectCourse,
  selectCourseHistory,
  selectCoursesVersionHistory,
  Status,
  UtilsService,
} from 'thkee-common';

@Component({
  selector: 'app-common-history-course',
  templateUrl: './history-course.component.html',
  styleUrls: ['./history-course.component.scss'],
})
export class HistoryCourseComponent implements OnInit {
  @Input() maxHeight: string = '310px';

  isNewHistory: boolean = true;

  course$!: Observable<PartialEntity<CourseV2>>;
  courseHistory$!: Observable<PartialEntity<CourseV2>[]>;

  versionHistory$ = this.store.select(selectCoursesVersionHistory);
  filterVersion: string = '';

  constructor(private store: Store, private utilsService: UtilsService) {}

  async ngOnInit() {
    this.course$ = this.store.select(selectCourse);
    this.courseHistory$ = this.store.select(selectCourseHistory);
    await firstValueFrom(this.course$).then((course) => {
      // this.store.dispatch(CourseActions.loadHistory({ projectId: course.project ?? '' }));
    });
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      const colorMatch: Record<Status, string> = {
        draft: 'slate',
        published: 'green',
        in_review: 'amber',
        rejected: 'red',
        unlisted: 'red',
        pending: 'yellow',
        ended: 'gray',
        refund: 'yellow',
        completed: 'green',
        failed: 'red',
        voided: 'yellow',
      };
      return colorMatch[status];
    }
    return 'green';
  }

  formateStatusTag(status: string | undefined): string {
    if (!status) {
      throw new Error('Status is required');
    }
    const formattedStatus = status
      .toLowerCase()
      .split(/[_-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedStatus;
  }
}
