<div class="course-overview p-8">
   <div class="flex flex-col gap-4 mb-4 relative min-h-[100px]">
      <div class=" flex justify-between">
         <h3 class=" text-xl font-semibold text-neutral-800">{{payoutInstructorProfileDetails?.fullname}}’s payout Details</h3>
         <div class="flex items-center gap-2">
            <h3 class=" font-bold text-slate-700 text-lg">Verification Status</h3>
            <div class="flex flex-row items-center gap-1">
               <span *ngIf="payoutInstructorProfileDetails?.profile_verification_status === 'under_review'" class="border border-amber-200 bg-amber-50 rounded-3xl text-amber-700 text-sm px-3" i18n>Under Review</span>
               <span *ngIf="payoutInstructorProfileDetails?.profile_verification_status === 'approved'" class="border border-green-200 bg-green-50 rounded-3xl text-green-700 text-sm px-3" i18n>Approved</span>
               <span *ngIf="payoutInstructorProfileDetails?.profile_verification_status === 'rejected'" class="border border-red-200 bg-red-50 rounded-3xl text-red-700 text-sm px-3" i18n>Rejected</span>
            </div>
            <button (click)="disablePayout()" class="btn btn-red">
               <svg-icon src="@assets/images/icons/lock.svg" class="input-icon"></svg-icon>
               <span>Disable Payout  </span>
            </button>
         </div>
       </div>
       <div class="border bg-white border-slate-200 rounded-lg">
         <div class="tab-menu">
            <ul class="items flex gap-[10px] px-6">
               <li *ngFor="let tab of tabs" (click)="selectTab(tab.value)" [ngClass]="tab.value === selectedTab ? 'active':''" class="item text-slate-500 px-3 cursor-pointer">
                  {{tab.label}}
               </li>
            </ul>
          </div>
          <ng-container *ngIf="selectedTab === 'profile'">
            <ng-container *ngIf="isLoading; else loadedInfo">
               <ng-container *ngTemplateOutlet="loader"></ng-container>
            </ng-container>
            <ng-template #loadedInfo>
               <ng-container *ngTemplateOutlet="profile"></ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="selectedTab === 'identity'">
            <ng-container *ngTemplateOutlet="identity"></ng-container>
          </ng-container>
          <ng-container *ngIf="selectedTab === 'terms'">
            <ng-container *ngTemplateOutlet="terms"></ng-container>
          </ng-container>
          <ng-container *ngIf="selectedTab === 'marketing'">
            <ng-container *ngTemplateOutlet="marketing"></ng-container>
          </ng-container>
          <ng-container *ngIf="selectedTab === 'payout'">
            <ng-container *ngTemplateOutlet="payout"></ng-container>
          </ng-container>
       </div>
   </div>
</div>



<!-- Profile -->
<ng-template #profile>
   <div class="w-full p-6 flex flex-col gap-6">
      <h3 class=" font-bold text-slate-700 text-lg">Profile Information</h3>
      <table class="w-full text-left text-neutral-400 text-sm font-normal">
         <tbody>
            <tr class="text-left">
               <td class="w-56">First Name</td>
               <td>{{payoutInstructorProfileDetails?.first_name}}</td>
            </tr>
            <tr class="text-left">
               <td class="w-56">Last Name</td>
               <td>{{payoutInstructorProfileDetails?.last_name}}</td>
            </tr>
            <tr class="text-left">
               <td class="w-56">Headline</td>
               <td>{{payoutInstructorProfileDetails?.headline}}</td>
            </tr>
            <tr class="text-left">
               <td class="w-56">Language</td>
               <td>{{payoutInstructorProfileDetails?.language}}</td>
            </tr>
            <tr class="text-left">
               <td class="w-56">Biography</td>
               <td>{{payoutInstructorProfileDetails?.bio}}</td>
            </tr>
         </tbody>
      </table>
   </div>
</ng-template>
<!-- Profile -->
<ng-template #identity>
   <div class="w-full p-6 flex flex-col gap-6">
      <h3 class="font-bold text-slate-700 text-lg">Identification Information</h3>
      <table class="w-full text-left text-neutral-400 text-sm font-normal border-spacing-4">
         <tbody>
            <tr class="text-left">
               <td class="w-56 py-3">Phone Number:</td>
               <td class="text-slate-700  py-3">{{payoutInstructorProfileDetails?.mobile}}</td>
            </tr>
            <tr class="text-left">
               <td class="w-56 py-3">Identity Verification with:</td>
               <td class="text-slate-700 py-3">
                  <div class="flex gap-2">
                     <ng-container *ngIf="payoutInstructorProfileDetails?.national_id">
                        <span> National Id Card (NID)</span>
                     </ng-container>
                     <ng-container *ngIf="payoutInstructorProfileDetails?.passport">
                        <span>/</span>
                        <span>Passport</span> 
                     </ng-container>
                  </div>
               </td>
            </tr>
            <tr class="text-left">
               <td class="w-56 align-top py-3">Image:</td>
               <td class="text-slate-700 py-3">
                  <div class="flex gap-2">
                     <ng-container *ngIf="payoutInstructorProfileDetails?.national_id">
                        <div class="flex flex-col items-start gap-2">
                           <span class="font-semibold">NID</span>
                           <a [href]="payoutInstructorProfileDetails?.national_id" target="_blank">
                              <img [src]="payoutInstructorProfileDetails?.national_id" class="w-100 max-w-[220px]" alt="">
                           </a>
                        </div>
                     </ng-container>
                     <ng-container *ngIf="payoutInstructorProfileDetails?.passport">
                        <div class="flex flex-col items-start gap-2">
                           <span class="font-semibold">Passport</span>
                           <a [href]="payoutInstructorProfileDetails?.passport" target="_blank">
                              <img [src]="payoutInstructorProfileDetails?.passport" class="w-100 max-w-[220px]" alt="">
                           </a>
                        </div>
                     </ng-container>
                  </div>
               </td>
            </tr>
            <tr class="text-left">
               <td class="w-56 align-top py-3">Business Licence:</td>
               <td class="py-3">
                  <a [href]="payoutInstructorProfileDetails?.business_licence" target="_blank">
                     <img [src]="payoutInstructorProfileDetails?.business_licence" class="w-100 max-w-[220px]" alt="">
                  </a>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</ng-template>
<!-- Profile -->
<ng-template #terms>
   <div class="w-full p-6 flex flex-col gap-6">
      <h3 class="font-bold text-slate-700 text-lg">Terms Of Use</h3>
      <div class="border border-slate-400 w-full h-[400px] overflow-y-auto mb-3">
         <iframe src="https://thkee.com/terms-of-use" class="w-full h-full" title="Thkee Terms"></iframe>
      </div>
      <div class="input-check">
         <input [checked]="payoutInstructorProfileDetails.thkee_terms_of_use" disabled type="checkbox" class="input-check ng-valid ng-dirty ng-touched" id="formly_18_checkbox_otherToo_5">
         <label class="form-check-label ar:ml-0 ar:mr-1" for="formly_18_checkbox_otherToo_5">
            I have read and agree to the Thkee Terms of Use.
         </label>
       </div>
   </div>
</ng-template>
<!-- Profile -->
<ng-template #marketing>
   <div class="w-full p-6 flex flex-col gap-6">
      <h3 class=" font-bold text-slate-700 text-lg">Marketing</h3>
      <div class="w-full h-full max-h-80 overflow-y-auto">
         <h3 class="text-slate-800 text-lg font-semibold mb-5">{{title1}}</h3>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content11}}</p>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content12}}</p>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content13}}</p>
         <h3 class="text-slate-800 text-lg font-semibold mb-5">{{title2}}</h3>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content21}}</p>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content22}}</p>
         <p class="text-slate-700 text-sm font-normal mb-5">{{content23}}</p>
      </div>
      <div class="input-check">
         <input [checked]="payoutInstructorProfileDetails.thkee_terms_of_use" disabled type="checkbox" class="input-check ng-valid ng-dirty ng-touched" id="formly_18_checkbox_otherToo_5">
         <label class="form-check-label ar:ml-0 ar:mr-1" for="formly_18_checkbox_otherToo_5">
            I have read and agree to the Promotions Policy terms for the Thkee Deals Program.
         </label>
       </div>
   </div>
</ng-template>
<!-- Profile -->
<ng-template #payout>
   <div class="grid grid-cols-12 gap-4 p-4">
      <div class="p-6 flex flex-col gap-6 col-span-6 border border-slate-200 rounded-lg">
         <h3 class=" font-bold text-slate-700 text-lg">Account Information</h3>
         <ng-container *ngIf="bankMethod.length > 0">
            <div class="flex flex-row w-full justify-start items-center mb-3">
               <svg-icon src="@assets/images/icons/bank-icon-color.svg" class="mr-3"></svg-icon>
               <h3 class=" text-slate-800 font-semibold text-base" i18n>Direct to Bank</h3>
            </div>
            <div class="flex flex-col items-start gap-5 w-full">
               <div *ngFor="let account of bankMethod" class="flex flex-row w-full justify-between">
                  <div class="flex items-center flex-row gap-2">
                     <h3 (click)="showDetails(account)" class="text-slate-600 font-normal text-sm cursor-pointer hover:underline" i18n>Bank account ending in {{account.details?.account_number}}</h3>
                     <span *ngIf="account.is_active" class="text-neutral-400 font-normal text-sm" i18n>(Activated)</span>
                  </div>
                  <div class="flex flex-row items-center gap-1">
                     <span *ngIf="account?.bank_verification_status === 'under_review'" class="border border-amber-200 bg-amber-50 rounded-3xl text-amber-700 text-sm px-3" i18n>Under Review</span>
                     <span *ngIf="account?.bank_verification_status === 'approved'" class="border border-green-200 bg-green-50 rounded-3xl text-green-700 text-sm px-3" i18n>Approved</span>
                     <span *ngIf="account?.bank_verification_status === 'rejected'" class="border border-red-200 bg-red-50 rounded-3xl text-red-700 text-sm px-3" i18n>Rejected</span>
                  </div>
               </div>
            </div>
         </ng-container>
         <!-- Paypal -->
         <div *ngIf="paypalMethod" class="flex flex-row w-full justify-between">
            <div class="flex items-center flex-row gap-2">
               <div class="flex items-center gap-4">
                  <svg-icon src="@assets/images/icons/paypal-icon.svg" class=""></svg-icon>
                  <span class="font-semibold text-lg text-slate-700" i18n>Paypal</span>
               </div>
               <ng-container *ngIf="paypalMethod.is_connected">
                  <span *ngIf="paypalMethod.is_active" class="text-neutral-400 font-normal text-sm" i18n>(Activated)</span>
               </ng-container>
            </div>
            <div class="flex items-center flex-row gap-3">
               <ng-container *ngIf="paypalMethod.is_connected; else notConnected">
                  <span class="text-slate-400 text-xs">{{paypalMethod.details?.email}}</span>
               </ng-container>
               <ng-template #notConnected>
                  <span class=" text-slate-400 text-xs" i18n>Not Connected</span>
               </ng-template>
            </div>
         </div>
         <!-- Pioneer -->
         <div *ngIf="payoneerMethod" class="flex flex-row w-full justify-between">
            <div class="flex items-center flex-row gap-2">
               <div class="flex items-center gap-4">
                  <svg-icon src="@assets/images/icons/payonear-icon.svg" class=""></svg-icon>
                  <span class="font-semibold text-lg text-slate-700" i18n>Payoneer</span>
               </div>
               <ng-container *ngIf="payoneerMethod.is_connected">
                  <span *ngIf="payoneerMethod.is_active" class="text-neutral-400 font-normal text-sm" i18n>(Activated)</span>
               </ng-container>
            </div>
            <div class="flex items-center flex-row gap-3">
               <ng-container *ngIf="payoneerMethod.is_connected; else notConnected">
                  <span class="badge badge-solid-green" i18n>Connected</span>
               </ng-container>
               <ng-template #notConnected>
                  <span class=" text-slate-400 text-xs" i18n>Not Connected</span>
               </ng-template>
            </div>
         </div>
      </div>
      <div class="p-6 col-span-6 border border-slate-200 rounded-lg">
         <h3 class="font-bold text-slate-700 text-lg mb-6">History</h3>
         <div class="relative">
            <div class="absolute top-0 left-0 bg-white bg-opacity-5 w-full h-full flex items-center justify-center z-50" [thkLibProcessing]="isLoadingHistory"></div>
            <ng-container *ngTemplateOutlet="history"></ng-container>
         </div>
            <app-common-pagination 
               (paginatorData)="paginationAction($event)" 
               (pageSize)="pageSizeAction($event)" 
               [config]="paginationConfig" 
               [type]="'dynamic'">
            </app-common-pagination>
      </div>
   </div>

   <div class="flex flex-row items-center justify-end mt-6">
      <button *ngIf="!payoutInstructorProfileDetails?.verified" (click)="verifyInstructor()" class="btn btn-primary">
         <svg-icon src="@assets/images/icons/verify.svg" class="input-icon"></svg-icon>
         <span>Verify Instructor</span>
      </button>
      <button *ngIf="payoutInstructorProfileDetails?.verified" (click)="revokeVerification()" class="btn btn-amber">
         <svg-icon src="@assets/images/icons/revok.svg" class="input-icon"></svg-icon>
         <span>Revoke Verification</span>
      </button>
   </div>
</ng-template>

<ng-template #history>
   <div *ngFor="let history of payoutHistory" class="pb-4 ps-6 ms-2 border-l border-dashed border-l-neutral-300 relative">
      <span class="absolute w-2 h-2 rounded-full bg-neutral-300 left-[-4px] top-0"></span>
      <span class="absolute w-2 h-2 rounded-full bg-neutral-300 left-[-4px] bottom-[-8px]"></span>
      <p *ngIf="history.payment_method === 'bank_account'" class="text-sm text-neutral-900 font-normal mb-1">Bank account {{history?.reason}}</p>
      <p *ngIf="history.payment_method === 'paypal'" class="text-sm text-neutral-900 font-normal mb-1">PayPal account {{history?.reason}}</p>
      <p *ngIf="history.payment_method === 'payoneer'" class="text-sm text-neutral-900 font-normal mb-1">Payoneer account {{history?.reason}}</p>
      
      <div class=" flex flex-row gap-2 items-center">
         <p class="text-xs text-neutral-700 font-normal mb-0">
            {{ history.date | date:'MMMM d, y' }}
         </p>
         <span class="w-1 h-1 rounded-full bg-neutral-400"></span>
         <p class="text-xs text-neutral-700 font-normal mb-0">
            {{ history.date | date:'h:mm a' }}
         </p>
      </div>
   </div>
</ng-template>


<!-- Product drawer -->
<app-drawer #accountDetails customClass="max-w-[700px] w-full">
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">{{payoutInstructorProfileDetails?.fullname}}'s Payout Details</h3>
         <button (click)="accountDetails.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
            </svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <!-- Summery -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 *ngIf="bankAccount?.bank_verification_status === 'under_review'" class="text-xl text-black font-semibold">Do you want to approve this account?</h3>
         <h3 *ngIf="bankAccount?.bank_verification_status === 'approved'" class="text-xl text-black font-semibold">Are you sure you want to reject this account?</h3>
         <div *ngIf="bankAccount" class="flex justify-between items-center gap-3">
            <button (click)="approved(bankAccount.id)" *ngIf="bankAccount?.bank_verification_status === 'under_review' && bankAccount?.bank_verification_status !== 'approved'" class="btn btn-primary">Approve</button>
            <button (click)="rejected(bankAccount.id)" *ngIf="bankAccount?.bank_verification_status === 'under_review' || bankAccount?.bank_verification_status === 'approved'" class="btn btn-outline-red">Reject</button>
         </div>
      </div>
      <div class="flex flex-col p-6 border-b border-b-neutral-100">
         <div class="w-full mb-6 flex flex-col gap-4">
            <h3 class=" font-bold text-slate-600 text-base">Account Information</h3>
            <table class="w-full text-left text-sm font-normal">
               <tbody>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Swift Code</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.swift_code}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Account Number (IBAN)</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.account_number}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Name on account</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.beneficiary_name}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Post code</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.post_code}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="w-full mb-6 flex flex-col gap-4">
            <h3 class=" font-bold text-slate-600 text-base">Personal Information</h3>
            <table class="w-full text-left text-sm font-normal">
               <tbody>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">First Name</td>
                     <td class="text-neutral-700">{{payoutInstructorProfileDetails?.first_name}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Last Name</td>
                     <td class="text-neutral-700">{{payoutInstructorProfileDetails?.last_name}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Birth Date</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.birth_date | date:'MMMM d, y'}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Phone Number</td>
                     <td class="text-neutral-700">{{payoutInstructorProfileDetails?.mobile}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="w-full mb-6 flex flex-col gap-4">
            <h3 class=" font-bold text-slate-600 text-base">Branch Information(Optional)</h3>
            <table class="w-full text-left text-sm font-normal">
               <tbody>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Branch Name</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.branch_name}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Branch Address</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.branch_address}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="w-full mb-6 flex flex-col gap-4">
            <h3 class=" font-bold text-slate-600 text-base">Customer Identification</h3>
            <table class="w-full text-left text-sm font-normal">
               <tbody>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Customer ID Type</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.customer_id_type}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Customer ID</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.customer_id}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="w-full mb-6 flex flex-col gap-4">
            <h3 class=" font-bold text-slate-600 text-base">Address Information</h3>
            <table class="w-full text-left text-sm font-normal">
               <tbody>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Address</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.address}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">City and State/Province</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.city}}</td>
                  </tr>
                  <tr class="text-left">
                     <td class="w-44 text-neutral-400">Country</td>
                     <td class="text-neutral-700">{{bankAccount?.details?.country}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </ng-container>
</app-drawer>


<!-- Sub Category modal -->
<common-modal #rejectConfirmedModal customClass="sm:w-full sm:max-w-[520px]">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl" i18n>Reject Payout Method</h1>
         </div>
         <button (click)="rejectConfirmedModal.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="px-6 py-5 border-b border-b-neutral-100">
         <h1 class="text-black font-semibold text-base" i18n>Why This method rejected?</h1>
         <p class="text-slate-500 font-semibold text-sm">Example: Mismatch in account details, unable to verify bank information.</p>
         <textarea [(ngModel)]="rejectedReason" class=" w-full h-28 border border-neutral-200 rounded p-3"></textarea>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="rejectConfirmedModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold" i18n>Cancel</span>
            </button>
            <button (click)="rejectedConfirmed()" [disabled]="rejectedReason.length < 5" class="btn btn-red">
               <span class="text-white text-sm font-semibold" i18n>Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 my-5 bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>