import { NgClass } from '@angular/common';
import { Component, HostBinding, HostListener, Input, OnInit, Optional } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipDirective } from '../../directives';

@Component({
  selector: 'thk-copier',
  templateUrl: './copier.component.html',
  styleUrls: ['./copier.component.css'],
  standalone: true,
  imports: [AngularSvgIconModule, NgClass],
})
export class CopierComponent implements OnInit {
  @Input() content: string = '';
  @HostBinding('class')
  @Input()
  class?: string;
  @Input() copiedMessage?: string;
  @Input() iconSizeClass?: string;

  @HostListener('click')
  copy() {
    navigator.clipboard.writeText(this.content);
    this.notifyCopied();
  }

  private cachedOriginalTooltipMessage?: string;

  constructor(@Optional() private tooltip: TooltipDirective) {}

  ngOnInit(): void {}

  private notifyCopied() {
    if (!this.tooltip || !this.copiedMessage) {
      return;
    }

    if (this.cachedOriginalTooltipMessage) {
      return;
    }

    this.cachedOriginalTooltipMessage = this.tooltip.tooltip || '';
    this.tooltip.tooltip = this.copiedMessage;
    this.updateTooltipState();

    setTimeout(() => {
      if (this.tooltip) {
        this.tooltip.tooltip = this.cachedOriginalTooltipMessage;
        this.cachedOriginalTooltipMessage = '';
        this.updateTooltipState();
      }
    }, 1000);
  }

  private updateTooltipState() {
    if (this.tooltip.showing) {
      this.tooltip.hide();
      this.tooltip.show();
    }
  }
}
