import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class formatDatePipe implements PipeTransform {
  transform(dateString?: string, ...args: unknown[]): string {
    // { month: string; year: number; output: string }  - old
    const format = args[0] ? String(args[0]) : 'MMMM';
    dateString = dateString ? dateString : '';
    const dateMoment = moment.utc(dateString);
    const month = dateMoment.format(format); // August (the month number, i.e., 7 for August, as it is zero-indexed)
    const year = dateMoment.year(); // 2023
    //let output = month + ' ' + year;
    return dateMoment.format(format);
    //return { month: month, year, output }; - old return
  }
}
