import { AfterViewInit, Component } from '@angular/core';
import { commandsCtx, editorViewCtx } from '@milkdown/core';
import { tooltipFactory, TooltipProvider, TooltipProviderOptions } from '@milkdown/plugin-tooltip';
import {
  addColAfterCommand,
  addColBeforeCommand,
  addRowAfterCommand,
  addRowBeforeCommand,
  deleteSelectedCellsCommand,
  setAlignCommand,
} from '@milkdown/preset-gfm';
import { CellSelection } from '@milkdown/prose/tables';
import { $ctx } from '@milkdown/utils';
import { NgMilkdownTooltip } from 'ng-milkdown';
import { TableButtonComponent } from './table-tooltip-button.component';

export const tableTooltipCtx = $ctx<TooltipProvider | null, 'tableTooltip'>(null, 'tableTooltip');

interface ExtendedTooltipProviderOptions extends TooltipProviderOptions {
  tippyOptions?: {
    zIndex: number;
    appendTo: HTMLElement;
  };
}

export const tableTooltip = tooltipFactory('TABLE');

@Component({
  selector: 'app-table-tooltip',
  template: `
    <div class="flex overflow-hidden rounded-[4px]">
      @for (button of buttons;track $index) { @if (button.iif()) {
      <app-table-tooltip-button
        [icon]="button.icon"
        [title]="button.title"
        (clickButton)="clickButton($index)"
        [iconStyle]="button.style ?? {}"
      />
      } }
    </div>
  `,
  styleUrl: './table-tooltip.scss',
  imports: [TableButtonComponent],
  standalone: true,
})
export class TableTooltipComponent extends NgMilkdownTooltip implements AfterViewInit {
  get isRow() {
    return this.state.selection instanceof CellSelection && this.state.selection.isRowSelection();
  }

  get isCol() {
    return this.state.selection instanceof CellSelection && this.state.selection.isColSelection();
  }

  get isWholeTable() {
    return this.isRow && this.isCol;
  }

  get isAny() {
    return this.isRow || this.isCol;
  }

  get isHeading() {
    return (
      this.isRow &&
      this.state.doc.nodeAt((this.state.selection as CellSelection).$headCell.pos)?.type.name === 'table_header'
    );
  }

  buttons = [
    {
      icon: '@assets/images/icons/editors/splitscreen_add.svg',
      title: 'Add row before',
      slice: addRowBeforeCommand.key,
      iif: () => !this.isWholeTable && !this.isHeading && this.isRow,
      hide: true,
      style: {
        transform: 'scaleY(-1)',
      },
    },
    {
      icon: '@assets/images/icons/editors/splitscreen_vertical_add.svg',
      title: 'Add column before',
      slice: addColBeforeCommand.key,
      iif: () => !this.isWholeTable && this.isCol,
      hide: true,
      style: {
        transform: 'scaleX(-1)',
      },
    },
    {
      icon: '@assets/images/icons/editors/delete.svg',
      title: 'Delete selected cells',
      slice: deleteSelectedCellsCommand.key,
      iif: () => this.isWholeTable || (!this.isHeading && this.isAny),
      hide: true,
    },
    {
      icon: '@assets/images/icons/editors/splitscreen_add.svg',
      title: 'Add row after',
      slice: addRowAfterCommand.key,
      iif: () => !this.isWholeTable && this.isRow,
      hide: true,
    },
    {
      icon: '@assets/images/icons/editors/splitscreen_vertical_add.svg',
      title: 'Add column after',
      slice: addColAfterCommand.key,
      iif: () => !this.isWholeTable && this.isCol,
      hide: true,
    },
    {
      icon: '@assets/images/icons/editors/format_align_left.svg',
      title: 'Align left',
      slice: setAlignCommand.key,
      payload: 'left',
      iif: () => !this.isWholeTable && this.isCol,
      hide: false,
    },
    {
      icon: '@assets/images/icons/editors/format_align_center.svg',
      title: 'Align center',
      slice: setAlignCommand.key,
      payload: 'center',
      iif: () => !this.isWholeTable && this.isCol,
      hide: false,
    },
    {
      icon: '@assets/images/icons/editors/format_align_right.svg',
      title: 'Align right',
      slice: setAlignCommand.key,
      payload: 'right',
      iif: () => !this.isWholeTable && this.isCol,
      hide: false,
    },
  ];

  clickButton(index: number) {
    const { slice, payload, hide } = this.buttons[index];
    this.tooltipProvider?.hide();
    this.action((ctx) => {
      ctx.get(commandsCtx).call(slice, payload);
    });
    if (hide) {
      this.tooltipProvider?.hide();
    }
    this.action((ctx) => {
      ctx.get(editorViewCtx).focus();
    });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.action((ctx) => {
      ctx.set(tableTooltipCtx.key, this.tooltipProvider);
    });
  }

  override get pluginView() {
    return new TooltipProvider({
      content: this.container,
      tippyOptions: {
        zIndex: 1000,
        appendTo: document.body,
      },
      shouldShow: () => false,
      debounce: 50,
    } as ExtendedTooltipProviderOptions) as any;
  }
}
