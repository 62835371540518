import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup, NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _ from 'lodash';
import { filter, Observable, switchMap, take } from 'rxjs';
import {
  CourseActions,
  InstructorService,
  Logger,
  PartialEntity,
  QuizAnswerV2,
  QuizDetailV3,
  QuizQuestionDetailV3,
  QuizV3,
  selectQuizQuestionDetailsByQuizIdV2,
  selectQuizV2,
  selectSelectedActivityIdV2,
} from 'thkee-common';

const log = new Logger('QuizComponent');

@UntilDestroy()
@Component({
  selector: 'app-activity-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @ViewChild('formQuiz') formQuiz!: NgForm;
  @ViewChild('fformQA') fformQA!: NgForm;
  @Output() nextEvent = new EventEmitter<Partial<string>>();
  @Output() quizCreateEvent = new EventEmitter<Partial<any>>();
  @Output() quizSaveEvent = new EventEmitter<Partial<any>>();
  @Output() qACreateEvent = new EventEmitter<Partial<any>>();
  @Output() qASaveEvent = new EventEmitter<Partial<any>>();
  @Output() qADeleteEvent = new EventEmitter<Partial<{ questionId: string; subsectionId: string }>>();
  @Output() cancelEvent = new EventEmitter<Partial<any>>();

  quizId$!: Observable<string>;
  quiz$!: Observable<PartialEntity<QuizDetailV3> | undefined>;

  qaData: QuizQuestionDetailV3[] = [];
  isEditQuiz: boolean = false;
  isQA: boolean = false;
  quizes: any[] = [];

  qaID: string = '';
  sectionID: string = '';
  relatedLectureOption: { label: string; value: string | number }[] = [];

  // Quiz
  readonly form: FormGroup = new FormGroup({});
  readonly options: FormlyFormOptions = {};
  model: Partial<QuizV3> = {
    title: '',
  };
  readonly fields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Title',
        placeholder: 'e.g. The basics of the Course',
        required: true,
        minLength: 6,
        maxLength: 60,
        hideRequiredMarker: true,
      },
      validation: {
        messages: {
          minLength: "Title can't be lower than 6 characters.",
        },
      },
    },
  ];

  // Q & A
  readonly formQA: FormGroup = new FormGroup({});
  readonly optionsQA: FormlyFormOptions = {};
  modelQA: Partial<any> = {
    question: '',
    answers: [{ is_correct: true }, {}],
  };
  fieldsQA: FormlyFieldConfig[] = [];

  constructor(private store: Store, private instructorService: InstructorService) {}

  ngOnInit(): void {
    this.quizId$ = this.store.select(selectSelectedActivityIdV2);
    this.quiz$ = this.quizId$.pipe(switchMap((quizId) => this.store.select(selectQuizV2(quizId))));

    this.quiz$.pipe(untilDestroyed(this)).subscribe((quiz) => {
      this.isEditQuiz = !!quiz?.id;
      this.model.title = quiz?.title ?? '';
      this.model.subsectionId = quiz?.subsectionId ?? '';
      this.sectionID = quiz?.section || '';
      this.getRelatedSection(this.sectionID);
    });

    this.quizId$
      .pipe(
        filter((quizId) => !!quizId),
        take(1)
      )
      .subscribe((quizId) => {
        this.store
          .select(selectQuizQuestionDetailsByQuizIdV2(quizId))
          .pipe(untilDestroyed(this))
          .subscribe((data) => {
            this.qaData = data;
            log.debug('qaData', this.qaData);
            log.debug('quizId', quizId);
          });
      });

    this.initFieldsQA();
  }

  initFieldsQA() {
    this.fieldsQA = [
      {
        key: 'lecture',
        type: 'select',
        props: {
          label: 'Related Lecture',
          placeholder: '',
          required: false,
          options: this.relatedLectureOption,
          stylish: true,
          hideRequiredMarker: true,
        },
      },
      {
        key: 'question',
        type: 'input',
        defaultValue: '',
        props: {
          label: 'Question',
          placeholder: '',
          required: true,
          minLength: 6,
          maxLength: 60,
          hideRequiredMarker: true,
        },
        validation: {
          messages: {
            minLength: "Question can't be lower than 6 characters.",
          },
        },
      },
      {
        template: `<div class="text-sm font-medium">Answers</div>`,
      },
      {
        template: `<div class="text-xs mb-3">Click the choice you want as the correct answer</div>`,
      },
      {
        key: 'answers',
        type: 'repeat',
        props: {
          addText: 'Add Answer',
          min: 2,
          max: 5,
          deleteConfirmation: true,
          onDelete: (field: FormlyFieldConfig, model: Partial<QuizAnswerV2>) => {
            if (model.id) {
              this.store.dispatch(CourseActions.deleteQuizAnswer({ quizAnswerId: model.id }));
            }
          },
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'input',
              key: 'answer',
              props: {
                label: 'Answer',
                required: true,
                hideRequiredMarker: true,
                placeholder: 'Your Answer',
                wrapClass: 'mb-2 custom-class',
                wrapGroupClass: '!static',
                inputAppendClass: ['border-0 p-0 disabled-input-outline'],
                addonRight: 'O',
                addonRightOnClick: (field: FormlyFieldConfig) => {
                  let QAs = this.formQA?.get('answers') as FormArray<FormGroup>;
                  QAs?.controls?.forEach((rcontrol) => {
                    rcontrol?.controls?.['is_correct'].setValue(false);
                  });
                  let isCorrect = _.find(field.parent?.fieldGroup, { key: 'is_correct' });
                  isCorrect?.formControl?.setValue(true);
                },
              },
              expressionProperties: {
                'props.addonRight': (model, formState, field) => {
                  let isCorrect = _.find(field?.parent?.fieldGroup, { key: 'is_correct' });
                  if (isCorrect) {
                    const isTrue = isCorrect.formControl?.value;
                    if (isTrue)
                      return `<div class=" flex gap-2 items-center">
                                <span class="text-xs bg-green-100 text-green-500 px-2 py-1 rounded-xl block max-sm:hidden">Correct Answer</span>
                                <span class="text-xs bg-green-100 text-green-500 px-2 py-1 rounded-xl hidden max-sm:block">Correct</span>
                                <div class="is_correct w-5 h-5 border-[7px] border-primary rounded-full cursor-pointer"></div>
                              </div>`;
                  }
                  return '<div class="is_correct w-5 h-5 border border-slate-300 rounded-full cursor-pointer"></div>';
                },
              },
            },
            {
              type: 'input',
              key: 'expected',
              defaultValue: '',
              props: {
                label: "Explain why this is or isn't the best answer... (Optional)",
                hideLabel: true,
                placeholder: "Explain why this is or isn't the best answer... (Optional)",
                wrapClass: 'mb-4',
              },
            },
            {
              type: 'checkbox',
              key: 'is_correct',
              defaultValue: false,
              className: 'hidden',
            },
          ],
        },
        expressions: {
          'templateOptions.min': (field: any) => {
            const hasCorrect = _.find(this.modelQA['answers'], { is_correct: true });
            if (!hasCorrect) {
              this.modelQA['answers'][0].is_correct = true;
            }
            return field.templateOptions.min;
          },
        },
      },
    ];
  }

  initQA() {
    if (this.qaID) {
      console.log('Init QA Data');
      if (this.qaData) {
        const selectedQA = _.find(this.qaData, { id: this.qaID });
        if (selectedQA) {
          this.modelQA['id'] = selectedQA.id;
          this.modelQA['question'] = selectedQA.question;
          this.modelQA['lecture'] = selectedQA.lecture;
          if (selectedQA.answers) {
            this.modelQA['answers'] = selectedQA.answers.map((data: any) => {
              return _.omit(data, ['date_created', 'date_updated', 'quiz_question']);
            });
          }
        }
      }
      this.isQA = true;
    }
  }

  submitQuiz(action: string = 'new'): void {
    this.formQA.markAsTouched();
    (this.formQuiz as any).submitted = true;
    if (this.formQA.valid) {
      if (action == 'new') this.quizCreateEvent.emit(this.model);
      if (action == 'save') this.quizSaveEvent.emit(this.model);
      this.formQA.markAsUntouched();
      console.log('Quiz Form Valid and Created - ', this.model);
      console.log('Quiz Fields - ', this.fields);
      // if (typeof this.options.resetModel === 'function' && this.options) {
      //   this.options.resetModel();
      // }
    }
  }

  cancel(): void {
    this.cancelEvent.emit(this.model);
  }

  cancelQA(): void {
    this.isQA = false;
    this.qaID = '';
  }

  submitQA(): void {
    this.form.markAsTouched();
    (this.fformQA as any).submitted = true;
    if (this.formQA.valid) {
      if (this.qaID) {
        this.qASaveEvent.emit({ ...this.modelQA, ...this.model });
        console.log('QA Form Valid and Saved - ', this.modelQA);
      } else {
        this.qACreateEvent.emit({ ...this.modelQA, ...this.model });
        console.log('QA Form Valid and Created - ', this.modelQA);
      }
      this.isQA = false;
      this.formQA.markAsUntouched();
      console.log('QA Fields - ', this.fieldsQA);
    } else {
      console.log('QA Form Invalid');
    }
  }

  deleteQA(questionId: string): void {
    this.qADeleteEvent.emit({ questionId: questionId, subsectionId: this.model.subsectionId });
  }

  getRelatedSection(sectionId: string) {
    if (sectionId) {
      this.instructorService.getRelatedSection(sectionId).subscribe({
        next: (res) => {
          this.relatedLectureOption = res.map((lecture) => ({
            label: _.startCase(lecture.title),
            value: lecture.id ?? 0,
          }));
          this.initFieldsQA();
        },
        error: (err) => {},
      });
    }
  }
}
