import { Injectable } from '@angular/core';
import { MemoizedSelector, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, filter, map, of } from 'rxjs';
import {
  API_COURSES_ANNOTATIONS_BY_PROJECT_GET,
  API_COURSES_ANNOTATION_UPSERT,
  Annotation,
  AnnotationDetail,
  ContentTypeModels,
  CopiedEntity,
  CourseV2,
  PartialEntity,
  SubsectionType,
} from '../../models';
import {
  annotationKey,
  selectAnnotationByKey,
  selectAnnotationHistory,
  selectAssignmentDetailByAssignmentIdV2,
  selectAssignmentQuestion,
  selectCourse,
  selectCourseV2,
  selectLearnerV2,
  selectLectureV2,
  selectObjectiveV2,
  selectQuizQuestion,
  selectQuizV2,
  selectRequirementV2,
  selectSection,
  selectSubsection,
} from '../../store';
import { linkedListToArray } from '../../utils';
import { HttpService } from '../http';
import { Logger } from '../logger';

const log = new Logger('AnnotationService');

export type AnnotationsByProjectResult = { courses: CourseV2[]; annotations: Annotation[] };

export const contentTypeModelToSelectorMap: {
  [key in ContentTypeModels]: (id: string, type?: string) => MemoizedSelector<any, any>;
} = {
  [ContentTypeModels.COURSE]: (_: string) => selectCourseV2,
  [ContentTypeModels.SECTION]: (id: string) => selectSection(id),
  [ContentTypeModels.SUBSECTION]: (id: string) => selectSubsection(id),
  [ContentTypeModels.QUIZ]: (id: string) => selectQuizV2(id),
  [ContentTypeModels.LECTURE]: (id: string) => selectLectureV2(id),
  [ContentTypeModels.ASSIGNMENT]: (id: string) => selectAssignmentDetailByAssignmentIdV2(id),
  [ContentTypeModels.ASSIGNMENT_QUESTION]: (id: string) => selectAssignmentQuestion(id),
  [ContentTypeModels.QUIZ_QUESTION]: (id: string) => selectQuizQuestion(id),
  [ContentTypeModels.OBJECTIVE]: (id: string) => selectObjectiveV2(id),
  [ContentTypeModels.LEARNER]: (id: string) => selectLearnerV2(id),
  [ContentTypeModels.REQUIREMENT]: (id: string) => selectRequirementV2(id),
  // [ContentTypeModels.QUIZ]: (id: string) => selectQuiz(id),
  // [ContentTypeModels.LECTURE]: (id: string) => selectLecture(id),
  // [ContentTypeModels.ASSIGNMENT]: (id: string) => selectAssignment(id),
  // [ContentTypeModels.OBJECTIVE]: (id: string) => selectObjective(id),
  // [ContentTypeModels.LEARNER]: (id: string) => selectLearner(id),
  // [ContentTypeModels.REQUIREMENT]: (id: string) => selectRequirement(id),
};

export const subsectionTypeToContentTypeModelMap = {
  [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
  [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
  [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
};

type GetAnnotationCountOpts = {
  prevCourseOnly: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  constructor(private readonly http: HttpService, private readonly store: Store) {}

  upsertAnnotation(annotation: PartialEntity<Annotation>): Observable<Annotation> {
    return this.http.post<Annotation>(API_COURSES_ANNOTATION_UPSERT, annotation);
  }

  getAnnotationsByProjectId(projectId: string): Observable<AnnotationsByProjectResult> {
    return this.http.get<AnnotationsByProjectResult>(API_COURSES_ANNOTATIONS_BY_PROJECT_GET, { project_id: projectId });
  }

  getAnnotation(fieldKey: string, fieldModel: ContentTypeModels, fieldId: string): Observable<Annotation> {
    const key = annotationKey({
      model: fieldModel,
      field: fieldKey,
      object_id: fieldId,
    });
    return this.store.select(selectAnnotationByKey(key));
  }

  getAnnotationCount(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<number> {
    const course$ = this.store.select(selectCourse);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter(
          (annotation) =>
            !opts.prevCourseOnly ||
            annotation.course === (typeof course.source === 'object' ? course.source?.id : course.source)
        );
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        return linkedListToArray(entity)
          .map((entity) => {
            const key = annotationKey({
              model: fieldModel,
              field: fieldKey,
              object_id: entity.id,
            });
            const annotation = annotationHistoryMap[key];
            // log.debug('annotation key', key, annotation);
            // log.debug('annotationHistory', annotationHistory);
            return !!annotation;
          })
          .filter((hasAnnotation) => hasAnnotation).length;
      }),
      distinctUntilChanged()
      // tap((count) => log.debug('annotation count', fieldModel, fieldId, fieldKey, count))
    );
  }

  // annotationChecker(
  //   fieldKey: string,
  //   fieldModel: ContentTypeModels,
  //   fieldId: string,
  //   opts: GetAnnotationCountOpts = {
  //     prevCourseOnly: false,
  //   }
  // ): Observable<object> {
  //   const course$ = this.store.select(selectCourse);
  //   const annotationHistory$ = this.store.select(selectAnnotationHistory);

  //   const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
  //     filter(([, annotationHistory]) => annotationHistory.length > 0),
  //     map(([course, annotationHistory]) =>
  //       annotationHistory.filter(
  //         (annotation) =>
  //           !opts.prevCourseOnly ||
  //           annotation.course === (typeof course.source === 'object' ? course.source?.id : course.source)
  //       )
  //     ),
  //     map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
  //   );

  //   const entity$ = this.store
  //     .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
  //     .pipe(map((entity) => entity as CopiedEntity<unknown>));

  //   return combineLatest([entity$, annotationHistoryMap$]).pipe(
  //     map(([entity, annotationHistoryMap]) => {
  //       return linkedListToArray(entity)
  //         .map((entity) => {
  //           const key = annotationKey({
  //             model: fieldModel,
  //             field: fieldKey,
  //             object_id: entity.id,
  //           });
  //           const annotation = annotationHistoryMap[key];
  //           console.log('🚀 ~ AnnotationService ~ .map ~ annotation:', annotation);
  //           return annotation;
  //         })
  //         .filter((hasAnnotation) => {
  //           console.log('🚀 ~ AnnotationService ~ .filter ~ hasAnnotation:', hasAnnotation);
  //           return hasAnnotation;
  //         });
  //     }),
  //     distinctUntilChanged()
  //   );
  // }

  annotationChecker(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<object> {
    const course$ = this.store.select(selectCourseV2);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) =>
        annotationHistory.filter((annotation) => !opts.prevCourseOnly || annotation.course === course.source)
      ),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    // Entity selector
    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];
        return [annotation];
      }),
      filter((annotation): annotation is PartialEntity<AnnotationDetail>[] => {
        return !!annotation;
      }),
      distinctUntilChanged()
    );
  }

  getAnnotationCountV2(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<number | undefined> {
    const course$ = this.store.select(selectCourseV2);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter((annotation) => !opts.prevCourseOnly || annotation.course === course.source);
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];

        if (annotation && typeof annotation === 'object' && 'annotation_count' in annotation) {
          return annotation.annotation_count;
        }

        return 0; // Return 0 if feedback_count does not exist or annotation is not an object
      }),
      map((annotation) => annotation ?? undefined),
      distinctUntilChanged()
    );
  }

  getAnnotationCountV3(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<number | undefined> {
    const course$ = this.store.select(selectCourseV2);
    // const annotationHistory$ = this.store.select(selectAnnotationHistory);
    const annotationHistory$ = this.demoData();

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter(
          (annotation: { course: string }) => !opts.prevCourseOnly || annotation.course === course.source
        );
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    // return of(2);
    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];

        if (annotation && typeof annotation === 'object' && 'annotation_count' in annotation) {
          return annotation.annotation_count;
        }

        return 0; // Return 0 if feedback_count does not exist or annotation is not an object
      }),
      map((annotation) => annotation ?? undefined),
      distinctUntilChanged()
    );
  }

  getAnnotationDate(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<string | undefined> {
    const course$ = this.store.select(selectCourseV2);
    // const annotationHistory$ = this.store.select(selectAnnotationHistory);
    const annotationHistory$ = this.demoData();

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter(
          (annotation: { course: string }) => !opts.prevCourseOnly || annotation.course === course.source
        );
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];

        if (annotation && typeof annotation === 'object' && 'date_updated' in annotation) {
          return annotation.date_updated;
        }

        return ''; // Return 0 if feedback_count does not exist or annotation is not an object
      }),
      map((annotation) => annotation ?? undefined),
      distinctUntilChanged()
    );
  }

  demoData(): Observable<any> {
    const history = [
      {
        id: 'f7e66dd9-f800-44cb-9279-01dd9f689a32',
        content_type: 'courseobjective',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'courseobjective',
        key: 'courseobjective-a7669ee2-019d-41c8-98ad-ba389f8c453e-content',
        content: {
          id: 'a7669ee2-019d-41c8-98ad-ba389f8c453e',
          type: 'courseobjective',
          details: {
            name: 'explain the Linux Filesystem hierarchy.',
          },
        },
        date_created: '2024-06-11T12:15:22.360924Z',
        date_updated: '2024-06-11T18:15:58.532454Z',
        object_id: 'a7669ee2-019d-41c8-98ad-ba389f8c453e',
        field: 'content',
        feedback: 'Please change text copy right',
        required_action: true,
        is_resolved: true,
        annotation_count: 3,
      },
      {
        id: 'ceba6cbd-8018-4d08-8756-01ccc34dd2c4',
        content_type: 'section',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'section',
        key: 'section-a0c928f2-dbfc-4196-8ecd-799ff503208f-title',
        content: {
          id: 'a0c928f2-dbfc-4196-8ecd-799ff503208f',
          type: 'section',
          details: {
            name: 'Introduction and command Line',
          },
        },
        date_created: '2024-06-11T18:14:28.105303Z',
        date_updated: '2024-06-11T18:17:14.694552Z',
        object_id: 'a0c928f2-dbfc-4196-8ecd-799ff503208f',
        field: 'title',
        feedback: 'test',
        required_action: true,
        is_resolved: true,
        annotation_count: 1,
      },
      {
        id: 'ceba6cbd-8018-4d08-8756-01ccc34dd2w1',
        content_type: 'lecture',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'lecture',
        key: 'lecture-23ec5a20-22b5-4f46-9d5a-8f0fdf6b7fab-title',
        content: {
          id: '23ec5a20-22b5-4f46-9d5a-8f0fdf6b7fab',
          type: 'lecture',
          details: {
            name: 'Introduction and command Line',
          },
        },
        date_created: '2024-06-11T18:14:28.105303Z',
        date_updated: '2025-01-05T11:26:43.214090Z',
        object_id: '23ec5a20-22b5-4f46-9d5a-8f0fdf6b7fab',
        field: 'title',
        feedback: 'test',
        required_action: true,
        is_resolved: true,
        annotation_count: 1,
      },
      {
        id: '909747d0-cf01-4b57-82fa-ac67260810f7',
        content_type: 'course',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'course',
        key: 'course-4469cbce-5f49-4d65-90a3-d204ee3f139d-image',
        content: {
          id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
          type: 'course',
          details: {
            name: 'Linux Command Line Basics',
          },
        },
        date_created: '2024-06-12T11:26:43.214073Z',
        date_updated: '2025-01-05T11:26:43.214090Z',
        object_id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        field: 'image',
        feedback: 'copy right',
        required_action: false,
        is_resolved: false,
        annotation_count: 1,
      },
      {
        id: '813496bb-24f7-4aa6-874f-4f10f021e7b2',
        content_type: 'course',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'course',
        key: 'course-4469cbce-5f49-4d65-90a3-d204ee3f139d-subcategory',
        content: {
          id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
          type: 'course',
          details: {
            name: 'Linux Command Line Basics',
          },
        },
        date_created: '2024-06-28T06:48:02.085792Z',
        date_updated: '2025-01-08T11:26:43.214090Z',
        object_id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        field: 'subcategory',
        feedback: 'test',
        required_action: false,
        is_resolved: false,
        annotation_count: 1,
      },
      {
        id: '813496bb-24f7-4aa6-874f-4f10f021e7b2',
        content_type: 'course',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'course',
        key: 'course-4469cbce-5f49-4d65-90a3-d204ee3f139d-title',
        content: {
          id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
          type: 'course',
          details: {
            name: 'Linux Command Line Basics',
          },
        },
        date_created: '2024-06-28T06:48:02.085792Z',
        date_updated: '2025-01-07T11:26:43.214090Z',
        object_id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        field: 'subcategory',
        feedback: 'test',
        required_action: false,
        is_resolved: false,
        annotation_count: 1,
      },
      {
        id: '813496bb-24f7-4aa6-874f-4f10f021e7b2',
        content_type: 'course',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'course',
        key: 'course-4469cbce-5f49-4d65-90a3-d204ee3f139d-topics',
        content: {
          id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
          type: 'course',
          details: {
            name: 'Linux Command Line Basics',
          },
        },
        date_created: '2024-06-28T06:48:02.085792Z',
        date_updated: '2025-01-07T11:26:43.214090Z',
        object_id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        field: 'subcategory',
        feedback: 'test',
        required_action: false,
        is_resolved: false,
        annotation_count: 1,
      },
      {
        id: '813496bb-24f7-4aa6-874f-4f10f021e7b2',
        content_type: 'course',
        course: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        model: 'course',
        key: 'course-4469cbce-5f49-4d65-90a3-d204ee3f139d-desc',
        content: {
          id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
          type: 'course',
          details: {
            name: 'Linux Command Line Basics',
          },
        },
        date_created: '2024-06-28T06:48:02.085792Z',
        date_updated: '2025-01-04T11:26:43.214090Z',
        object_id: '4469cbce-5f49-4d65-90a3-d204ee3f139d',
        field: 'subcategory',
        feedback: 'test',
        required_action: false,
        is_resolved: false,
        annotation_count: 1,
      },
    ];
    return of(history);
  }
}
