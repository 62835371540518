<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Tax</h3>
    </div>
    <button (click)="add()" class="btn-blue btn-sm">
      <svg-icon class="btn-icon" src="@assets/images/icons/plus.svg"></svg-icon>
      <span> Add </span>
    </button>
  </div>

  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <button (click)="switchToFixedRate()" class="btn btn-transparent">
        <span>Fixed Rate</span>
      </button>
      <button class="btn btn-transparent bg-primary-100 text-primary-600">
        <span>By Country</span>
      </button>
    </div>
  </div>
  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="Tax by Countries" badge="{{ totalTaxByCountry }} Countries">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">Countries</div>
              </th>
              <th scope="col">
                <div class="col-name">State</div>
              </th>
              <th scope="col">
                <div class="col-name">ZIP</div>
              </th>
              <th scope="col">
                <div class="col-name">Default Percentage</div>
              </th>
              <th scope="col">
                <div class="col-name">Tax Category</div>
              </th>
              <th scope="col">
                <div class="col-name">Status</div>
              </th>
              <th scope="col" class="w-28">
                <div class="col-name">Option</div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (setting of taxByCountryLists; track setting) {
            <tr class="cursor-pointer text-left hover:!bg-neutral-100">
              <td>
                <span class="course-id">{{ setting.country_name }}</span>
              </td>
              <td>
                <span class="course-id">{{ setting.state_province }}</span>
              </td>
              <td>
                <span class="course-id">{{ setting.zip_code }}</span>
              </td>
              <td>
                <span class="course-id">{{ setting.default_percentage }}</span>
              </td>
              <td (click)="previewCategory(setting)">
                <span class="course-id text-blue-600 underline">{{ setting.linked_category_count }} Categories</span>
              </td>
              <td>
                <div class="tier-status">
                  <label class="relative inline-flex cursor-pointer items-center">
                    <input type="checkbox" [(ngModel)]="setting.enabled" class="peer sr-only" />
                    <div
                      class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                    ></div>
                    <span class="ms-3 text-sm">{{ setting.enabled ? 'Active' : 'Disabled' }}</span>
                  </label>
                </div>
              </td>
              <td>
                <button (click)="update(setting)" class="btn-amber-soft btn-sm px-2">
                  <svg-icon class="btn-icon" src="@assets/images/icons/pencil.svg"></svg-icon>
                </button>
              </td>
            </tr>
            } @if(isLoading){
            <tr>
              <td colspan="7">
                <div
                  class="flex h-14 w-full items-center justify-center bg-white bg-opacity-50"
                  [thkLibProcessing]="true"
                  size="sm"
                ></div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
    <!-- Pagination -->
    <div class="relative z-30">
      <app-common-pagination (paginatorData)="paginate($event)" [type]="'dynamic'"></app-common-pagination>
    </div>
  </div>
</div>

<!-- Drawer -->
<app-drawer #drawerSidebar customClass="max-w-[600px] min-w-[560px] !sm:w-full">
  <ng-container header>
    <div class="flex items-center justify-between border-b border-b-neutral-100 bg-neutral-50 px-6 py-5">
      <h3 *ngIf="action === 'create'" class="text-xl font-semibold text-black">Add New Category</h3>
      <h3 *ngIf="action === 'update'" class="text-xl font-semibold text-black">Update Category</h3>
      <h3 *ngIf="action === 'preview'" class="text-xl font-semibold text-black">View Category</h3>
      <div class="flex items-center justify-between gap-3">
        <button (click)="drawerSidebar.close()" class="h-6 w-6 rounded-[3px] bg-neutral-100">
          <svg-icon src="@assets/images/icons/x-mark.svg" class="h-3 w-3 text-black"> </svg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container content>
    <!-- Form -->
    <div class="card mt-4 p-4">
      <div *ngIf="action === 'create' || action === 'update'" class="m-auto block max-w-xl">
        <ng-container *ngTemplateOutlet="categoryForm"></ng-container>
      </div>
      <div *ngIf="action === 'preview'" class="m-auto block max-w-xl">
        <ng-container *ngTemplateOutlet="categoryView"></ng-container>
      </div>
    </div>
  </ng-container>
</app-drawer>

<common-modal #categoryModal title="Select Category" customClass="sm:w-full sm:max-w-[300px]">
  <ng-container content>
    <ul class="w-full pb-4">
      @for (category of taxCategoryLists; track category.id; let idx = $index) {
      <li class="hover:bg-primary-100 list-item w-full cursor-pointer list-none px-4 py-2">
        <div class="input-check">
          <input
            (change)="selectCategory(category, $event)"
            class="!border-slate-700 checked:!bg-slate-700"
            id="default2-checkbox-{{ idx }}"
            [(ngModel)]="category.checked"
            type="checkbox"
          />
          <label class="flex w-full items-center gap-2" for="default2-checkbox-{{ idx }}">
            {{ category.name }}
            <span class="block h-1 w-4 rounded-lg"></span>
          </label>
        </div>
      </li>
      }
    </ul>
  </ng-container>
  <ng-container footer>
    <div class="flex flex-col items-end border-t border-neutral-100 px-3 pb-3">
      <div class="flex gap-4">
        <button (click)="categoryModal.close()" class="btn border-none bg-neutral-100">
          <span class="text-sm font-semibold text-neutral-900" i18n>Cancel</span>
        </button>
        <button (click)="applySelectedCategory()" class="btn border-none bg-teal-600">
          <span class="text-sm font-semibold text-white" i18n>Apply</span>
        </button>
      </div>
    </div>
  </ng-container>
</common-modal>
<ng-template #categoryForm>
  <form [formGroup]="taxByCountry">
    <div class="flex items-center justify-between gap-4">
      <span class="w-40">Details</span>
      <div class="flex items-center gap-4">
        <button
          (click)="createUpdate()"
          [disabled]="isSubmitted || taxByCountry.invalid"
          [thkLibProcessing]="isSubmitted"
          size="sm"
          variant="white"
          type="submit"
          class="btn-blue btn-sm"
        >
          {{ action === 'create' ? 'Create' : 'Update' }}
        </button>
        <!-- <button (click)="reset()" type="button" class="btn-blue btn-sm">Reset</button> -->
      </div>
    </div>
    <formly-form [form]="taxByCountry" [fields]="taxByCountryFields" [model]="taxByCountryModel"></formly-form>
  </form>
</ng-template>
<ng-template #categoryView>
  <div class="flex items-center justify-between gap-4">
    <span class="w-40">Details</span>
    <div class="flex items-center gap-4">
      <button (click)="update(previewCategoryDetails)" type="button" class="btn-blue btn-sm">Edit</button>
    </div>
  </div>
  <div class="mt-12 flex w-full flex-col gap-3">
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Country</div>
      <div class="w-full text-sm text-slate-600">{{ previewCategoryDetails?.country_name }}</div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">State</div>
      <div class="w-full text-sm text-slate-600">{{ previewCategoryDetails?.state_province }}</div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Zip</div>
      <div class="w-full text-sm text-slate-600">{{ previewCategoryDetails?.zip_code }}</div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Default Percentage</div>
      <div class="w-full text-sm text-slate-600">{{ previewCategoryDetails?.default_percentage }}</div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Tax Category</div>
      <div class="w-full text-sm text-slate-600"># {{ previewCategoryDetails?.id }}</div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Categories level percentage</div>
      <div class="w-full">
        <div *ngFor="let category of previewCategoryDetails?.categories" class="flex flex-row gap-5">
          <div class="text-sm text-slate-600">{{ category.category_name }}</div>
          <div class="text-sm text-slate-600">{{ category.percentage }}</div>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-row gap-3">
      <div class="w-auto min-w-56 text-sm font-semibold text-slate-800">Overall Status</div>
      <div *ngIf="previewCategoryDetails?.enabled" class="w-full text-sm font-bold text-teal-600">Active</div>
      <div *ngIf="!previewCategoryDetails?.enabled" class="w-full text-sm font-bold text-slate-600">Inactive</div>
    </div>
  </div>
</ng-template>
