import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { COURSE_TAB, TabItem } from '@shared';
import { firstValueFrom } from 'rxjs';
import { CourseActionsV2, selectCoursesVersionHistory, selectCourseV2, ToastService } from 'thkee-common';

export type HeaderActions = 'cancel' | 'update' | 'accept' | 'reject' | '';

@Component({
  selector: 'app-course-header',
  templateUrl: './course-header.component.html',
  styleUrls: ['./course-header.component.scss'],
})
export class CourseHeaderComponent implements OnInit, AfterViewInit {
  @Input() courseState: string = '0';
  @ViewChildren('buttonState', { read: TemplateRef }) buttonStateTemplates!: QueryList<TemplateRef<any>>;
  @Output() clickEvent = new EventEmitter<HeaderActions>();
  tabItems: TabItem[] = COURSE_TAB;
  buttonsArray: any[] = [];
  loaded: boolean = false;
  course$ = this.store.select(selectCourseV2);
  versionHistory$ = this.store.select(selectCoursesVersionHistory);

  constructor(private store: Store, private toastService: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.buttonsArray = this.buttonStateTemplates.toArray();
    this.loaded = true;
    this.cdr.detectChanges();
  }

  emitButton(action: HeaderActions = '') {
    this.clickEvent.emit(action);
  }

  get currentTemplate(): any {
    // return this.buttonsArray[this.courseState];
    const courseStateIndex = parseInt(this.courseState, 10); // Convert string to number
    return this.buttonsArray[courseStateIndex];
  }

  // Switch version
  // Exit History: It will take to the current version
  // View current published version: It will take to the current version
  // View latest unpublished version: This will take to the latest version
  switchVersion(type: string) {
    if (type === 'exit_history' || type === 'view_current_published_version') {
      firstValueFrom(this.versionHistory$).then(({ history }) => {
        const data = history.find((h) => h.is_current === true);
        if (data) {
          this.store.dispatch(CourseActionsV2.loadCourseV2({ courseId: data?.id || '' }));
        } else {
          this.toastService.error({
            message: 'History not founded',
          });
        }
      });
    } else if (type === 'view_latest_unpublished_version') {
      firstValueFrom(this.versionHistory$).then(({ history }) => {
        const data = history.find((h) => h.is_latest === true);
        if (data) {
          this.store.dispatch(CourseActionsV2.loadCourseV2({ courseId: data?.id || '' }));
        } else {
          this.toastService.error({
            message: 'History not founded',
          });
        }
      });
    } else {
      console.log('No condition match here!');
    }
  }
}
