import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[defaultImage]',
  standalone: true,
})
export class DefaultImageDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('error')
  onError() {
    const element: HTMLImageElement = this.elementRef.nativeElement;
    element.src = 'assets/images/image-placeholder.svg';
  }
}
