
      <li [class]="['flex-column', 'flex', 'items-start', 'gap-2', selected ? 'ProseMirror-selectednode' : '']">
         <span class="flex h-6 items-center">
             @if (isBullet && checked !== null) {
                 <input class="form-checkbox rounded" (change)="setAttrs({ checked: !checked })" type="checkbox"
                        checked="checked"/>
             } @else if (isBullet) {
                 <span class="h-2 w-2 rounded-full bg-slate-800 dark:bg-nord9"></span>
             } @else {
                 <span class="text-slate-800">{{ label }}</span>
             }
         </span>
         <div class="min-w-0" #contentRef></div>
     </li>