<app-course-header [courseState]="courseState"></app-course-header>
<div class="px-4">
   <app-course-heading (changeHistory)="changeHistoryDrawer.open()"></app-course-heading>
</div>

<div class="course-overview p-4">
   <app-common-tab-menu [items]="tabItems" title="Overview"></app-common-tab-menu>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div></div>
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'':'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
               <div class="flex flex-col">
                  <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">
                     Lectures
                  </div>
                  <app-filter-option #filterReview key="course_rating" [options]="filterOptions.course_rating"
                     [defaultValue]="filterValues.course_rating" (filterEvent)="filterHander($event)">
                  </app-filter-option>
               </div>
               <div class="flex flex-col">
                  <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">
                     Is Reported
                  </div>
                  <app-filter-option #filterReview key="is_reported" [options]="filterOptions.is_reported"
                     [defaultValue]="filterValues.is_reported" (filterEvent)="filterHander($event)">
                  </app-filter-option>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="clearFilter([filterReview])" type="button"
                     class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
                  <button (click)="submitFilter()" type="submit" class="btn btn-dark bg-neutral-600 text-white">
                     <span>Filter</span>
                  </button>
               </div>
            </div>
         </div>
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown(2)" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">{{selectedItems.title}}</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown[2]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                     aria-activedescendant="listbox-option-3" class="select-items absolute right-0"
                     ng-reflect-ng-class="hidden">
                     <li *ngFor="let type of searchType" (click)="dropdown(2); selectItems(type)" id="listbox-option-0"
                        role="option" class="item">{{type.title}}</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>Reviews</h6>
               <div class="tag tag-sm">5,000</div>
            </div>
            <div class="actions relative">
               <button (click)="dropdown('table')" class="btn-neutral px-2">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
               <div [ngClass]="isDropdown['table']?'':'hidden'"
                  class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
                  <div *ngFor="let col of columns" class="input-check w-48 my-2">
                     <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox" id="default-checkbox">
                     <label for="default-checkbox">{{col.name}}</label>
                  </div>
               </div>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <ng-container *ngFor="let column of columns">
                              <th *ngIf="column.visible" scope="col" class=" last:w-32">
                                 <div class="col-name">{{column.name}}</div>
                              </th>
                           </ng-container>
                        </tr>
                     </thead>
                     <tbody *ngIf="reviews_lists">
                        <tr class="" *ngFor="let course of reviews_lists.results; let i = index">
                           <td *ngIf="columns[0].visible" class="">
                              <span class="course-id">
                                 <a class="">
                                    #{{ course.id.substring(0, 8) }}
                                 </a>
                              </span>
                           </td>
                           <td *ngIf="columns[1].visible">
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <a class="course-name line-clamp-3 text-blue-600 underline">
                                    {{ course.content }}
                                 </a>
                              </div>
                           </td>
                           <td *ngIf="columns[2].visible" class="max-w-[170px]">
                              <span class="whitespace-nowrap flex items-center gap-2">
                                 {{ course.rate | number:'1.1-1'}}
                                 <ng-container *ngTemplateOutlet="ratingContent; context: {  rating: course.rate}">
                                 </ng-container>
                              </span>
                              <span *ngIf="course.is_reported"
                                 class="whitespace-nowrap flex items-center gap-2 text-red-600 underline">
                                 <svg-icon src="@assets/images/icons/flag-outline.svg" class=" w-4 h-4"></svg-icon>
                                 Reported
                              </span>
                           </td>
                           <td *ngIf="columns[3].visible" class="max-w-[170px]">
                              <span class="whitespace-nowrap text-blue-600 underline" *ngIf="course.user">
                                 {{ course?.user?.fullname }}
                              </span>
                           </td>
                           <td *ngIf="columns[4].visible">
                              <span class="course-price">{{ (course.date_created) | date }}</span> <br>
                              <span class="course-price text-neutral-400">{{ (course.date_created) | date:'h:mm a'
                                 }}</span>
                           </td>
                           <td *ngIf="columns[5].visible">
                              <span class="flex items-center justify-end gap-1">
                                 <button type="button" (click)="updateReview(course)"
                                    class="btn border-0 bg-amber-100 text-amber-600 w-10 px-1.5">
                                    <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
                                 </button>
                                 <button type="button" (click)="deleteReview(course)"
                                    class="btn border-red-600 text-red-600 w-10 px-1.5">
                                    <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon"></svg-icon>
                                 </button>
                              </span>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #ratingContent let-rating="rating">
   <div class="flex gap-[2px] items-center">
      <svg-icon [ngClass]="rating>=1?'!text-amber-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="rating>=2?'!text-amber-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="rating>=3?'!text-amber-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="rating>=4?'!text-amber-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
      <svg-icon [ngClass]="rating>=5?'!text-amber-500':'text-slate-200'" src="@assets/images/icons/star-solid.svg"
         class="w-4 h-4">
      </svg-icon>
   </div>
</ng-template>


<common-modal #newReview title="Update review" customClass="text-base sm:w-full sm:max-w-2xl">
   <ng-container content>
      <div *ngIf="newReview.isOpened">
         <ng-container *ngTemplateOutlet="newReviewForm"></ng-container>
      </div>
   </ng-container>
</common-modal>

<ng-template #newReviewForm>
   <div class="p-6 pb-0">
      <div class="course-info flex items-center gap-6">
         <div class="grow basis-0">
            <div>
               <div class="text-sm" translate>Your rating</div>
               <div>
                  <div class="starts text-amber-500 flex cursor-pointer items-center">
                     <svg-icon
                        [src]="selectedRate >= 1 ? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                        class="h-7 w-7" (click)="selectedRate = 1"></svg-icon>
                     <svg-icon
                        [src]="selectedRate >= 2 ? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                        class="h-7 w-7" (click)="selectedRate = 2"></svg-icon>
                     <svg-icon
                        [src]="selectedRate >= 3 ? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                        class="h-7 w-7" (click)="selectedRate = 3"></svg-icon>
                     <svg-icon
                        [src]="selectedRate >= 4 ? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                        class="h-7 w-7" (click)="selectedRate = 4"></svg-icon>
                     <svg-icon
                        [src]="selectedRate >= 5 ? '@assets/images/icons/star-solid.svg' : '@assets/images/icons/star.svg'"
                        class="h-7 w-7" (click)="selectedRate = 5"></svg-icon>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div>
      <form [formGroup]="formReview" (submit)="submitReview()">
         <div class="px-6 pb-3">
            <formly-form [form]="formReview" [fields]="fieldsReview" [model]="modelReview"></formly-form>
         </div>
         <div class="border-t border-slate-200 px-6 py-4 text-right">
            <button type="button" class="btn btn-light" (click)="newReview.close()">Cancel</button>
            <button type="submit" class="btn btn-primary">Submit</button>
         </div>
      </form>
   </div>
</ng-template>


<app-common-modal-confirm #deleteReviewModal title="Delete Review"
   message="Are you sure you want to delete this review?" confirmText="Delete Review"
   (confirm)="deleteReviewConfirmed()">
</app-common-modal-confirm>

<!-- Buyer drawer -->
<app-drawer #changeHistoryDrawer customClass="max-w-[600px] !sm:w-full">
  <ng-container header>
    <div class="px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
      <h3 class="text-xl text-black font-semibold">Version History</h3>
      <div class="flex justify-between items-center gap-3">
        <button (click)="changeHistoryDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
          <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
          </svg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container content>
    <ng-container content *ngIf="changeHistoryDrawer.isOpened">
      <app-common-history-course maxHeight="90vh"></app-common-history-course>
    </ng-container>
  </ng-container>
</app-drawer>