import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FixedRateTax, FixedRateTaxList, SharedModule } from '@shared';
import { TaxConfigService } from '@shared/services/price-management/tax-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalService, PaginationService, Params, ToastService } from 'thkee-common';
const pageSize: number = 10;

@Component({
  selector: 'app-price-tax-fixed-rates',
  standalone: true,
  imports: [AngularSvgIconModule, RouterLink, ReactiveFormsModule, FormsModule, SharedModule],
  templateUrl: './price-tax-fixed-rates.component.html',
  styleUrl: './price-tax-fixed-rates.component.scss',
})
export class PriceTaxFixedRatesComponent implements OnInit {
  taxFixedRateData!: FixedRateTax;
  totalFixedRateTax: number = 0;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  action: string = 'create';
  queryParams: Params = { page: 1, page_size: pageSize };

  editId: number | null = null;
  fixed_percentage: string = '';

  editModel: FixedRateTaxList = {
    id: 0,
    global_tax_category: '',
    fixed_percentage: '',
  };

  constructor(
    private taxConfigService: TaxConfigService,
    private router: Router,
    private toastService: ToastService,
    private paginationService: PaginationService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getFixedRateTaxLists();
  }

  getFixedRateTaxLists() {
    this.isLoading = true;
    this.taxConfigService.getFixedRateTaxLists(this.queryParams).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.taxFixedRateData = res;
        this.totalFixedRateTax = res.count;
        this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
      },
      error: (error) => {
        this.isLoading = false;
        this.toastService.error({
          message: error,
        });
      },
    });
  }

  editRate(data: FixedRateTaxList) {
    if (data) {
      this.editId = data.id;
      this.editModel.id = data.id;
      this.editModel.global_tax_category = data.global_tax_category;
      this.editModel.fixed_percentage = data.fixed_percentage;
    }
  }

  saveRate() {
    if (this.editId) {
      console.log(this.editModel);
      let body = {
        global_tax_category_id: this.editModel.id,
        fixed_percentage: this.editModel.fixed_percentage,
      };

      this.taxConfigService.updateFixedRateTaxLists(body, this.editId.toString()).subscribe({
        next: (res) => {
          this.getFixedRateTaxLists();
          this.toastService.message({
            message: 'Global fixed tax updated successfully.',
          });
        },
        error: (error) => {
          this.toastService.error({
            message: error,
          });
        },
      });
    }

    // Reset
    this.editId = null;
  }

  cancelRate() {
    this.editId = null;
  }

  paginate(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getFixedRateTaxLists();
  }

  switchToByCountry() {
    this.router.navigate(['/price-management/tax-config/by-country']);
    // this.modalService.confirm({
    //   title: 'Are you sure?',
    //   message: `You are going to change the way the tax rate is calculated.
    //   This will cause the tax rate to be calculated based on the settings specified on the configuration page.
    //   Any current tax rate settings will be saved, but will not be active until you return to this tax calculation method.
    //   Are you sure you want to perform this action?`,
    //   onConfirm: () => {
    //     this.router.navigate(['/price-management/tax-config/by-country']);
    //   },
    // });
  }
}
