import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

// All types
export type Status =
  | 'draft'
  | 'published'
  | 'unlisted'
  | 'pending'
  | 'ended'
  | 'in_review'
  | 'rejected'
  | 'refund'
  | 'completed'
  | 'failed'
  | 'voided';

export type DataLayerUserRole = 'student' | 'instructor' | 'admin' | '';
export type DataLayerSignUpMethod = 'email' | 'google' | 'facebook' | 'linkedin' | '';

export enum DataLayerEvent {
  SIGN_UP = 'sign_up',
  INSTRUCTOR_REGISTER = 'instructor_register',
  COURSE_CREATE = 'course_create',
  LOGIN_AS_USER = 'login_as_user',
}
export type DataLayerPayload = {
  [DataLayerEvent.SIGN_UP]: {
    signUpMethod: DataLayerSignUpMethod;
    userRole: DataLayerUserRole;
    userName?: string;
    userEmail?: string;
  };
  [DataLayerEvent.INSTRUCTOR_REGISTER]: {
    userRole: DataLayerUserRole;
    userEmail: string;
    userName: string;
  };
  [DataLayerEvent.COURSE_CREATE]: {
    courseId: string;
    courseName: string;
    userName: string;
    userId: number;
  };
  [DataLayerEvent.LOGIN_AS_USER]: {
    userRole: DataLayerUserRole;
  };
};

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private http: HttpClient) {}

  // Check Object type and Expacted Output @true/false
  isObjEmpty(obj = {}) {
    return Object.values(obj).length === 0 && obj.constructor === Object;
  }

  // Caching service
  private cache: { [key: string]: { data: any; expiry: number } } = {};
  get<T>(url: string, options?: { forceRefresh?: boolean }): Observable<T> {
    if (!options?.forceRefresh && this.cache[url] && this.cache[url].expiry > Date.now()) {
      return of(this.cache[url].data);
    }
    return this.http.get<T>(url).pipe(
      tap((data) => {
        this.cache[url] = { data, expiry: Date.now() + 60000 * 5 }; // Cache expiry in milliseconds (e.g., 1 minute = 60000ms)
      })
    );
  }

  /**
   * Converts seconds to an object containing hours and minutes.
   * @param seconds - The number of seconds to be converted.
   * @returns An object with hours and minutes.
   */
  secondsToHoursAndMinutes(seconds: number): { hours: number; minutes: number } {
    if (seconds < 0 || isNaN(seconds)) {
      return { hours: NaN, minutes: NaN }; // or throw an error, depending on your use case
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes };
  }

  /**
   * Converts query object to an CSV string as quesry params.
   * @param queryObject - The key value pare object to be converted.
   * @returns A router query params.
   */
  convertQueryParams(obj: Record<string, Record<string, boolean>>): Record<string, string> {
    const result: Record<string, string> = {};
    for (const key in obj) {
      result[key] = Object.keys(obj[key])
        .filter((innerKey) => obj[key][innerKey])
        .join(',');
    }
    return result;
  }

  /**
   * Input a status
   * @returns Return color class based on the status.
   */
  getStatusColor(status: Status = 'draft') {
    const colorMatch: Record<Status, string> = {
      draft: 'blue',
      published: 'green',
      unlisted: 'red',
      pending: 'yellow',
      ended: 'gray',
      in_review: 'yellow',
      rejected: 'red',
      refund: 'yellow',
      completed: 'green',
      failed: 'red',
      voided: 'yellow',
    };
    return colorMatch[status];
  }
  isStatus(status: string): status is Status {
    return [
      'draft',
      'published',
      'unlisted',
      'pending',
      'ended',
      'in_review',
      'rejected',
      'refund',
      'completed',
      'failed',
      'voided',
    ].includes(status);
  }

  // Data layer service
  dataLayerActions<T extends DataLayerEvent>(event: T, payload: DataLayerPayload[T]): void {
    window.dataLayer = window.dataLayer || [];

    // Remove undefined values in a functional way
    const eventData = Object.fromEntries(
      Object.entries({ event, ...payload }).filter(([_, value]) => value !== undefined)
    );

    window.dataLayer.push(eventData);
    console.log(window.dataLayer);
  }

  // dataLayerActions(
  //   event: DataLayerEvent,
  //   signUpMethod?: DataLayerSignUpMethod,
  //   userRole?: DataLayerUserRole,
  //   userName?: string,
  //   userEmail?: string
  // ) {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: event,
  //     signUpMethod: signUpMethod, // Change to 'google' or 'facebook' to test different methods
  //     userRole: userRole, // Change to 'instructor' if needed
  //     userName: userName,
  //     userEmail: userEmail,
  //   });

  //   console.log(window.dataLayer);
  // }
}
