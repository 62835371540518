import { Injectable } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { LocalStorage } from '../utils';

export type VideoPrecessingStatus = 'init' | 'uploading' | 'complete' | 'failed';

export interface LectureInfo {
  lectureId: string;
  library: string;
  status: VideoPrecessingStatus;
}

@Injectable({
  providedIn: 'root',
})
export class VideoProcessingService {
  // LocalStorage
  private lectureInfo$ = new BehaviorSubject<{ [key: string]: { library: string; status: VideoPrecessingStatus } }>(
    this.initializeLectureInfo()
  );

  constructor() {}

  setLectureInfo(lectureId: string, library: string, status: VideoPrecessingStatus = 'uploading') {
    let lectureInfo: LectureInfo[] = [];
    const storageLectureInfo = LocalStorage.getObject('precessing_info') as LectureInfo[] | undefined;

    if (storageLectureInfo) {
      const index = storageLectureInfo.findIndex((info) => info.lectureId === lectureId);
      if (index !== -1) {
        // storageLectureInfo[index].status = status;
        // If a new library is provided, update it
        if (library) {
          if (storageLectureInfo[index].status === 'init') {
            storageLectureInfo[index].library = library;
          }
        }
        // If a new status is provided, update it
        if (status) {
          storageLectureInfo[index].status = status;
        }
      } else {
        storageLectureInfo.push({ lectureId, library, status });
      }
      lectureInfo = storageLectureInfo;
    } else {
      lectureInfo = [{ lectureId, library, status }];
    }

    LocalStorage.setValue('precessing_info', lectureInfo);
    this.lectureInfo$.next(this.formatLectureInfo(lectureInfo));
  }

  removeLectureInfo(lectureId: string) {
    let lectureInfo: LectureInfo[] = [];
    const storageLectureInfo = LocalStorage.getObject('precessing_info') as LectureInfo[] | undefined;
    if (storageLectureInfo) {
      const index = storageLectureInfo.findIndex((info) => info.lectureId === lectureId);
      storageLectureInfo.splice(index, 1);
      lectureInfo = storageLectureInfo;
    }

    LocalStorage.setValue('precessing_info', lectureInfo);
    this.lectureInfo$.next(this.formatLectureInfo(lectureInfo));
  }

  getLectureInfo() {
    return this.lectureInfo$.asObservable();
  }

  private initializeLectureInfo(): { [key: string]: { library: string; status: VideoPrecessingStatus } } {
    const storedLectureInfo = LocalStorage.getObject('precessing_info') as LectureInfo[];
    return this.formatLectureInfo(storedLectureInfo || []);
  }

  private formatLectureInfo(lectureInfo: LectureInfo[]): {
    [key: string]: { status: VideoPrecessingStatus; lectureId: string; library: string };
  } {
    return lectureInfo.reduce((acc, curr) => {
      acc[curr.lectureId] = { status: curr.status, lectureId: curr.lectureId, library: curr.library };
      return acc;
    }, {} as { [key: string]: { status: VideoPrecessingStatus; lectureId: string; library: string } });
  }

  // Socket
  private url: string = '';
  private rws: ReconnectingWebSocket | null = null;

  connect(url: string): Observable<any> {
    this.url = url;
    const options = {
      // minReconnectionDelay: 3000, // min delay in ms between reconnections
      // reconnectionDelayGrowFactor?: number; // how fast the reconnection delay grows
      minUptime: 10000, // min time in ms to consider connection as stable
      connectionTimeout: 3000, // retry connect if not connected after this time, in ms
      // maxRetries: 10; // maximum number of retries
      // maxEnqueuedMessages?: number; // maximum number of messages to buffer until reconnection
      startClosed: true, // start websocket in CLOSED state, call `.reconnect()` to connect
    };
    this.rws = new ReconnectingWebSocket(this.url);
    return new Observable((observer: Observer<any>) => {
      if (!this.rws) {
        observer.error('WebSocket is not initialized.');
        return;
      }
      this.rws.onopen = (event) => {
        console.log('WebSocket connection established.');
        observer.next(event);
      };
      this.rws.onmessage = (event) => {
        observer.next(event.data);
      };
      this.rws.onclose = (event) => {
        observer.complete();
      };
      this.rws.onerror = (event) => {
        observer.error(event);
      };
      this.rws.reconnect();
      // Cleanup when the observable is unsubscribed
      return () => {
        if (this.rws) {
          this.rws.close();
        }
      };
    });
  }
  send(data: any): void {
    if (this.rws && this.rws.readyState === WebSocket.OPEN) {
      this.rws.send(data);
    } else {
      console.error('WebSocket is not open. Cannot send data.');
    }
  }
  close(): void {
    if (this.rws) {
      this.rws.close();
    }
  }
}
